import React from "react";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import { KPIReducer, initialState, KPIAction, KPIState } from "./KPIReducer";

type KPIContextProps = {
  state: KPIState;
  dispatch: React.Dispatch<KPIAction>;
};

const KPIContext = React.createContext<KPIContextProps>({
  state: initialState,
  dispatch: () => initialState,
});

export const KPIProvider = (props: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(KPIReducer, initialState);
  const { user } = useAppSelector(selectAuthen);
  React.useEffect(() => {
    if (user) {
      dispatch({ type: "SET_EMP", emp_id: user.username });
    }
    return () => {
      dispatch({ type: "SET_EMP", emp_id: null });
      dispatch({ type: "SET_PERIOD", period: null });
    };
  }, [user]);

  return <KPIContext.Provider value={{ state, dispatch }} {...props} />;
};

export default function useKPI() {
  return React.useContext(KPIContext);
}
