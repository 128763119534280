import { IonAlert, IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { useAppDispatch } from "redux/hooks";
import { store } from "redux/store";
import AppRouter from "routes";
import { getToken } from "./api/AuthAPI";
//import Training from "./pages/Training";
import * as serviceWorker from "./serviceWorker";
import MUITheme from "./theme/MUITheme";
/* Theme variables */
import "./theme/variables.css";

setupIonicReact({});

const App: React.FC = () => {
  const [promptRefresh, setPromptRefresh] = useState(false);
  const token = getToken();

  const dispatch = useAppDispatch();

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        if (registration && registration.waiting) {
          setPromptRefresh(true);
        } else {
          setPromptRefresh(false);
        }
      },
    });
  }, [dispatch, token]);

  const handleRefreshForUpdate = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistration()
        .then((reg) => {
          if (reg && reg.waiting)
            reg.waiting.postMessage({ type: "SKIP_WAITING" });
          window.location.reload();
        })
        .catch((err) => console.log("Could not get registration: ", err));
    }
  };

  return (
    <IonApp className={"gbdi-theme"}>
      <IonReactRouter>
        <AppRouter />
      </IonReactRouter>
      <IonAlert
        isOpen={promptRefresh}
        onDidDismiss={handleRefreshForUpdate}
        cssClass="my-custom-class"
        header={"New Update"}
        message={"A new version is available !! Please Reload now"}
        buttons={[
          {
            text: "Reload",
            role: "cancel",
            handler: handleRefreshForUpdate,
          },
        ]}
      />
    </IonApp>
  );
};

const storeWrap = () => (
  <Provider store={store}>
    <MUITheme>
      <App />
    </MUITheme>
  </Provider>
);

export default storeWrap;
