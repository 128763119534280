import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { IEmployee } from "models/employee.model";

interface EmpState {
  employeeList: Array<IEmployee>;
}

const initialState: EmpState = {
  employeeList: [],
};

export const employeeSlice = createSlice({
  name: "dropdown",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setEmpList: (state: EmpState, action: PayloadAction<IEmployee[]>) => {
      state.employeeList = action.payload;
    },
    setEmpAvatar: (
      state: EmpState,
      action: PayloadAction<{ emp_id: string; avatar: any }>
    ) => {
      const { emp_id, avatar } = action.payload;
      let index = state.employeeList.findIndex(
        (value) => value.emp_id === emp_id
      );
      if (index > -1) {
        state.employeeList[index].avatarURI = avatar;
      }
    },
  },
});

export const { setEmpList, setEmpAvatar } = employeeSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectEmployeeList = (state: RootState) =>
  state.employee.employeeList;

export default employeeSlice.reducer;
