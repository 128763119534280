import { IonChip, IonItem, IonList, IonText } from "@ionic/react";
import React from "react";
import { selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { EmpChip } from "../../../components/Select/EmployeeSelect";
import { IScoreE360Data } from "../../../models/kpi.model";
import { getEmpFullName, getWorkingTime, roundTwoDigit } from "../../../utils";

const List: React.FC<{
  Items: Array<IScoreE360Data>;
}> = ({ Items }) => {
  const empList = useAppSelector(selectEmpList);

  const renderKPIList = Items.map((p, i) => (
    <IonItem key={i}>
      <EmpChip value={p.emp_id}></EmpChip>
      <IonText style={{ fontWeight: "bold" }}>
        {`${getEmpFullName(p.emp_id, empList)}`}
      </IonText>
      <IonChip>{`${getWorkingTime(p.emp_id, empList)}`}</IonChip>
      <IonText slot="end">
        <span style={{ marginRight: "10px" }}>{`KPI Score : ${roundTwoDigit(
          p.kpi_acm
        )}`}</span>
      </IonText>
      <IonText slot="end">
        <span style={{ marginRight: "10px" }}>{`360 Score : ${roundTwoDigit(
          p.e360_acm
        )}`}</span>
      </IonText>
      <IonText slot="end">
        <span style={{ marginRight: "10px" }}>{`PIP Score : ${roundTwoDigit(
          p.pip_acm
        )}`}</span>
      </IonText>
      <IonText slot="end">
        <span style={{ marginRight: "10px" }}>{`PMS Score : ${roundTwoDigit(
          p.pms_acm
        )}`}</span>
      </IonText>
    </IonItem>
  ));

  return <IonList>{renderKPIList}</IonList>;
};

export default List;
