import { configureStore } from "@reduxjs/toolkit";
import dropdownReducer from "redux/dropdown/reducer";
import employeeReducer from "redux/employee/reducer";
import authenReducer from "redux/authen/reducer";

export const store = configureStore({
  reducer: {
    dropdown: dropdownReducer,
    employee: employeeReducer,
    authen: authenReducer,
  },
  devTools: false,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
