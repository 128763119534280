import { IScorePercent } from "models/kpi.model";

export const score_default: IScorePercent[] = [
  {
    acm_name: "vp_acm",
    acm_fullname: "VP",
    percent: 100,
  },
  {
    acm_name: "e360_acm",
    acm_fullname: "360",
    percent: 0,
  },
  {
    acm_name: "final_score",
    acm_fullname: "VP",
    percent: 100,
  },
];
