import dayjs from "dayjs";
import { IEmployee } from "models/employee.model";

export const roundTwoDigit = (data: number) => Math.round(data * 100) / 100;

export const getEmpDetail = (emp_id: string, empList: Array<IEmployee>) =>
  empList.find((emp) => emp.username === emp_id);

export const getEmpFullName = (emp_id: string, empList: Array<IEmployee>) => {
  const emp = empList.find((emp) => emp.username === emp_id);
  return emp ? `${emp.firstname} ${emp.lastname}` : null;
};

export const getWorkingTime = (emp_id: string, empList: Array<IEmployee>) => {
  const emp = empList.find((emp) => emp.username === emp_id);
  const formatWorkTime = (work_firstdate: Date) => {
    const duration = dayjs.duration(dayjs().diff(work_firstdate));
    const years = duration.get("years");
    const months = duration.get("months");
    const days = duration.get("days");
    return `${years}Y ${months}M ${days}D`;
  };
  return emp && emp.work_firstdate
    ? `${formatWorkTime(emp.work_firstdate)}`
    : "N/A";
};

export const formatShortDate = (date: string | null | undefined) =>
  date ? dayjs(date).format("D MMM YYYY") : "";

export const formatSprintName = () => {
  const result: string[] = [];
  let thisMonth = dayjs().get("month");
  // for (let year = 0; year < 1; year++) {
  for (let month = 0; month < 4; month += 1) {
    const calMonth = dayjs().add(month, "month").get("month");
    const calYear = dayjs().get("year");
    let formattedYear: string;
    if (thisMonth > calMonth) {
      formattedYear = (calYear + 1).toString().slice(2, 4);
    } else {
      formattedYear = calYear.toString().slice(2, 4);
    }
    const formattedMonth = (calMonth + 1).toString().padStart(2, "0");
    const sprintASlot = `${formattedYear}-${formattedMonth}A`;
    const sprintBSlot = `${formattedYear}-${formattedMonth}B`;
    result.push(sprintASlot, sprintBSlot);
  }
  // }
  return result;
};
