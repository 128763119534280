import React from "react";
import { IonAlert } from "@ionic/react";

const Alert: React.FC<{
  showAlert: boolean;
  onDisDismiss: () => void;
  message: string;
}> = ({ showAlert, onDisDismiss, message }) => {
  return (
    <IonAlert
      isOpen={showAlert}
      onDidDismiss={onDisDismiss}
      header="Error"
      message={message}
      buttons={["OK"]}
    />
  );
};

export default Alert;
