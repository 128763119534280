import {
  IonBadge,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonContent,
  IonHeader,
  IonItemGroup,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import { IEventKey, IPeriod } from "../../models/kpi.model";
import useKPI from "../../store/KPIProvider";
import { IGroupAPI } from "models/rest-api.model";
import Spinning from "components/Spinning";
import { roundTwoDigit } from "utils";

interface periodSelectProp {
  API: IGroupAPI;
  title: string;
  eventKey?: IEventKey;
  eventTitleActive?: string;
}

const PeriodSelect: React.FC<periodSelectProp> = ({
  API,
  title,
  eventKey,
  eventTitleActive,
}) => {
  const [PeriodList, setPeriodList] = useState<Array<IPeriod>>([]);
  const [showLoading, setShowLoading] = useState(true);

  const history = useHistory();
  const { url } = useRouteMatch();

  const { dispatch } = useKPI();
  const { user } = useAppSelector(selectAuthen);

  useEffect(() => {
    let emp_id;
    setShowLoading(true);
    if (Boolean(user?.role.includes("admin") || user?.role.includes("spm"))) {
      emp_id = undefined;
    } else {
      emp_id = user?.username;
    }
    API.readPeriod({ emp_id: emp_id }).then((res: any) => {
      setPeriodList([...res.data]);
      setShowLoading(false);
    });
  }, [user, API]);

  const onSelectPeriod = (period: IPeriod) => {
    dispatch({
      type: "SET_PERIOD",
      period: period,
    });
    history.push(`${url}/detail`);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!showLoading ? (
          PeriodList.length > 0 ? (
            <IonList>
              <IonListHeader>
                <h1>Period</h1>
              </IonListHeader>
              <IonItemGroup>
                {PeriodList &&
                  PeriodList.map((period, i) => (
                    <IonCard
                      routerDirection="forward"
                      button
                      onClick={() => onSelectPeriod(period)}
                      key={i}
                    >
                      <IonCardHeader>
                        <IonCardTitle>
                          {period.name}
                          {period.total_storypoint && (
                            <IonChip color="success">
                              {roundTwoDigit(period.total_storypoint) +
                                " storypoint"}
                            </IonChip>
                          )}
                          {eventKey && period[eventKey] && (
                            <IonBadge
                              style={{ marginLeft: "20px" }}
                              color="warning"
                            >
                              {eventTitleActive}
                            </IonBadge>
                          )}
                        </IonCardTitle>
                        <IonCardSubtitle>
                          <p>
                            {new Date(period.startdate).toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                            &mdash;&nbsp;
                            {new Date(period.enddate).toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </p>
                        </IonCardSubtitle>
                      </IonCardHeader>
                    </IonCard>
                  ))}
              </IonItemGroup>
            </IonList>
          ) : (
            <div
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No Data
            </div>
          )
        ) : (
          <Spinning />
        )}
      </IonContent>
    </IonPage>
  );
};

export default PeriodSelect;
