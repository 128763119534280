import {
    IonButton,
    IonButtons,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonNote,
    IonTitle,
    IonToolbar,
    useIonToast,
  } from "@ionic/react";
  import { resetPasswordReq } from "api/AuthAPI";
  import React, { useState } from "react";
  
  const ResetPasswordModal: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [present] = useIonToast();
    const [username, setUsername] = useState<string>("");
    const toggle = () => {
      setShowModal(!showModal);
    };
  
    return (
      <>
        <a onClick={() => toggle()}>Forgot Password?</a>
        <IonModal
          isOpen={showModal}
          onDidDismiss={() => {
            setShowModal(false);
            setUsername("");
          }}
          breakpoints={[0, 0.25, 0.5, 1]}
        >
          <IonToolbar color="gbdi">
            <IonButtons slot="start"></IonButtons>
            <IonTitle>Reset Password</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonContent className="ion-padding">
            <IonList>
              <IonItem
                className={`${isValid && "ion-valid"} ${
                  isValid === false && "ion-invalid"
                }`}
              >
                <IonLabel position="fixed">Username</IonLabel>
                <IonInput
                  type="text"
                  name="username"
                  value={username}
                  autocomplete="username"
                  onIonChange={(e) => setUsername(e.detail.value!)}
                ></IonInput>
                <IonNote slot="error">Invalid username</IonNote>
              </IonItem>
              <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Loading ..."}
              />
              <IonButton
                expand="block"
                className="ion-margin"
                onClick={() => {
                  setShowLoading(true);
                  resetPasswordReq(username)
                    .then((res) => {
                      const result = res.data;
                      setShowModal(false);
                      setUsername("");
                      setIsValid(true);
                      present({
                        message: result.message,
                        duration: 1500,
                        position: "middle",
                      });
                    })
                    .catch((res) => {
                      setIsValid(false);
                    })
                    .finally(() => setShowLoading(false));
                }}
              >
                {"Reset Password"}
              </IonButton>
            </IonList>
          </IonContent>
        </IonModal>
      </>
    );
  };
  
  export default ResetPasswordModal;
  