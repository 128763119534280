import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { helpCircle } from "ionicons/icons";
import { IEmployee, IUserGroup } from "models/employee.model";
import React, { useEffect, useState } from "react";
import { selectEmpAvatar, selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { fullLabelStyle } from "variable";

interface employeeSelect {
  data: IEmployee;
  checked: boolean;
}

const EmployeeSelect: React.FC<{
  label: string;
  value: any;
  name: string;
  color?: string;
  position?: string;
  readonly?: boolean;
  multiple?: boolean;
  onChange: Function;
  filterByGroup?: IUserGroup | "all";
}> = ({
  label,
  value,
  onChange,
  name,
  color,
  position,
  readonly,
  multiple,
  filterByGroup,
}: any) => {
  const [EmployeeList, setEmployeeList] = useState<Array<employeeSelect>>([]);
  const [searchText, setSearchText] = useState("");
  //const [selectEmp, setSelectEmp] = useState<Array<emp_checkBox>>([]);
  const [modal, setModal] = useState<boolean>(false);
  const empList = useAppSelector(selectEmpList);

  useEffect(() => {
    setEmployeeList(
      empList
        .filter((emp) => emp.is_employee)
        .filter((emp) => {
          if (!filterByGroup) return true;
          else if (filterByGroup === "all") return true;
          return emp.group === filterByGroup;
        })
        .map((element) => ({
          data: element,
          checked: value && value.includes(element.emp_id) ? true : false,
        }))
    );
  }, [empList, value, filterByGroup]);

  return (
    <>
      <IonItem
        button={!readonly}
        detail={false}
        lines={readonly ? "none" : "inset"}
        onClick={() => {
          !readonly && setModal(true);
        }}
      >
        <IonLabel
          position={multiple ? "fixed" : position}
          color={color}
          style={fullLabelStyle}
        >
          {label}
        </IonLabel>
        {Boolean(value) &&
          Boolean(value.length) &&
          (Array.isArray(value) ? (
            <IonList style={{ background: "none" }}>
              {value.map((value, i) => (
                <EmpChip key={i} value={value} />
              ))}
            </IonList>
          ) : (
            <EmpChip value={value} />
          ))}
      </IonItem>
      {/* <IonInput readonly type="text" name={name} id={name} value={value} /> */}

      <IonModal isOpen={modal}>
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonTitle>Employee List</IonTitle>
            <IonButtons slot="end">
              {multiple && (
                <IonButton
                  expand="block"
                  onClick={() => {
                    const selected = EmployeeList.filter(
                      (emp) => emp.checked
                    ).map((emp) => emp.data.emp_id);
                    const event = { target: { name: name, value: selected } };
                    onChange(event);
                    setModal(false);
                  }}
                >
                  Submit
                </IonButton>
              )}
              <IonButton
                expand="block"
                onClick={() => {
                  if (multiple)
                    setEmployeeList((prevState) =>
                      prevState.map((element) => ({
                        ...element,
                        checked:
                          value && value.includes(element.data.emp_id)
                            ? true
                            : false,
                      }))
                    );
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar className="ion-align-items-center">
            <IonSearchbar
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList style={{ background: "none" }}>
            {EmployeeList.map((emp, i) => (
              <EmpItem
                key={i}
                item={emp.data}
                display={
                  emp.data.emp_id
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) > -1
                    ? true
                    : false
                }
                checked={emp.checked}
                onClick={() => {
                  if (multiple) {
                    setEmployeeList((prevState) =>
                      prevState.map((element) =>
                        element.data.emp_id === emp.data.emp_id
                          ? {
                              ...element,
                              checked: !element.checked,
                            }
                          : element
                      )
                    );
                  } else {
                    setSearchText("");
                    const event = {
                      target: { name: name, value: emp.data.emp_id },
                    };
                    onChange(event);
                    setModal(false);
                  }
                }}
                multiple={multiple}
              />
            ))}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

const EmpItem: React.FC<{
  item: any;
  display: boolean;
  onClick: Function;
  multiple: boolean;
  checked: boolean;
}> = ({ item, display, onClick, multiple, checked }) => {
  const avatarEmp = useAppSelector(selectEmpAvatar(item.emp_id));
  const [check, setCheck] = useState<boolean | undefined>(checked);

  return (
    <IonItem
      button
      detail={false}
      onClick={() => {
        setCheck(!check);
        onClick();
      }}
      style={{ display: display ? "block" : "none" }}
    >
      {multiple && <IonCheckbox checked={check} slot="start" color="primary" />}
      <IonAvatar slot="start">
        <img
          src={avatarEmp}
          alt="avatar"
          width="250px"
          height="250px"
          loading="lazy"
        />
      </IonAvatar>
      <IonLabel>{item.emp_id}</IonLabel>
    </IonItem>
  );
};

export const EmpChip: React.FC<{
  value: any;
}> = ({ value }) => {
  const avatarEmp = useAppSelector(selectEmpAvatar(value));
  return value ? (
    <IonChip color="secondary">
      <IonAvatar>
        <img
          src={avatarEmp}
          alt="avatar"
          loading="lazy"
        />
      </IonAvatar>
      <IonLabel>{value}</IonLabel>
    </IonChip>
  ) : (
    <IonChip color="secondary">
      <IonIcon icon={helpCircle} />
      <IonLabel>{"N/A"}</IonLabel>
    </IonChip>
  );
};

export default EmployeeSelect;
