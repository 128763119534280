import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { list } from "ionicons/icons";
import React, { useEffect } from "react";
import { IssueItem } from "../../../components/Item/ItemKPI";
import Spinning from "../../../components/Spinning";
import { ProjectAPI } from "api/KPIAPI"

const ModalForm: React.FC<{
  period: string;
  epic_id: string;
}> = ({ period, epic_id }) => {
  const [modal, setModal] = React.useState(false);
  const [epicDetail, setEpicDetail] = React.useState<any>();
  const [textLoading, setTextLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (modal) {
      setTextLoading(true);
      ProjectAPI.readDetail!({ period: period, epic_id: epic_id }).then(
        (items) => {
          setTextLoading(false);
          setEpicDetail(items.data);
        }
      );
    }
  }, [modal, period, epic_id]);

  const toggle = () => {
    setModal(!modal);
  };

  const renderTaskList = () => {
    return epicDetail
      ? epicDetail.issue_list.map((task: any, i: number) => (
          <IssueItem
            key={i}
            issue_type={task.issue_type}
            issue_id={task.issue_id}
            issue_summary={task.issue_summary}
            issue_resolution={task.issue_resolution}
            storypoint={task.storypoint}
            emp_id={task.emp_id}
          />
        ))
      : null;
  };

  return (
    <>
      <IonButton color="primary" fill="solid" onClick={toggle}>
        <IonIcon icon={list} />
        {"Click for issue list"}
      </IonButton>
      <IonModal isOpen={modal} >
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonTitle>Issue List</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {textLoading ? <Spinning /> : renderTaskList()}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ModalForm;
