import {
  IonAccordion,
  IonAccordionGroup,
  IonButtons,
  IonCard,
  IonChip,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
} from "@ionic/react";
import React, { useState } from "react";
import { selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { EmpChip } from "../../../components/Select/EmployeeSelect";
import { IPeriod } from "../../../models/kpi.model";
import { getEmpFullName, getWorkingTime, roundTwoDigit } from "../../../utils";
import ModalForm from "./modal";
import { IScoreEmployeeData } from "models/kpi.model";
import ModalCompetency from "./modalCompentency";
import { LeaveStorypointChip, StorypointChip } from "components/Chip";
import { selectAuthen } from "redux/authen/selector";

const List: React.FC<{
  Items: Array<IScoreEmployeeData>;
  ScoreList: Array<IScoreEmployeeData>;
  setScoreList: Function;
  countFilled: Function;
  period: IPeriod | null;
}> = ({ Items, ScoreList, setScoreList, countFilled, period }) => {
  const empList = useAppSelector(selectEmpList);
  const { user } = useAppSelector(selectAuthen);

  const changeData = (
    data: Array<IScoreEmployeeData>,
    detail: IScoreEmployeeData
  ) => {
    let index = ScoreList.findIndex(
      (score: IScoreEmployeeData) => score.emp_id === detail.emp_id
    );
    if (index !== -1) {
      setScoreList([
        ...ScoreList.slice(0, index),
        { ...data },
        ...ScoreList.slice(index + 1),
      ]);
    }
  };

  const renderKPIList = Items.map((p, i) => (
    <IonAccordion key={i}>
      {/* <IonCard key={i}> */}
      <IonItem
        slot="header"
        color="light"
        lines="full"
        className="ion-align-items-center"
      >
        <IonButtons slot="start">
          <EmpChip value={p.emp_id} />
        </IonButtons>
        <IonText style={{ fontWeight: "bold" }}>
          {`${getEmpFullName(p.emp_id, empList)}`}
        </IonText>
        <IonChip>{`${getWorkingTime(p.emp_id, empList)}`}</IonChip>
        {/* <IonButtons slot="end">
        <ModalCompetency emp_id={p.emp_id} />
        </IonButtons> */}
        <IonButtons slot="end">
          <ModalForm emp_id={p.emp_id} dimension={[p]} />
        </IonButtons>
      </IonItem>
      <div className="ion-padding" slot="content">
        <IonItem lines="inset">
          <StorypointChip value={p.total_storypoint} slot={"start"} />
          <LeaveStorypointChip value={p.leave_storypoint} slot={"start"} />
          <IonLabel position="fixed">{"360 Score : "}</IonLabel>
          <IonLabel position="fixed">
            {p.e360_acm ? roundTwoDigit(p.e360_acm) : "N/A"}
          </IonLabel>
          <IonLabel position="fixed">{"PIP Score : "}</IonLabel>
          <IonLabel position="fixed">
            {p.pip_acm ? roundTwoDigit(p.pip_acm) : "N/A"}
          </IonLabel>
          <IonLabel position="fixed">{"PMS Score : "}</IonLabel>
          <IonLabel position="fixed">
            {p.pms_acm ? roundTwoDigit(p.pms_acm) : "N/A"}
          </IonLabel>
          <IonLabel position="fixed">{"KPI ฝ่าย : "}</IonLabel>
          <IonLabel position="fixed">
            {p.kpi_acm ? roundTwoDigit(p.kpi_acm) : "N/A"}
          </IonLabel>
        </IonItem>

        {/* <IonItem lines="inset">
        <IonLabel position="fixed">{"360 Score : "}</IonLabel>
        {p.e360_acm ? roundTwoDigit(p.e360_acm) : "N/A"}
      </IonItem> */}
        <IonItem lines="inset">
          <IonLabel style={{ flexBasis: "200px" }} position="fixed">
            {"Tier-1 Score : "}
          </IonLabel>
          {p.pm_acm ? roundTwoDigit(p.pm_acm) : "N/A"}
        </IonItem>
        <IonItem lines="inset">
          <IonLabel style={{ flexBasis: "200px" }} position="fixed">
            {"Tier-2 Score : "}
          </IonLabel>
          {p.vp_acm ? roundTwoDigit(p.final_score) : "N/A"}
        </IonItem>
        <IonItem lines="inset">
          <IonLabel style={{ flexBasis: "200px" }} position="fixed">
            {"Reason : "}
          </IonLabel>
          {p.vp_comment ? p.vp_comment : "N/A"}
        </IonItem>
        <ScoreForm
          p={p}
          changeData={changeData}
          editable={Boolean(
            period?.edit_empKPI && user && user.role.includes("dr")
          )}
        />
      </div>
      {/* </IonCard> */}
    </IonAccordion>
  ));

  return (
    <div className="ion-align-self-center">
      {Boolean(Items.length) ? (
        <IonAccordionGroup multiple>{renderKPIList}</IonAccordionGroup>
      ) : (
        <h1 className="ion-text-center">No Record</h1>
      )}
    </div>
  );
};

const ScoreForm: React.FC<{
  p: IScoreEmployeeData;
  changeData: Function;
  editable: boolean;
}> = ({ p, changeData, editable }) => {
  const [isEdit, setIsEdit] = useState("dark");

  const checkEditable = () => {
    if (!editable) return true;
    else return false;
  };

  return (
    <>
      <IonItem lines="inset">
        <IonLabel style={{ flexBasis: "200px" }} position="fixed">
          {"Tier-3 Score: "}
        </IonLabel>
        <IonInput
          type="number"
          name="director_acm"
          id="director_acm"
          max="5"
          min="0"
          step="0.5"
          value={p.director_acm}
          color={isEdit}
          disabled={checkEditable()}
          onIonChange={(e) => {
            let score = parseFloat(e.detail.value!);
            if (score && (score > 5 || score < 0)) {
              setIsEdit("danger");
            }
          }}
          onIonBlur={(e: any) => {
            let score = parseFloat(e.target.value!);
            if (score && (score > 5 || score < 0)) {
              //setDirectorACM(0);
              setIsEdit("danger");
              changeData({ ...p, director_acm: 0 }, p);
            } else {
              //setDirectorACM(score);
              setIsEdit("dark");
              changeData({ ...p, director_acm: score ? score : null }, p);
            }
          }}
          onWheel={(e: any) => {
            e.preventDefault();
            e.target.blur();
          }}
        />
      </IonItem>
      <IonItem lines="inset">
        <IonLabel style={{ flexBasis: "200px" }} position="fixed">
          {"Adjustment Reason"}
        </IonLabel>
        <IonTextarea
          autoGrow
          value={p.comment}
          disabled={checkEditable()}
          onIonBlur={(e: any) => {
            console.log(e.target.value!);
            changeData({ ...p, comment: e.target.value! }, p);
          }}
        ></IonTextarea>
      </IonItem>
    </>
  );
};

export default List;
