//import { create, trash, addCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from "react";
//import { useParams } from 'react-router';
import { modelData } from "./model";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Table from "./table";
import { dataAPI } from "./api";
import { useAppSelector } from "redux/hooks";
import { selectAuthen } from "redux/authen/selector";

const Page: React.FC = () => {
  const [Items, setItems] = React.useState<Array<modelData>>([]);
  const [DropDownList, setDropDownList] = React.useState<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [action, setAction] = useState<Array<string>>([]);

  const { user } = useAppSelector(selectAuthen);


  useEffect(() => {
    const DataService = new dataAPI();

    const getItems = () => {
      setShowLoading(true);
      DataService.read().then((items) => {
        setItems(items.data);
        DataService.getDropdown().then((items) => {
          setDropDownList(items.data);
          setShowLoading(false);
        });
      });
    };

    if (user) {
      const resource = user.resource.find(
        (element) => element.name === "project"
      );
      if (resource) {
        setAction([...resource.action]);
      }
    }

    console.log("FETCH");
    getItems();
    console.log("PROJECT MOUNT");
    return () => {
      console.log("PROJECT UNMOUNT");
    };
  }, [user]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Project</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Loading ..."}
        duration={0}
      />
      <IonContent>
        {!showLoading && (
          <div>
            <Table
              Items={Items}
              updateItems={setItems}
              action={action}
              username={user?.username}
              DropDownList={DropDownList}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Page;
