import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "../../api/APIUtils";
import React, { useEffect, useMemo, useState } from "react";
import { IJiraProject } from "models/dropdown.model";
import { selectJiraProjects } from "redux/dropdown/reducer";
import { useAppSelector } from "redux/hooks";

interface projectSelect {
  data: IJiraProject;
  checked: boolean;
}

const ProjectSelect: React.FC<{
  label: string;
  value: any;
  name: string;
  color?: string;
  position?: string;
  multiple?: boolean;
  onChange: Function;
}> = ({ label, value, onChange, name, color, position, multiple }: any) => {
  const [projectList, setProjectList] = useState<Array<projectSelect>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [TextLoading, setTextLoading] = useState<boolean>(false);
  //const [selectEmp, setSelectEmp] = useState<Array<ministry_checkBox>>([]);
  const [modal, setModal] = useState<boolean>(false);
  const jiraProjects = useAppSelector(selectJiraProjects);

  useEffect(() => {
    const getProject = () =>
      axios({
        method: "get",
        url: "/staff_borrow/project",
        params: { search: searchText },
      });

    // setTextLoading(true);
    setProjectList(
      jiraProjects
        .filter((prj) => prj.id)
        .map((element) => ({
          data: element,
          checked: value && value.includes(element.id) ? true : false,
        }))
    );
    // getProject().then((res) => {
    //   setProjectList(() => {
    //     const list = [...res.data].map((element) => ({
    //       data: element,
    //       checked: value && value.includes(element.pkey) ? true : false,
    //     }));

    //     return searchText
    //       ? [
    //           ...list,
    //           {
    //             checked: true,
    //             data: { pname: 'Add "' + searchText + '"', pkey: searchText },
    //           },
    //         ]
    //       : list;
    //   });
    //   setTextLoading(false);
    // });
  }, [value, searchText]);

  console.log("🚀 ~  searchText:", searchText);
  return (
    <>
      <IonItem
        button
        detail={false}
        lines="inset"
        onClick={() => {
          setModal(true);
        }}
      >
        <IonLabel position={multiple ? "fixed" : position} color={color}>
          {label}
        </IonLabel>
        {Array.isArray(value) ? (value as string[]).join(", ") : value}
      </IonItem>
      {/* <IonInput readonly type="text" name={name} id={name} value={value} /> */}

      <IonModal isOpen={modal}>
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonTitle>Project List</IonTitle>
            <IonButtons slot="end">
              {multiple && (
                <IonButton
                  expand="block"
                  onClick={() => {
                    const selected = projectList
                      .filter((jira_project) => jira_project.checked)
                      .map((jira_project) => jira_project.data.prj_name);
                    const event = { target: { name: name, value: selected } };
                    onChange(event);
                    setModal(false);
                  }}
                >
                  Submit
                </IonButton>
              )}
              <IonButton
                expand="block"
                onClick={() => {
                  setSearchText("");
                  if (multiple)
                    setProjectList((prevState) =>
                      prevState.map((element) => ({
                        ...element,
                        checked:
                          value && value.includes(element.data.id)
                            ? true
                            : false,
                      }))
                    );
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar className="ion-align-items-center">
            <IonSearchbar
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {TextLoading ? (
            <IonRow className="ion-justify-content-center">
              <IonSpinner name="dots" />
            </IonRow>
          ) : (
            <>
              <IonList style={{ background: "none" }}>
                {projectList.map((jira_project, i) => (
                  <ProjectItem
                    key={i}
                    item={jira_project.data}
                    display={
                      jira_project.data.prj_name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) > -1
                        ? true
                        : false
                    }
                    checked={jira_project.checked}
                    onClick={() => {
                      if (multiple) {
                        setProjectList((prevState) =>
                          prevState.map((element) =>
                            element.data.prj_name === jira_project.data.prj_name
                              ? {
                                  ...element,
                                  checked: !element.checked,
                                }
                              : element
                          )
                        );
                      } else {
                        setSearchText("");
                        const event = {
                          target: {
                            name: name,
                            value: jira_project.data.id,
                          },
                        };
                        onChange(event);
                        setModal(false);
                      }
                    }}
                    multiple={multiple}
                  />
                ))}
              </IonList>
            </>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

const ProjectItem: React.FC<{
  item: any;
  display: boolean;
  onClick: Function;
  multiple: boolean;
  checked: boolean;
}> = ({ item, display, onClick, multiple, checked }) => {
  const [check, setCheck] = useState<boolean | undefined>(checked);
  return (
    <IonItem
      button
      onClick={() => {
        setCheck(!check);
        onClick();
      }}
      style={{ display: display ? "block" : "none" }}
    >
      {multiple && <IonCheckbox checked={check} slot="start" color="primary" />}
      <IonLabel>{item.prj_name}</IonLabel>
    </IonItem>
  );
};

export default ProjectSelect;
