import { IUser } from "models/user.model";
import { setAvatar, setUser } from "redux/authen/reducer";
import { setEmpList } from "redux/employee/reducer";
import { AppDispatch } from "redux/store";
import { getEmpList, getAvatar } from "../api/AuthAPI";
import { IPermissions } from "../models/permission.model";
import forge from "node-forge";

export const SetAuthData = async (dispatch: AppDispatch, user: IUser) => {
  dispatch(setUser(user));
  dispatch(setAvatar("/avatar/" + user.avatar));
};

export const HasPermission = (
  permissions: IPermissions[],
  resourceName: string,
  action: string
) => {
  if (permissions.length !== 0) {
    return permissions
      .find((element) => element.name === resourceName)
      ?.action?.includes(action);
  }
  return false;
};

export const HasResource = (
  permissions: IPermissions[],
  resourceName: string | Array<string>
) => {
  if (typeof resourceName === "string") {
    resourceName = [resourceName];
  }
  if (permissions.length !== 0) {
    let resource = permissions.find((element) =>
      resourceName.includes(element.name)
    );
    return resource ? true : false;
  }
  return false;
};

// export const SetEmpListData = async (reduxDispatch: AppDispatch) => {
//   const res = await getAll().then((res) => res.data);

//   const emp_list: Array<any> = res.map((data: any, i: any) => ({
//     ...data,
//     emp_id: data.username,
//     avatarURI: null,
//   }));

//   reduxDispatch(setEmpList(emp_list));
// };

export const DecryptText = (encryptedText: any | null, user: IUser | null) => {
  if (user && user.private_key) {
    if (encryptedText === null) return null;
    const privateKey = user.private_key;
    try {
      // Convert the private key string to a forge private key object
      const privateKeyObject = forge.pki.privateKeyFromPem(privateKey);

      // Convert the base64 encoded encrypted data to a forge buffer
      console.log(encryptedText);
      const encryptedBuffer = forge.util.decode64(encryptedText);

      console.log(encryptedBuffer);

      var decrypted = privateKeyObject.decrypt(encryptedBuffer, "RSA-OAEP", {
        md: forge.md.sha256.create(),
      });

      return decrypted;
      // return decryptedText;
    } catch (error: any) {
      console.error("Decryption error:", error.message);
      return "DECRYPT_ERR";
    }
    // return decryptedBuffer.toString("utf-8");
  } else {
    return "NO_KEY";
  }
};

export const SetEmpListData = async (reduxDispatch: AppDispatch) => {
  const res = await getEmpList().then((res) => res.data);

  const emp_list: Array<any> = res.map((data: any, i: any) => ({
    ...data,
    emp_id: data.username,
  }));

  // const emp_list: Array<any> = res.map((data: any, i: any) =>
  //   getAvatar(data.username)
  //     .then((file: any) => ({
  //       ...data,
  //       emp_id: data.username,
  //       avatarURI: URL.createObjectURL(file.data),
  //     }))
  //     .catch(() => {
  //       return {
  //         ...data,
  //         emp_id: data.username,
  //         avatarURI: person,
  //       };
  //     })
  // );

  // const result = await Promise.all(emp_list);

  reduxDispatch(setEmpList(emp_list));
};
