import {
  IonButtons,
  IonCard,
  IonChip,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import React, { useState } from "react";
import { IPeriod } from "../../../models/kpi.model";
import ModalForm from "./modal";
import { IScoreProjectData } from "models/kpi.model";

const List: React.FC<{
  Items: Array<IScoreProjectData>;
  ScoreList: Array<IScoreProjectData>;
  setScoreList: Function;
  countFilled: Function;
  period: IPeriod | null;
}> = ({ Items, ScoreList, setScoreList, countFilled, period }) => {
  const changeScore = (e: any, detail: IScoreProjectData) => {
    let index = ScoreList.findIndex(
      (score) => score.epic_id === detail.epic_id
    );
    if (index !== -1) {
      setScoreList([
        ...ScoreList.slice(0, index),
        { ...ScoreList[index], spm_acm: e },
        ...ScoreList.slice(index + 1),
      ]);
    }
  };

  const renderKPIList = ScoreList.map((p, i) => (
    <IonCard key={i}>
      <IonItem color="light" lines="full" className="ion-align-items-center">
        <IonChip color="tertiary">{p.epic_id}</IonChip>
        <IonText style={{ fontWeight: "bold" }}>{p.epic_name}</IonText>
        <IonButtons slot="end">
          <ModalForm period={p.period} epic_id={p.epic_id} />
        </IonButtons>
      </IonItem>
      <IonItem lines="none">
        <IonChip color="success">
          {`${Math.round(p.total_storypoint * 100) / 100} storypoint`}
        </IonChip>
      </IonItem>
      <IonItem lines="inset">
        <IonLabel position="fixed">{"SPM"}</IonLabel>
        <ScoreForm
          p={p}
          changeScore={changeScore}
          editable={Boolean(period?.edit_spm)}
        />
      </IonItem>
      <IonItem lines="none">
        <IonList className="ion-no-padding">
          <IonChip color="quaternary" style={{ fontWeight: "bold" }}>
            {p.flagship}
          </IonChip>
          <IonChip color="quaternary" style={{ fontWeight: "bold" }}>
            {p.type}
          </IonChip>
          <IonChip color="quaternary" style={{ fontWeight: "bold" }}>
            {p.ministry}
          </IonChip>
          <IonChip color="quaternary" style={{ fontWeight: "bold" }}>
            {p.department}
          </IonChip>
        </IonList>
      </IonItem>
    </IonCard>
  ));

  return (
    <div className="ion-align-self-center">
      {Boolean(Items.length) ? (
        renderKPIList
      ) : (
        <h1 className="ion-text-center">No Record</h1>
      )}
    </div>
  );
};

const ScoreForm: React.FC<{
  p: IScoreProjectData;
  changeScore: Function;
  editable: boolean;
}> = ({ p, changeScore, editable }) => {
  // const [spmACM, setSpmACM] = useState(p.spm_acm);
  const [isEdit, setIsEdit] = useState("dark");

  const checkEditable = () => {
    if (!editable) return true;
    else return false;
  };

  return (
    <IonInput
      type="number"
      name="spm_acm"
      id="spm_acm"
      max="5"
      min="0"
      step="0.5"
      value={p.spm_acm}
      color={isEdit}
      disabled={checkEditable()}
      onIonChange={(e) => {
        let score = parseFloat(e.detail.value!);
        if (score && (score > 5 || score < 0)) {
          setIsEdit("danger");
        }
      }}
      onIonBlur={(e: any) => {
        let score = parseFloat(e.target.value!);
        if (score && (score > 5 || score < 0)) {
          // setSpmACM(0);
          setIsEdit("danger");
          changeScore(0, p);
        } else {
          // setSpmACM(score);
          setIsEdit("dark");
          changeScore(score ? score : null, p);
        }
      }}
      onWheel={(e:any) => {
        e.preventDefault();
        e.target.blur();
      }}
    />
  );
};

export default List;
