import { IonAlert, IonLoading } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { DataTable } from "../../components/DataTable";
import { ArrayCell, BooleanCell, DateCell } from "../../components/FormInputType";
import { dataAPI } from "./api";
import Modal from "./modal";
import { InitModelData, modelData } from "./model";

const Table: React.FC<{
  Items: Array<modelData>;
  updateItems: Function;
  action: Array<string>;
  username: any;
  DropDownList: any;
}> = ({ Items, updateItems, action, username, DropDownList }) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState<any>();
  const [buttonLabel, setButtonLabel] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [labelAdd, setLabelAdd] = useState<any>();
  const [labelDel, setLabelDel] = useState<any>();
  const toggle = () => {
    setModal(!modal);
    console.log("modal")
  };
  const DataService = new dataAPI();

  useEffect(() => {
    //Check for add action and gen ID
    const option_add = action.find((element) => element.includes("add"));
    if (option_add) {
      const [, label] = option_add.split("-");
      if (label && label !== "all") {
        setLabelAdd(label);
      }
    }

    //Check for delete action
    const option_delete = action.find((element) => element.includes("delete"));
    if (option_delete) {
      const [, label] = option_delete.split("-");
      setLabelDel(label);
    }
  }, [Items, action]);

  const generateID = (isFiscalYear = false) => {
    //let year = "";
    const now = new Date();
    if (isFiscalYear) {
      const month = now.getMonth();
      if (month > 8)
        // more than september
        now.setFullYear(now.getFullYear() + 1);
    }
    const year = now.getFullYear().toString().substr(-2);
    const maxID = Items.reduce((acc, cur) => {
      if (cur.id.split("-")[0] !== labelAdd + year) return acc;
      const num = parseInt(cur.id.split("-")[1]);
      return num > acc ? num : acc;
    }, 0);
    return labelAdd + year + "-" + ("000" + (maxID + 1)).slice(-3);
  };

  const DeleteData = () => {
    if (confirmDelete) {
      setShowLoading(true);
      Promise.all(
        selectedRows.map((row: any) =>
          DataService.delete({ id: row.id }).then((item) => {
            updateItems((prevState: Array<any>) =>
              prevState.filter((element) => element.id !== row.id)
            );
            //setIsupdate(Math.random());
          })
        )
      ).then(() => setShowLoading(false));
    }
  };

  const columns = React.useMemo(() => {
    const checkEditable = (owner: string, id: string) => {
      const option = action.find((element) => element.includes("edit"));
      // if (!option && owner === username) return false;
      const [, label] = option ? option.split("-") : [null, null];
      if (
        (label && (label === "all" || id.startsWith(label))) ||
        owner === username
      )
        return true;
      else return false;
    };

    return [
      {
        Header: "ID",
        accessor: "id",
        Cell: (data: any) => (
          <div
            className={
              checkEditable(data.row.original.pm, data.row.original.id)
                ? "editable"
                : ""
            }
            onClick={() => {
              if (checkEditable(data.row.original.pm, data.row.original.id)) {
                setButtonLabel("edit");
                setItem({ ...data.row.original });
                setModal(true);
              }
            }}
          >
            {data.value}
          </div>
        ),
      },
      {
        Header: "Flagship",
        accessor: "flagship",
      },
      {
        Header: "Project Type",
        accessor: "prj_type",
      },
      {
        Header: "Project Name",
        accessor: "prj_name",
      },
      {
        Header: "Ministry",
        accessor: "ministry",
      },
      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Funded",
        accessor: "funded",
        Cell: BooleanCell,
      },
      {
        Header: "Income (Baht)",
        accessor: "total_unit",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Project Manager",
        accessor: "pm",
      },
      {
        Header: "Project Lead",
        accessor: "pl",
      },
      {
        Header: "Total Dataset",
        accessor: "total_dataset",
      },
      {
        Header: "Total Catalog",
        accessor: "total_catalog",
      },
      {
        Header: "Total Model",
        accessor: "total_model",
      },
      {
        Header: "Total Dashboard",
        accessor: "total_dashboard",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: DateCell,
      },
      {
        Header: "Finish Date",
        accessor: "end_date",
        Cell: DateCell,
      },
      {
        Header: "Epic Name",
        accessor: "epic_name",
      },
      {
        Header: "MOU",
        accessor: "mou",
      },
      {
        Header: "MOU Signee",
        accessor: "mou_signee",
      },
      {
        Header: "Project Complexity",
        accessor: "prj_complexity",
      },
      {
        Header: "Project Level",
        accessor: "prj_level",
      },
      {
        Header: "Period",
        accessor: "Period",
      },
      {
        Header: "Data Location",
        accessor: "prj_data_location",
      },
      {
        Header: "Accessible Employee",
        accessor: "access_emp",
        Cell: ArrayCell,
      },
      {
        Header: "Retention Type",
        accessor: "retention_type",
      },
      {
        Header: "Retention Status",
        accessor: "retention_status",
      },
      {
        Header: "Source Control",
        accessor: "source_control",
      },
    ];
  }, [action, username]);

  const addAction = () => {
    setButtonLabel("add");
    setItem({ ...{ ...InitModelData } });
    toggle();
  };

  const addActive = Boolean(
    action.find((element) => element.startsWith("add")) && labelAdd
  );

  const deleteAction = () => {
    setConfirmDelete(true);
  };

  const deleteActive = Boolean(
    action.find((element) => element.startsWith("delete")) &&
      selectedRows.length &&
      (selectedRows.every((element) => element.id.startsWith(labelDel)) ||
        labelDel === "all")
  );

  return (
    <>
      <Modal
        buttonLabel={buttonLabel}
        item={item}
        updateItems={updateItems}
        modal={modal}
        setModal={setModal}
        DropDownList={DropDownList}
        generateID={generateID}
      />
      <DataTable
        columns={columns}
        data={Items}
        Modal={Modal}
        setSelectedRows={setSelectedRows}
        addActive={addActive}
        addAction={addAction}
        deleteActive={deleteActive}
        deleteAction={deleteAction}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Deleting ..."}
        duration={0}
      />
      <IonAlert
        isOpen={confirmDelete}
        onDidDismiss={() => setConfirmDelete(false)}
        cssClass="my-custom-class"
        header={"Confirm Delete"}
        message={
          "Are you sure want to delete (" +
          selectedRows.map((d: any) => d.id) +
          ") ?"
        }
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {},
          },
          {
            text: "Okay",
            handler: () => {
              DeleteData();
            },
          },
        ]}
      />
    </>
  );
};

export default Table;
