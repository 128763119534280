import {
  IonAvatar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenuButton,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { StorypointChip, EmpChip } from "components/Chip";
import { people, person } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { selectAuthen } from "redux/authen/selector";
import { selectEmpAvatar } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import EmployeeSelect from "components/Select/EmployeeSelect";
import Spinning from "components/Spinning";
import { IPeriod, IRadar } from "models/kpi.model";
import useKPI from "store/KPIProvider";
import { roundTwoDigit } from "utils";
import { colorScheme } from "variable/colorScheme";
import { KPIDashboardAPI } from "api/KPIAPI";
import "./dashboard.scss";
import { DecryptText } from "utils/auth.util";

const Dashboard: React.FC = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [PeriodList, setPeriodList] = useState<Array<IPeriod>>([]);
  const [summary, setSummary] = useState<any>();
  const [updateDate, setUpdateDate] = useState<Date | null>();
  const [radar, setRadar] = useState<IRadar>();
  const [showLoadingRadar, setShowLoadingRadar] = useState(true);

  const {
    state: { period, emp_id },
    dispatch,
  } = useKPI();

  const avatar = useAppSelector(selectEmpAvatar(emp_id));
  const { user } = useAppSelector(selectAuthen);

  useEffect(() => {
    setShowLoading(true);
    PeriodList.length === 0 &&
      KPIDashboardAPI.readPeriod().then((res) => {
        const periods = [...res.data];
        setPeriodList(periods);

        if (!period) {
          const defaultPeriods = periods.find((period) => period.default_indv);
          dispatch({ type: "SET_PERIOD", period: defaultPeriods });
          setShowLoading(false);
        }
      });
  }, [dispatch, period, PeriodList]);
  useEffect(() => {
    if (period && emp_id) {
      setShowLoading(true);
      setShowLoadingRadar(true);
      KPIDashboardAPI.read({ period: period.name, emp_id: emp_id }).then(
        (res) => {
          setSummary(res.data);
          setShowLoading(false);
          setUpdateDate(new Date(res.data.updateDate));
        }
      );
      KPIDashboardAPI.readRadar({ period: period.name, emp_id: emp_id }).then(
        (res) => {
          setRadar(res.data);
          setShowLoadingRadar(false);
        }
      );
    }
  }, [period, emp_id]);

  return (
    <>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>KPI Management</IonTitle>
        </IonToolbar>
        {user &&
          Boolean(user.role.includes("admin") || user.role.includes("spm")) && (
            <IonToolbar>
              <EmployeeSelect
                label="Employee"
                value={emp_id}
                name="Employee"
                onChange={(e: any) => {
                  dispatch({
                    type: "SET_EMP",
                    emp_id: e.target.value,
                  });
                }}
              />
            </IonToolbar>
          )}
      </IonHeader>
      <IonContent>
        <IonCard>
          {period && (
            <IonCardHeader>
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="3">
                    <IonCardTitle>{period.name}</IonCardTitle>
                    <IonCardSubtitle>
                      {new Date(period.startdate).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                      &mdash;&nbsp;
                      {new Date(period.enddate).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </IonCardSubtitle>
                  </IonCol>
                  <IonCol size="12" size-md="9">
                    <IonItem>
                      <IonLabel>Change Period</IonLabel>
                      <IonSelect
                        placeholder="Select One"
                        value={period}
                        compareWith={(cur: IPeriod, opt: IPeriod) =>
                          cur && opt ? cur.name === opt.name : cur === opt
                        }
                        interface="popover"
                        onIonChange={(e) => {
                          dispatch({
                            type: "SET_PERIOD",
                            period: e.detail.value,
                          });
                        }}
                      >
                        {PeriodList.map((period, i) => (
                          <IonSelectOption key={i} value={period}>
                            {period.name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
          )}
          <IonGrid>
            <IonRow>
              {emp_id && (
                <IonAvatar
                  className="ion-padding"
                  style={{
                    margin: "auto",
                    width: "250px",
                    height: "250px",
                  }}
                >
                  <img src={avatar} alt="avatar" />
                </IonAvatar>
              )}

              <IonCol>
                {showLoadingRadar ? (
                  <Spinning />
                ) : radar && radar.data && radar.data.length >= 3 ? (
                  <ResponsiveContainer width="100%" height={250}>
                    <RadarChart data={radar.data}>
                      <PolarGrid />
                      <PolarAngleAxis dataKey="dimension_desc" />
                      <PolarRadiusAxis angle={30} domain={[0, 5]} />
                      <PolarRadiusAxis />
                      <Radar
                        name={radar.staff_id_b}
                        dataKey="score"
                        stroke="#8884d8"
                        fill="#f9c900"
                        fillOpacity={0.6}
                      />
                      <Tooltip
                        formatter={(value: any) =>
                          `score ${roundTwoDigit(value)} `
                        }
                      />

                      <Legend />
                    </RadarChart>
                  </ResponsiveContainer>
                ) : (
                  <></>
                )}
              </IonCol>
              <IonCol>
                {showLoading ? (
                  <Spinning />
                ) : (
                  <IonCardContent>
                    <IonList>
                      <IonItemGroup>
                        <IonItem>
                          <IonLabel>
                            Self Score{" "}
                            <StorypointChip
                              value={summary.summary.self_total_storypoint}
                            />
                          </IonLabel>
                          {summary.summary.self_acm
                            ? roundTwoDigit(summary.summary.self_acm)
                            : "N/A"}
                        </IonItem>
                        <IonItem>
                          <IonLabel>
                            Tier-1 Score{" "}
                            <StorypointChip
                              value={summary.summary.pm_total_storypoint}
                            />
                          </IonLabel>
                          {summary.summary.pm_acm
                            ? roundTwoDigit(summary.summary.pm_acm)
                            : "N/A"}
                        </IonItem>
                        <IonItem>
                          <IonLabel>360 Feedback Score</IonLabel>
                          {summary.summary.e360_acm
                            ? roundTwoDigit(summary.summary.e360_acm)
                            : "N/A"}
                        </IonItem>
                        <IonItem>
                          <IonLabel>Tier-2 Score</IonLabel>
                          {summary.summary.final_score || summary.summary.vp_acm
                            ? roundTwoDigit(
                                summary.summary.final_score
                                  ? summary.summary.final_score
                                  : summary.summary.vp_acm
                              )
                            : "N/A"}
                        </IonItem>
                        <IonItem>
                          <IonLabel position="stacked">
                            Tier-2 Adjustment Reason
                          </IonLabel>
                          <IonTextarea
                            readonly
                            value={
                              summary.summary.vp_comment
                                ? summary.summary.vp_comment
                                : "N/A"
                            }
                          ></IonTextarea>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Tier-3 Score</IonLabel>
                          {summary.summary.director_acm
                            ? roundTwoDigit(summary.summary.director_acm)
                            : "N/A"}
                        </IonItem>
                        <IonItem>
                          <IonLabel position="stacked">
                            Tier-3 Adjustment Reason
                          </IonLabel>
                          <IonTextarea
                            readonly
                            value={
                              summary.summary.comment
                                ? summary.summary.comment
                                : "N/A"
                            }
                          ></IonTextarea>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Final Grade</IonLabel>
                          {summary.summary.director_acm
                            ? DecryptText(summary.summary.final_grade, user)
                            : "N/A"}
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                  </IonCardContent>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
        {showLoading ? (
          <Spinning />
        ) : (
          <>
            {Array.isArray(summary.indv_issue_project) &&
              Array.isArray(summary.indv_issue_kpi) &&
              summary.indv_issue_project.length > 0 &&
              summary.indv_issue_kpi.length > 0 && (
                <IonCard>
                  <IonCardHeader>
                    <IonItem lines="none">
                      <IonCardTitle>
                        <IonIcon
                          icon={person}
                          color="orange"
                          size="large"
                          className="ion-margin-end"
                        />
                        {`Individual KPI ${
                          updateDate &&
                          "(" +
                            updateDate.toLocaleString("en-GB", {
                              year: "2-digit",
                              month: "short",
                              day: "numeric",
                              hour12: false,
                              hour: "2-digit",
                              minute: "2-digit",
                            }) +
                            ")"
                        }`}
                      </IonCardTitle>
                    </IonItem>
                  </IonCardHeader>
                  <IonGrid>
                    <IonRow>
                      <IonCol
                        style={{
                          maxWidth: "250px",
                          margin: "0px auto",
                        }}
                      >
                        <ResponsiveContainer height={250}>
                          <PieChart>
                            <Pie
                              data={summary.indv_issue_kpi}
                              nameKey="depa_indv_group"
                              dataKey="total_storypoint"
                            >
                              {summary.indv_issue_kpi.map(
                                (entry: any, index: number) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={
                                      colorScheme[index % colorScheme.length]
                                    }
                                  />
                                )
                              )}
                            </Pie>
                            <Tooltip
                              formatter={(value: any) =>
                                `${roundTwoDigit(value)} storypoint`
                              }
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </IonCol>
                      <IonCol>
                        <IonCardContent>
                          <IonList>
                            <IonCardHeader>
                              <IonCardTitle>KPI Group</IonCardTitle>
                            </IonCardHeader>
                            <IonItemGroup>
                              {summary.indv_issue_kpi.map(
                                (kpi: any, i: number) => (
                                  <IonItem key={i}>
                                    {kpi.depa_indv_group}
                                    <StorypointChip
                                      value={kpi.total_storypoint}
                                    />
                                  </IonItem>
                                )
                              )}
                            </IonItemGroup>
                          </IonList>
                        </IonCardContent>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol
                        style={{
                          maxWidth: "250px",
                          margin: "0px auto",
                        }}
                      >
                        <ResponsiveContainer height={250}>
                          <PieChart>
                            <Pie
                              data={summary.indv_issue_project}
                              nameKey="prj_id"
                              dataKey="total_storypoint"
                            >
                              {summary.indv_issue_project.map(
                                (entry: any, index: number) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={
                                      colorScheme[index % colorScheme.length]
                                    }
                                  />
                                )
                              )}
                            </Pie>
                            <Tooltip
                              formatter={(value: any) =>
                                `${roundTwoDigit(value)} storypoint`
                              }
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </IonCol>
                      <IonCol>
                        <IonCardContent>
                          <IonList>
                            <IonCardHeader>
                              <IonCardTitle>Project</IonCardTitle>
                            </IonCardHeader>
                            <IonItemGroup>
                              {summary.indv_issue_project.map(
                                (prj: any, i: number) => (
                                  <IonItem key={i}>
                                    <IonChip color="secondary">
                                      {prj.prj_id}
                                    </IonChip>
                                    {prj.prj_detail.pname}
                                    <IonButtons slot="end">
                                      <EmpChip value={prj.prj_detail.lead} />
                                      <StorypointChip
                                        value={prj.total_storypoint}
                                      />
                                    </IonButtons>
                                  </IonItem>
                                )
                              )}
                            </IonItemGroup>
                          </IonList>
                        </IonCardContent>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              )}

            {Array.isArray(summary.prj_issue) &&
              summary.prj_issue.length > 0 && (
                <IonCard>
                  <IonCardHeader>
                    <IonItem lines="none">
                      <IonCardTitle>
                        <IonIcon
                          icon={people}
                          color="orange"
                          size="large"
                          className="ion-margin-end"
                        />
                        {`Project Member KPI ${
                          updateDate &&
                          "(" +
                            updateDate.toLocaleString("en-GB", {
                              year: "2-digit",
                              month: "short",
                              day: "numeric",
                              hour12: false,
                              hour: "2-digit",
                              minute: "2-digit",
                            }) +
                            ")"
                        }`}
                      </IonCardTitle>
                    </IonItem>
                  </IonCardHeader>
                  <IonGrid>
                    {summary.prj_issue.map((prj: any, i: number) => (
                      <IonRow key={i}>
                        <IonCol
                          style={{
                            maxWidth: "250px",
                            margin: "0px auto",
                          }}
                        >
                          <ResponsiveContainer height={250}>
                            <PieChart>
                              <Pie
                                data={prj.list}
                                nameKey="emp_id"
                                dataKey="total_storypoint"
                              >
                                {prj.list.map((entry: any, index: number) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={
                                      colorScheme[index % colorScheme.length]
                                    }
                                  />
                                ))}
                              </Pie>
                              <Tooltip
                                formatter={(value: any) =>
                                  `${roundTwoDigit(value)} storypoint`
                                }
                              />
                            </PieChart>
                          </ResponsiveContainer>
                        </IonCol>
                        <IonCol>
                          <IonCardHeader>
                            <IonCardTitle>{prj.pname}</IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent>
                            <IonList>
                              <IonItemGroup>
                                {prj.list.map((prj: any, i: number) => (
                                  <IonItem key={i}>
                                    <EmpChip value={prj.emp_id}></EmpChip>
                                    <StorypointChip
                                      value={prj.total_storypoint}
                                    />
                                  </IonItem>
                                ))}
                              </IonItemGroup>
                            </IonList>
                          </IonCardContent>
                        </IonCol>
                      </IonRow>
                    ))}
                  </IonGrid>
                </IonCard>
              )}
          </>
        )}
      </IonContent>
    </>
  );
};

export default Dashboard;
