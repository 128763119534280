import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { download } from "ionicons/icons";
import React, { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { getEmpDetail } from "utils";
import { EmpChip } from "../../../components/Select/EmployeeSelect";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import { roundTwoDigit } from "../../../utils";
import { EmployeeAPI } from "api/KPIAPI";

const ExportModal: React.FC = () => {
  const [modal, setModal] = React.useState(false);
  const [scoreList, setScoreList] = React.useState<Array<any>>([]);
  const [report, setReport] = React.useState<Array<any>>([]);
  const [textLoading, setTextLoading] = React.useState<boolean>(false);
  const [isRound, setIsRound] = React.useState(false);
  const csvInstance = useRef<any>(null);
  const empList = useAppSelector(selectEmpList);

  const {
    state: { period },
  } = useKPI();

  useEffect(() => {
    if (modal) {
      setTextLoading(true);
      period &&
        EmployeeAPI.readReport!({ period: period.name }).then((items) => {
          const data = items.data;
          setTextLoading(false);
          setScoreList(data);
          setIsRound(false);
          setReport(
            data.map((emp: any) => ({
              ...emp,
              ...getEmpDetail(emp.emp_id, empList),
            }))
          );
        });
    }
  }, [modal, period, empList]);

  const toggle = () => {
    setModal(!modal);
  };

  const Loadingstyle = {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  };

  const filename = "AllEmployeeKPI-" + period?.name + ".csv";

  const headers = [
    { label: "username", key: "emp_id" },
    { label: "emp_id", key: "depa_emp_id" },
    { label: "firstname", key: "firstname" },
    { label: "lastname", key: "lastname" },
    { label: "director_score", key: "director_acm" },
    { label: "diractor_reason", key: "comment" },
    { label: "vp_score", key: "vp_acm" },
    { label: "vp_reason", key: "vp_comment" },
    { label: "pm_score", key: "pm_acm" },
    { label: "360_score", key: "e360_acm" },
    { label: "kpi_score", key: "kpi_acm" },
    { label: "pip_score", key: "pip_acm" },
    { label: "pms_score", key: "pms_acm" },
    { label: "goal_oriented_score", key: "di2_acm" },
    { label: "agility_lifelong_learning_score", key: "di3_acm" },
    { label: "integrity_score", key: "di4_acm" },
    { label: "time_management_score", key: "di5_acm" },
    { label: "effective_communication_score", key: "di6_acm" },
  ];

  const downloadCSV = () => {
    csvInstance.current.link.click();
    setModal(false);
  };

  const handleRounding = (e: any) => {
    const checked = e.detail.checked;
    setIsRound(checked);
    if (checked) {
      setReport(
        scoreList.map((emp: any) => ({
          ...emp,
          ...getEmpDetail(emp.emp_id, empList),
          director_acm: Math.round(emp.director_acm * 2) / 2,
          vp_score: Math.round(emp.vp_score * 2) / 2,
          pm_score: Math.round(emp.pm_score * 2) / 2,
        }))
      );
    } else {
      setReport(
        scoreList.map((emp: any) => ({
          ...emp,
          ...getEmpDetail(emp.emp_id, empList),
          director_acm: emp.director_acm,
          vp_score: emp.vp_score,
          pm_score: emp.pm_score,
        }))
      );
    }
  };

  return (
    <>
      <IonButton aria-label="Save" onClick={() => toggle()}>
        <IonIcon slot="icon-only" icon={download} />
      </IonButton>
      <IonModal isOpen={modal}>
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonButtons slot="start"></IonButtons>
            <IonTitle>Export</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonItem lines="none">
              <IonCheckbox
                slot="start"
                checked={isRound}
                onIonChange={handleRounding}
                disabled={report.length === 0}
              />
              <IonLabel>Mathematical Rounding</IonLabel>
              <IonButtons slot="end">
                <IonButton disabled={report.length === 0} onClick={downloadCSV}>
                  Download
                </IonButton>
              </IonButtons>
            </IonItem>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-justify-content-center">
          {textLoading ? (
            <Spinning />
          ) : scoreList.length !== 0 ? (
            <>
              <CSVLink
                ref={csvInstance}
                data={report}
                headers={headers}
                filename={filename}
                target="_blank"
              />
              <IonList>
                {report.map((value) => (
                  <IonItem key={value.emp_id}>
                    <EmpChip value={value.emp_id}></EmpChip>
                    <IonText slot="end">
                      {roundTwoDigit(value.director_acm)}
                    </IonText>
                  </IonItem>
                ))}
              </IonList>
            </>
          ) : (
            <div style={Loadingstyle}>No Data</div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ExportModal;
