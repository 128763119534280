import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "../../api/APIUtils";
import React, { useEffect, useState } from "react";

interface ministry {
  data: { name: string; value: string };
  checked: boolean;
}

const MinistrySelect: React.FC<{
  label: string;
  value: any;
  name: string;
  color?: string;
  position?: string;
  multiple?: boolean;
  onChange: Function;
}> = ({ label, value, onChange, name, color, position, multiple }: any) => {
  const [MinistryList, setMinistryList] = useState<Array<ministry>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [TextLoading, setTextLoading] = useState<boolean>(false);
  //const [selectEmp, setSelectEmp] = useState<Array<ministry_checkBox>>([]);
  const [modal, setModal] = useState<boolean>(false);

  useEffect(() => {
    const getMinistry = () =>
    axios({
      method: "get",
      url: "/api/ministry",
      params: { search: searchText },
    });
    
    setTextLoading(true);
    getMinistry().then((res) => {
      setMinistryList(() => {
        const list = [...res.data].map((element) => ({
          data: { name: element.name, value: element.name },
          checked: value && value.includes(element.name) ? true : false,
        }));

        return searchText
          ? [
              ...list,
              {
                checked: true,
                data: { name: 'Add "' + searchText + '"', value: searchText },
              },
            ]
          : list;
      });
      setTextLoading(false);
    });
  }, [value, searchText]);

  return (
    <>
      <IonItem
        button
        detail={false}
        lines="full"
        onClick={() => {
          setModal(true);
        }}
      >
        <IonLabel position={multiple ? "fixed" : position} color={color}>
          {label}
        </IonLabel>
        {Array.isArray(value) ? (value as string[]).join(", ") : value}
      </IonItem>
      {/* <IonInput readonly type="text" name={name} id={name} value={value} /> */}

      <IonModal isOpen={modal} >
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonTitle>Ministry List</IonTitle>
            <IonButtons slot="end">
              {multiple && (
                <IonButton
                  expand="block"
                  onClick={() => {
                    const selected = MinistryList.filter(
                      (ministry) => ministry.checked
                    ).map((ministry) => ministry.data.name);
                    const event = { target: { name: name, value: selected } };
                    onChange(event);
                    setModal(false);
                  }}
                >
                  Submit
                </IonButton>
              )}
              <IonButton
                expand="block"
                onClick={() => {
                  setSearchText("");
                  if (multiple)
                    setMinistryList((prevState) =>
                      prevState.map((element) => ({
                        ...element,
                        checked:
                          value && value.includes(element.data.name)
                            ? true
                            : false,
                      }))
                    );
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar className="ion-align-items-center">
            <IonSearchbar
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {TextLoading ? (
            <IonRow className="ion-justify-content-center">
              <IonSpinner name="dots" />
            </IonRow>
          ) : (
            <>
              <IonList style={{ background: "none" }}>
                {MinistryList.map((ministry, i) => (
                  <MinistryItem
                    key={i}
                    item={ministry.data}
                    display={
                      ministry.data.name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) > -1
                        ? true
                        : false
                    }
                    checked={ministry.checked}
                    onClick={() => {
                      if (multiple) {
                        setMinistryList((prevState) =>
                          prevState.map((element) =>
                            element.data.name === ministry.data.name
                              ? {
                                  ...element,
                                  checked: !element.checked,
                                }
                              : element
                          )
                        );
                      } else {
                        setSearchText("");
                        const event = {
                          target: { name: name, value: ministry.data.value },
                        };
                        onChange(event);
                        setModal(false);
                      }
                    }}
                    multiple={multiple}
                  />
                ))}
              </IonList>
            </>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

const MinistryItem: React.FC<{
  item: any;
  display: boolean;
  onClick: Function;
  multiple: boolean;
  checked: boolean;
}> = ({ item, display, onClick, multiple, checked }) => {
  const [check, setCheck] = useState<boolean | undefined>(checked);
  return (
    <IonItem
      button
      onClick={() => {
        setCheck(!check);
        onClick();
      }}
      style={{ display: display ? "block" : "none" }}
    >
      {multiple && <IonCheckbox checked={check} slot="start" color="primary" />}
      <IonLabel>{item.name}</IonLabel>
    </IonItem>
  );
};

export default MinistrySelect;
