import React from "react";
import {
  IonCheckbox,
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from "@ionic/react";
import { fullLabelStyle } from "variable";
export const InputText = ({
  label,
  name,
  value,
  onChange,
  readonly,
  ...rest
}: any) => {
  return (
    <IonItem lines={readonly ? "none" : "inset"}>
      <IonLabel position="stacked" color="primary">
        {label}
      </IonLabel>
      <IonInput
        type="text"
        name={name}
        id={name}
        onIonBlur={(e) => onChange(e)}
        value={value}
        readonly={readonly}
        {...rest}
      />
    </IonItem>
  );
};

export const InputTextArea = ({
  label,
  name,
  value,
  onChange,
  readonly,
  ...rest
}: any) => {
  return (
    <IonItem lines={readonly ? "none" : "inset"}>
      <IonLabel position="stacked" color="primary">
        {label}
      </IonLabel>
      <IonTextarea
        type="text"
        name={name}
        id={name}
        onIonBlur={(e) => onChange(e)}
        value={value}
        readonly={readonly}
        {...rest}
      />
    </IonItem>
  );
};

export const InputCheckbox = ({
  label,
  name,
  value,
  onChange,
  readonly,
  slot,
  ...rest
}: any) => {
  return (
    <IonItem lines="none">
      <IonLabel position="fixed" color="primary">
        {label}
      </IonLabel>
      <IonCheckbox
        slot={slot || "end"}
        color="secondary"
        name={name}
        id={name}
        onIonChange={(e) => {
          onChange({ target: { name: name, value: e.detail.checked } });
        }}
        checked={value}
        disabled={readonly}
        {...rest}
      />
    </IonItem>
  );
};

export const InputNumber = ({
  label,
  name,
  value,
  onChange,
  readonly,
  ...rest
}: any) => {
  return (
    <IonItem lines={readonly ? "none" : "inset"}>
      <IonLabel position="stacked" color="primary">
        {label}
      </IonLabel>
      <IonInput
        type="number"
        name={name}
        id={name}
        // onIonChange={(e: any) => {
        //   e.target.value = parseFloat(e.target.value);
        //   onChange(e);
        // }}
        onIonBlur={(e: any) => {
          e.target.value = Math.round(parseFloat(e.target.value) * 100) / 100;
          onChange(e);
        }}
        value={value}
        readonly={readonly}
        {...rest}
      />
    </IonItem>
  );
};

export const InputDate = ({
  label,
  name,
  value,
  onChange,
  readonly,
  presentation,
  ...rest
}: any) => {
  return (
    <IonItem lines={readonly ? "none" : "inset"}>
      <IonLabel position="stacked" color="primary">
        {label}
      </IonLabel>
      <IonDatetime
        name={name}
        id={name}
        displayTimezone="Asia/Bangkok"
        displayFormat="D MMM YYYY"
        value={value}
        onIonChange={(e: any) => {
          onChange(e);
        }}
        presentation="date"
        readonly={readonly}
        {...rest}
      ></IonDatetime>
    </IonItem>
  );
};

export const InputSelection = ({
  label,
  name,
  value,
  option,
  onChange,
  ...rest
}: any) => {
  return (
    <IonItem lines="inset">
      <IonLabel position="fixed" color="primary" style={fullLabelStyle}>
        {label}
      </IonLabel>
      <IonSelect
        slot="end"
        interface="popover"
        value={value}
        name={name}
        onIonChange={(e) => onChange(e)}
        {...rest}
      >
        {(option as String[]).map((option, i) => (
          <IonSelectOption key={i} value={option}>
            {option}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export const DateCell = (d: any) => {
  if (d.value == null) return "N/A";
  //const options = { year: "numeric", month: "short", day: "numeric" };
  let date = new Date(d.value).toLocaleDateString("en-GB");
  return date;
};

export const FloatCell = (d: any) =>
  d.value ? parseFloat(d.value).toFixed(2) : "0";

export const ArrayCell = (d: any) => (d.value as string[]).join(", ");

export const BooleanCell = (d: any) => {
  if (d.value === true) return "Yes";
  else if (d.value === false) return "No";
  else return null;
};
