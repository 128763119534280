export interface modelData {
	id: string,
	flagship: string,
	prj_type: string,
	prj_name: string,
	ministry: string,
	department: string,
    funded: boolean,
	total_unit: number,
	status: string,
	pm: string,
    pl: string,
	total_dataset: number,
	total_catalog: number,
	total_model: number,
	total_dashboard: number,
	start_date: Date | null,
	end_date: Date | null,
	epic_name: string,
	mou: string,
	mou_signee: string,
	prj_complexity: string,
	prj_level: string,
	Period: string,
    prj_data_location: string,
    retention_type: string,
    retention_status: string,
    source_control: string,
    access_emp: Array<string>,
}

export const InitModelData: modelData = {
	id: "",
	flagship: "",
	prj_type: "",
	prj_name: "",
	ministry: "",
	department: "",
    funded: false,
	total_unit: 0,
	status: "",
	pm: "",
    pl: "",
	total_dataset: 0,
	total_catalog: 0,
	total_model: 0,
	total_dashboard: 0,
	start_date: null,
	end_date: null,
	epic_name: "",
	mou: "",
	mou_signee: "",
	prj_complexity: "",
	prj_level: "",
	Period: "",
    prj_data_location: "",
    retention_type: "",
    retention_status: "",
    source_control: "",
    access_emp: [],
};