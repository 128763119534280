import { IonRouterOutlet } from "@ionic/react";
import { IEventKey } from "models/kpi.model";
import { IGroupAPI } from "models/rest-api.model";
import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { KPIProvider } from "../../store/KPIProvider";
import PeriodSelect from "./PeriodSelect";

interface KPIRouterProps {
  MainComponent: any;
  API: IGroupAPI;
  title: string;
  eventKey: IEventKey;
  eventTitleActive: string;
}

const KPIRouter: React.FC<KPIRouterProps> = ({
  MainComponent,
  API,
  title,
  eventKey,
  eventTitleActive,
}) => {
  const { url } = useRouteMatch();
  return (
    <KPIProvider>
      <IonRouterOutlet>
        <Route
          path={`${url}`}
          exact={true}
          render={(props) => (
            <PeriodSelect
              API={API}
              title={title}
              eventKey={eventKey}
              eventTitleActive={eventTitleActive}
              {...props}
            />
          )}
        />
        <Route
          path={`${url}/detail`}
          exact={true}
          render={(props) => (
            <MainComponent title={title} path={`${url}`} {...props} />
          )}
        />
        <Route render={() => <Redirect to={`${url}`} />} />
      </IonRouterOutlet>
    </KPIProvider>
  );
};

export default KPIRouter;
