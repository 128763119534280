import {
  IonButton,
  IonButtons,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { analytics, download } from "ionicons/icons";
import React, { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { getEmpDetail } from "utils";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import { ReportKPIGroupAPI } from "api/KPIAPI";

const ExportModal: React.FC<{ emp_id: string }> = ({ emp_id }) => {
  const [modal, setModal] = React.useState(false);
  const [report, setReport] = React.useState<Array<any>>([]);
  const [textLoading, setTextLoading] = React.useState<boolean>(false);
  const csvInstance = useRef<any>(null);

  const empList = useAppSelector(selectEmpList);

  const {
    state: { period },
  } = useKPI();

  useEffect(() => {
    if (modal) {
      setTextLoading(true);
      period &&
        ReportKPIGroupAPI.readReport({
          period: period.name,
          emp_id: emp_id,
        }).then((items) => {
          const data = items.data;
          setTextLoading(false);
          setReport(
            data.map((data: any, i: number) => {
              const {
                topic_name,
                comment,
                depa_indv_group,
                description,
                weight,
                score,
              } = data;
              const empDetail = getEmpDetail(emp_id, empList);
              const depa_emp_id = empDetail ? empDetail.depa_emp_id : null;
              return {
                rec_id:
                  depa_emp_id +
                  new Date().getFullYear().toString() +
                  ("000" + (i + 1)).slice(-3),
                emp_id: depa_emp_id,
                topic_name: topic_name ? topic_name : depa_indv_group,
                comment: comment,
                description: description,
                weight: Math.round((weight + Number.EPSILON) * 100) / 100,
                score: Math.round((score + Number.EPSILON) * 100) / 100,
              };
            })
          );
        });
    }
  }, [modal, period, empList, emp_id]);

  const toggle = () => {
    setModal(!modal);
  };

  const Loadingstyle = {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  };

  const filename = emp_id + "-" + period?.name + ".csv";

  const headers = [
    { label: "rec_id", key: "rec_id" },
    { label: "emp_id", key: "emp_id" },
    { label: "topic", key: "topic_name" },
    { label: "content", key: "description" },
    { label: "weight", key: "weight" },
    { label: "score", key: "score" },
    { label: "comment", key: "comment" },
  ];

  const downloadCSV = () => {
    csvInstance.current.link.click();
  };

  return (
    <>
      <IonButton aria-label="Save" onClick={() => toggle()}>
        <IonIcon slot="icon-only" icon={download} />
      </IonButton>
      <IonModal isOpen={modal} >
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonButtons slot="start"></IonButtons>
            <IonTitle>Export</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonItem lines="none">
              <IonButtons slot="end">
                <IonButton disabled={report.length === 0} onClick={downloadCSV}>
                  Download
                </IonButton>
              </IonButtons>
            </IonItem>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-justify-content-center">
          {textLoading ? (
            <Spinning />
          ) : report.length !== 0 ? (
            <>
              <CSVLink
                ref={csvInstance}
                data={report}
                headers={headers}
                filename={filename}
                target="_blank"
              />
              <IonList>
                {report.map((value, i) => (
                  <IonCard key={i}>
                    <IonGrid className="ion-no-padding ion-no-margin">
                      <IonRow>
                        <IonCol size="12">
                          <IonItemDivider>
                            <IonIcon slot="start" icon={analytics} />
                            <IonText
                              color="dark"
                              style={{ fontWeight: "bold" }}
                            >
                              {value.topic_name}
                            </IonText>
                          </IonItemDivider>
                          <IonItem>
                            <IonLabel position="stacked">Description</IonLabel>
                            <IonText>
                              {value.description !== ""
                                ? value.description
                                : "-"}
                            </IonText>
                          </IonItem>
                          <IonItem>
                            <IonLabel position="stacked">Comment</IonLabel>
                            <IonText>
                              {value.comment !== "" ? value.comment : "-"}
                            </IonText>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="6">
                          <IonItem lines="none">
                            <IonChip>Weight</IonChip>
                            <IonInput readonly>
                              {Math.round(value.weight * 100) / 100 + " %"}
                            </IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol size="6">
                          <IonItem lines="none">
                            <IonChip>Score</IonChip>
                            <IonInput readonly>
                              {Math.round(value.score * 100) / 100}
                            </IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                ))}
              </IonList>
            </>
          ) : (
            <div style={Loadingstyle}>No Data</div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ExportModal;
