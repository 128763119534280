import {
  IonAvatar,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Collapse } from "@material-ui/core";
import { logout } from "api/AuthAPI";
import {
  book,
  chevronDown,
  chevronUp,
  download,
  home,
  logOut,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { removeUser } from "redux/authen/reducer";
import { selectAuthen } from "redux/authen/selector";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import routes, { routeGroup } from "../pages/route";
import { HasResource } from "../utils/auth.util";
import { useAddToHomescreenPrompt } from "./Hooks/useAddToHomescreenPrompt";
import "./Menu.scss";
import { powerbiIcon } from "variable/customIcon";

const MenuGroup: React.FC<{ title: string; icon: string }> = ({
  children,
  title,
  icon,
}) => {
  const [expand, setExpand] = useState(false);

  const toggle = () => {
    setExpand(!expand);
  };
  return (
    <div>
      <IonItem lines="none" button detail={false} onClick={toggle}>
        <IonIcon slot="start" size="small" icon={icon} />
        <IonLabel>{title}</IonLabel>
        <IonIcon
          slot="end"
          style={{ fontSize: "15px" }}
          icon={expand ? chevronUp : chevronDown}
        />
      </IonItem>
      <Collapse in={expand}>
        <div style={{ paddingLeft: "10px" }}>{children}</div>
      </Collapse>
    </div>
  );
};

const Menu: React.FC = () => {
  //const decoratedOnClick = useAccordionToggle(eventKey, onClick);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = React.useState(false);
  const location = useLocation();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isAuthenticated, user, avatar, permission, dashboards } =
    useAppSelector(selectAuthen);

  useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [user, isAuthenticated, prompt, isVisible]);

  const mainMenu = (
    <>
      <IonMenuToggle auto-hide="false">
        <IonItem
          detail={false}
          lines="none"
          routerLink={"/profile"}
          routerDirection="none"
          className={
            location.pathname.endsWith("/profile") ? "selected" : undefined
          }
        >
          <IonAvatar
            style={{ maxWidth: "35px", maxHeight: "35px" }}
            slot="start"
          >
            <img src={avatar} alt="avatar" width="250px" height="250px" />
          </IonAvatar>
          <IonLabel>{user?.firstname + " " + user?.lastname}</IonLabel>
        </IonItem>
      </IonMenuToggle>
      <IonMenuToggle auto-hide="false">
        <IonItem
          detail={false}
          lines="none"
          routerLink={"/home"}
          routerDirection="none"
          className={
            location.pathname.endsWith("/home") ? "selected" : undefined
          }
        >
          <IonIcon slot="start" size="small" icon={home} />
          <IonLabel>Home</IonLabel>
        </IonItem>
      </IonMenuToggle>
    </>
  );

  const MenuItem = () => {
    const pageList = routes.reduce((result: any, route) => {
      const { group, ...rest } = route;
      if (!route.requirePermission || HasResource(permission, route.page))
        result[group]
          ? result[group].push({
              title: rest.title,
              path: rest.path,
              name: rest.page,
              icon: rest.icon,
              subpage: null,
              style: rest.style,
            })
          : (result[group] = [
              {
                title: rest.title,
                path: rest.path,
                name: rest.page,
                icon: rest.icon,
                subpage: null,
                style: rest.style,
              },
            ]);
      return result;
    }, {});

    const groupMenu = routeGroup.reduce((result: any, routeGroup) => {
      if (pageList[routeGroup.name])
        result.push({
          ...routeGroup,
          subpage: pageList[routeGroup.name],
        });
      return result;
    }, []);

    return groupMenu;
  };

  const DashboardItem = () => {
    const DashboardList = dashboards.map((value) => {
      const result = {
        title: value.name,
        path: "/" + value.id,
        name: value.name,
        icon: powerbiIcon,
        subpage: null,
        style: { marginLeft: "10px" },
      };
      return result;
    });

    const dashboardGroup = [
      {
        title: "BI Dashboard",
        path: "",
        name: "dashboard",
        icon: powerbiIcon,
        subpage: DashboardList,
        collapse: true,
      },
    ];

    return dashboardGroup;
  };

  const renderlistItems = (list: Array<any>) => {
    return list.map((p, i) =>
      p.subpage ? (
        <IonList key={i}>
          {p.collapse ? (
            <MenuGroup title={p.title} icon={p.icon}>
              {renderlistItems(p.subpage)}
            </MenuGroup>
          ) : (
            p.subpage.map((p: any, i: any) => (
              <IonMenuToggle key={i} auto-hide="false">
                <IonItem
                  detail={false}
                  routerLink={p.path}
                  style={p.style}
                  routerDirection="none"
                  lines="none"
                  className={
                    location.pathname.startsWith(p.path)
                      ? "selected"
                      : undefined
                  }
                >
                  <IonIcon slot="start" size="small" icon={p.icon} />
                  <IonLabel>{p.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            ))
          )}
        </IonList>
      ) : (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection="none"
            lines="none"
            className={
              location.pathname.endsWith(p.path) ? "selected" : undefined
            }
          >
            <IonIcon slot="start" size="small" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      )
    );
  };

  return (
    <IonMenu contentId="main" menuId="main" type="overlay" className="">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <a href="/home">
              <IonAvatar>
                <img
                  src="assets/img/vela_logo.png"
                  alt="Vera logo"
                  width="512px"
                  height="512px"
                />
              </IonAvatar>
            </a>
          </IonButtons>
          <IonTitle style={{ fontFamily: "Raleway" }}>
            Vela
            <IonNote style={{ fontFamily: "Raleway" }}>
              BDI Central Database
            </IonNote>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList id="main-list">{mainMenu}</IonList>
        {renderlistItems(DashboardItem())}
        {renderlistItems(MenuItem())}
        <IonItem
          button
          lines="none"
          detail={false}
          routerLink="/manual"
          routerDirection="none"
        >
          <IonLabel>Manual</IonLabel>
          <IonIcon slot="start" size="small" icon={book} />
        </IonItem>
        <IonItem
          button
          lines="none"
          detail={false}
          onClick={() => {
            dispatch(removeUser());
            logout();
            history.push("/login", { direction: "forward" });
          }}
        >
          <IonLabel>Logout</IonLabel>
          <IonIcon slot="start" size="small" icon={logOut} />
        </IonItem>
        {isVisible && (
          <IonItem
            button
            lines="none"
            detail={false}
            onClick={async () => {
              if (prompt) {
                setVisibleState(false);
                promptToInstall();
                const { outcome } = await prompt.userChoice;
                console.log(`User response to the install prompt: ${outcome}`);
                if (outcome === "accepted") window.location.reload();
              }
            }}
          >
            <IonLabel>Install Vela</IonLabel>
            <IonIcon slot="start" size="small" icon={download} />
          </IonItem>
        )}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
