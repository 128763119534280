import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { checkValidReset, resetPassword } from "../api/AuthAPI";
import "../theme/login.scss";

const ResetPassword: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [resultMessage, setResultMessage] = useState<string>("");
  const [messageError, setMessageError] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const history = useHistory();
  const [passwordNew, setPasswordNew] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [isValidReset, setIsValidReset] = useState<boolean>(false);
  const [notSame, setNotSame] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { token } = useParams<{ token: string; userId: string }>();

  useEffect(() => {
    checkValidReset(token)
      .then(({ data }) => {
        setIsValidReset(true);
        setUsername(data.username);
      })
      .catch(({ data }) => {
        setIsValidReset(false);
        setResultMessage(data.message);
      });
  }, [token]);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setShowLoading(true);
    resetPassword(token, passwordNew)
      .then((res) => {
        setSuccess(true);
        setResultMessage(res.data.message);
      })
      .catch((res) => {
        setSuccess(true);
        setResultMessage(res.data.message);
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <IonPage>
      {/* <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          message={"Please wait..."}
        /> */}
      <IonContent fullscreen className="ion-no-padding ion-text-center">
        <div className="outer">
          <div className="inner">
            {!isValidReset ? (
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <div className="login-logo">
                      <img
                        src="assets/img/vela_logo.png"
                        alt="Vera logo"
                        width="100px"
                        height="100px"
                      />
                      <IonText style={{ fontSize: "2rem" }}>
                        {resultMessage}
                      </IonText>
                    </div>
                  </IonCol>
                  {showLoading && <IonSpinner />}
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      expand="block"
                      onClick={() =>
                        history.push("/login", { direction: "root" })
                      }
                    >
                      Go to login
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <>
                {!success ? (
                  <form onSubmit={handleSubmit}>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="login-logo">
                            <img
                              src="assets/img/vela_logo.png"
                              alt="Vera logo"
                              width="50px"
                              height="50px"
                            />
                            <IonText style={{ fontSize: "2rem" }}>
                              Reset Password
                            </IonText>
                          </div>
                        </IonCol>
                        {showLoading && <IonSpinner />}
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonItem>
                            <IonLabel> Username</IonLabel>
                            <IonInput
                              name="username"
                              value={username}
                              readonly={true}
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonItem>
                            <IonLabel> New Password</IonLabel>
                            <IonInput
                              type="password"
                              name="passwordNew"
                              value={passwordNew}
                              clearOnEdit={false}
                              onIonChange={(e) =>
                                setPasswordNew(e.detail.value!)
                              }
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonItem>
                            <IonLabel> Confirm New Password</IonLabel>
                            <IonInput
                              type="password"
                              name="passwordConfirm"
                              value={passwordConfirm}
                              clearOnEdit={false}
                              onIonChange={(e) =>
                                setPasswordConfirm(e.detail.value!)
                              }
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <input hidden type="submit" value="Submit" />
                      <IonRow>
                        {notSame && !showLoading && (
                          <IonText color="danger">{messageError}</IonText>
                        )}
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonButton
                            expand="block"
                            onClick={(e) => {
                              if (
                                passwordNew === "" ||
                                passwordConfirm === ""
                              ) {
                                setNotSame(true);
                                setMessageError(
                                  "Please fill your new password"
                                );
                              } else if (passwordNew === passwordConfirm) {
                                handleSubmit(e);
                                setNotSame(false);
                              } else {
                                setNotSame(true);
                                setMessageError(
                                  "Your confirm password is not the same try again"
                                );
                              }
                            }}
                          >
                            {"Reset Password"}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                ) : (
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <div className="login-logo">
                          <img
                            src="assets/img/vela_logo.png"
                            alt="Vera logo"
                            width="100px"
                            height="100px"
                          />
                          <IonText style={{ fontSize: "2rem" }}>
                            {resultMessage}
                          </IonText>
                        </div>
                      </IonCol>
                      {showLoading && <IonSpinner />}
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonButton
                          expand="block"
                          onClick={() =>
                            history.push("/login", { direction: "root" })
                          }
                        >
                          Go to login
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
              </>
            )}
          </div>
        </div>
      </IonContent>
      {/* <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Log in Fail"}
          message={messageError}
          buttons={["OK"]}
        /> */}
    </IonPage>
  );
};

export default ResetPassword;
