import { IonRouterOutlet } from "@ionic/react";
import Login from "pages/Login";
import ResetPassword from "pages/Reset_Password";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const LoginRoute: React.FC = () => {
  console.log("LOGIN ROUTE");
  return (
    <IonRouterOutlet id="login">
      <Route exact path="/login" component={Login} />
      <Route path="/reset-password/:token" component={ResetPassword} />
      <Redirect exact from="/" to="/login" />
      <Route render={() => <Redirect to="/login" />} />
    </IonRouterOutlet>
  );
};

export default LoginRoute;
