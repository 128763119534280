import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItemGroup,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import {
  InputCheckbox,
  InputDate,
  InputNumber,
  InputSelection,
  InputText,
} from "../../components/FormInputType";
import DepartmentSelect from "../../components/Select/DepartmentSelect";
import EmployeeSelect from "../../components/Select/EmployeeSelect";
import MinistrySelect from "../../components/Select/MinistrySelect";
import { dataAPI } from "./api";
import { modelData } from "./model";

const Modal: React.FC<{
  buttonLabel: string;
  item: modelData;
  updateItems: Function;
  modal: boolean;
  setModal: Function;
  DropDownList: any;
  generateID: (isFiscalYear: boolean) => string;
}> = ({
  buttonLabel,
  item,
  updateItems,
  modal,
  setModal,
  DropDownList,
  generateID,
}) => {
  const [Item, setItem] = React.useState({ ...item });
  const [iserror, setIserror] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const DataService = new dataAPI();
  const [isFiscalYear, setIsFiscalYear] = React.useState<boolean>(false);

  useEffect(() => {
    if (item) {
      setItem({ ...item });
      if (label === "add") setItem({ ...item, id: generateID(isFiscalYear) });
    }
  }, [item]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setItem((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const label = buttonLabel;
  let title;

  if (label === "add") {
    title = "Add New Item";
  }
  if (label === "edit") {
    title = "Edit Item";
  }

  const submitFormAdd = (event: any) => {
    event.preventDefault();

    setShowLoading(true);
    DataService.create(Item)
      .then((item) => {
        if (Array.isArray(item.data)) {
          updateItems((prevState: any) => [...prevState, Item]);
          setShowLoading(false);
          setModal(false);
        } else {
          console.log("failure");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setIserror(true);
      });
  };

  const submitFormEdit = (event: any) => {
    event.preventDefault();

    setShowLoading(true);
    DataService.update(Item).then((item) => {
      if (Array.isArray(item.data)) {
        updateItems((prevState: Array<any>) => {
          const index = prevState.findIndex(
            (element) => element.id === Item.id
          );
          return [
            ...prevState.slice(0, index),
            { ...Item },
            ...prevState.slice(index + 1),
          ];
        });
        setShowLoading(false);
        setModal(false);
      } else {
        console.log("failure");
      }
    });
  };

  const form = (
    <IonItemGroup>
      <InputText
        label="Project ID"
        name="id"
        value={Item.id}
        onChange={onChange}
        disabled={buttonLabel === "edit" ? true : false}
      />
      {buttonLabel === "add" && (
        <InputCheckbox
          label="Fiscal Year"
          name="isFiscalYear"
          value={isFiscalYear}
          onChange={(e: any) => {
            const { value } = e.target;
            const newID = generateID(value);
            setIsFiscalYear(value);
            setItem((prevState: any) => ({
              ...prevState,
              id: newID,
            }));
          }}
        />
      )}
      <InputSelection
        label="Flagship"
        name="flagship"
        value={Item.flagship}
        option={DropDownList?.flagship}
        onChange={onChange}
      />
      <InputSelection
        label="Project Type"
        name="prj_type"
        value={Item.prj_type}
        option={DropDownList?.prj_type}
        onChange={onChange}
      />
      <InputText
        label="Project Name"
        name="prj_name"
        value={Item.prj_name}
        onChange={onChange}
      />
      <MinistrySelect
        label="Ministry"
        value={Item.ministry}
        name="ministry"
        color="primary"
        onChange={onChange}
      />
      <DepartmentSelect
        label="Department"
        name="department"
        value={Item.department}
        color="primary"
        onChange={onChange}
      />
      <InputCheckbox
        label="Funded"
        name="funded"
        value={Item.funded}
        onChange={onChange}
      />
      <InputNumber
        label="Income (Baht)"
        name="total_unit"
        value={Item.total_unit}
        onChange={onChange}
      />
      <InputSelection
        label="Status"
        name="status"
        value={Item.status}
        option={DropDownList?.status}
        onChange={onChange}
      />
      <EmployeeSelect
        name="pm"
        label="Project Manager"
        color="primary"
        value={Item.pm}
        onChange={onChange}
      />
      <EmployeeSelect
        name="pl"
        label="Project Lead"
        color="primary"
        value={Item.pl}
        onChange={onChange}
      />
      <InputNumber
        label="Total Dataset"
        name="total_dataset"
        value={Item.total_dataset}
        onChange={onChange}
      />
      <InputNumber
        label="Total Catalog"
        name="total_catalog"
        value={Item.total_catalog}
        onChange={onChange}
      />
      <InputNumber
        label="Total Model"
        name="total_model"
        value={Item.total_model}
        onChange={onChange}
      />
      <InputNumber
        label="Total Dashboard"
        name="total_dashboard"
        value={Item.total_dashboard}
        onChange={onChange}
      />
      <InputDate
        label="Start Date"
        name="start_date"
        value={Item.start_date}
        onChange={onChange}
      />
      <InputDate
        label="Finish Date"
        name="end_date"
        value={Item.end_date}
        onChange={onChange}
      />
      <InputText
        label="Epic Name"
        name="epic_name"
        value={Item.epic_name}
        onChange={onChange}
      />
      <InputText label="MOU" name="mou" value={Item.mou} onChange={onChange} />
      <InputText
        label="MOU Signee"
        name="mou_signee"
        value={Item.mou_signee}
        onChange={onChange}
      />
      <InputText
        label="Project Complexity"
        name="prj_complexity"
        value={Item.prj_complexity}
        onChange={onChange}
      />
      <InputText
        label="Project Level"
        name="prj_level"
        value={Item.prj_level}
        onChange={onChange}
      />
      <InputText
        label="Period"
        name="Period"
        value={Item.Period}
        onChange={onChange}
      />
      <InputText
        label="Data Location"
        name="prj_data_location"
        value={Item.prj_data_location}
        onChange={onChange}
      />
      <EmployeeSelect
        name="access_emp"
        label="Accessible Employee"
        color="primary"
        value={Item.access_emp}
        onChange={onChange}
        multiple
      />
      <InputSelection
        label="Retention Type"
        name="retention_type"
        value={Item.retention_type}
        option={DropDownList?.retention_type}
        onChange={onChange}
      />
      <InputSelection
        label="Retention Status"
        name="retention_status"
        value={Item.retention_status}
        option={DropDownList?.retention_status}
        onChange={onChange}
      />
      <InputText
        label="Source Control"
        name="source_control"
        value={Item.source_control}
        onChange={onChange}
      />
    </IonItemGroup>
  );

  return (
    <>
      <IonModal isOpen={modal}>
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={
                  buttonLabel === "edit" ? submitFormEdit : submitFormAdd
                }
              >
                Save
              </IonButton>
              <IonButton
                expand="block"
                onClick={() => {
                  // setUpdate(Math.random());
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">{form}</IonContent>
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Error"}
          message={errorMessage + "<br>Please try again"}
          buttons={["OK"]}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          message={"Saving ..."}
          duration={0}
        />
      </IonModal>
    </>
  );
};

export default Modal;
