import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
//import * as serviceWorker from './serviceWorker';

dayjs.extend(duration);
dayjs.extend(relativeTime);

ReactDOM.render(<App />, document.getElementById("root"));
//serviceWorker.register();
