import { IonButton, IonIcon } from "@ionic/react";
import { download } from "ionicons/icons";
import React from "react";
import { useCSVDownloader, usePapaParse } from "react-papaparse";
import { selectCurrentEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import useKPI from "../../../store/KPIProvider";

const GetTemplate: React.FC = () => {
  const empList = useAppSelector(selectCurrentEmpList);

  const {
    state: { period },
  } = useKPI();

  const { CSVDownloader, Type } = useCSVDownloader();
  const { jsonToCSV } = usePapaParse();

  const filename = "KPI360-" + period?.name + ".csv";

  return (
    <>
      <CSVDownloader
        type={Type.Link}
        filename={filename}
        bom={true}
        config={{
          delimiter: ",",
        }}
        data={() => {
          //   const header = jsonToCSV({
          //     fields: ["Column 1", "Column 2"],
          //     data: [],
          //   });
          const data = jsonToCSV(empList, {
            // header: false,
            columns: [
              "username",
              "bdi_emp_id",
              "firstname",
              "lastname",
              "KPI_score",
              "360_score",
              "PIP_score",
              "PMS_score",
            ],
          });
          return data;
        }}
        complete={(data: any) => {
          console.log(data);
        }}
      >
        <IonButton aria-label="Save">
          <IonIcon slot="start" icon={download} />
          Template
        </IonButton>
      </CSVDownloader>
    </>
  );
};

export default GetTemplate;
