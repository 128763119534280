import { createTheme, ThemeProvider } from "@material-ui/core";
import React from "react";

const MUITheme: React.FC = ({ children }) => {
  const theme = createTheme({
    typography: {
      fontFamily: "Kanit",
    },
    palette: {
      primary: {
        main: "#006838",
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: "10px",
          },
          '*::-webkit-scrollbar-track': {
            background: "#f1f1f1",
          },
          '*::-webkit-scrollbar-thumb': {
            background: "#888",
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: "#555",
          },
        }
      },  
      MuiFormControl: {
        marginNormal: {
          marginTop: "0px",
          marginBottom: "0px"
        }
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MUITheme;