import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import NormalCurve from "components/NormalDistKPI";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { selectDDLUserGroup } from "redux/dropdown/reducer";
import { useAppSelector } from "redux/hooks";
import { roundTwoDigit } from "utils";
import { Footer } from "../../../components/Item/ItemKPI";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import ExportModal from "./export_modal";
import List from "./list";
import { FinalScoreAPI } from "api/KPIAPI";
import { IScoreFinalData } from "models/kpi.model";
import KPICiteriaModal from "components/Modal/KPICiteriaModal";

const Page: React.FC<{ title: string; path: string }> = ({ title, path }) => {
  const [Items, setItems] = useState<Array<IScoreFinalData>>([]);
  const [searchEmp, setSearchEmp] = useState<string>("");
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [Textloading, setTextLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const userGroupOption = useAppSelector(selectDDLUserGroup);
  const [userGroup, setUserGroup] = useState<string | null>(null);
  const [avg, setAvg] = useState<number>(0);
  const [stddev, setStddev] = useState<number>(0);
  const history = useHistory();

  const {
    state: { period },
  } = useKPI();

  useEffect(() => {
    setTextLoading(true);
    if (period) {
      FinalScoreAPI.read({ period: period.name, group: userGroup })
        .then((res) => {
          const { avg, stddev, score } = res.data;
          setItems(score);
          setAvg(avg);
          setStddev(stddev);
        })
        .then(() => setTextLoading(false));
    } else history.push(path);
  }, [period, userGroup, history, path]);

  const filterSearch = (list: Array<IScoreFinalData>, searchEmp: string) =>
    list.filter((emp) => emp.emp_id.indexOf(searchEmp.toLowerCase()) > -1);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/kpi/final_kpi" />
          </IonButtons>
          <IonTitle>{`${title} (${period?.name})`}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeSm="6">
                <IonItem lines="none">
                  <IonSearchbar
                    placeholder="Search Employee"
                    value={searchEmp}
                    onIonChange={(e) => {
                      setSearchEmp(e.detail.value!);
                    }}
                  ></IonSearchbar>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeSm="6">
                <IonItem>
                  <IonLabel>Employee Group</IonLabel>
                  <IonSelect
                    value={userGroup}
                    onIonChange={(e) => setUserGroup(e.detail.value)}
                    interface="popover"
                  >
                    {userGroupOption.map(({ label, value }) => (
                      <IonSelectOption value={value} key={value}>
                        {label}
                      </IonSelectOption>
                    ))}
                    <IonSelectOption value={null} key="all">
                      All
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {Textloading ? (
          <Spinning />
        ) : (
          <div>
            {Boolean(Items.length) ? (
              <>
                <h5 className="ion-padding">
                  {`Director Score Distribution (\u03BC = ${roundTwoDigit(
                    avg
                  )}, \u03C3 = ${roundTwoDigit(stddev)})`}
                </h5>
                <div
                  className="ion-padding ion-margin"
                  style={{ height: "300px" }}
                >
                  <NormalCurve
                    stddev={stddev}
                    avg={avg}
                    dataList={Items}
                    scoreKey="director_acm"
                  />
                </div>
                <List
                  data={filterSearch(Items, searchEmp)}
                  stddev={stddev}
                  avg={avg}
                  dataList={Items}
                />
              </>
            ) : (
              <h1 className="ion-text-center">No Record</h1>
            )}
          </div>
        )}
      </IonContent>

      <IonToast
        isOpen={saveSuccess}
        onDidDismiss={() => setSaveSuccess(false)}
        message="Your data was saved."
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Saving..."}
      />
      {Boolean(Items.length) && (
        <Footer
          editable={period?.edit_empKPI}
          buttonList={[<ExportModal data={Items} />, <KPICiteriaModal />]}
        />
      )}
    </IonPage>
  );
};

export default Page;
