import {
  IonButton,
  IonButtons,
  IonFooter,
  IonHeader,
  IonIcon,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
//import TablePaginationActions from "./TablePaginationActions";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { add, trash } from "ionicons/icons";
import { matchSorter } from "match-sorter";
import React, { useEffect } from "react";
//import Checkbox from "@material-ui/core/Checkbox";
//import TableToolbar from "./TableToolbar";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import "./DataTable.scss";
import TablePaginationActions from "./TablePaginationAction";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {/* <Checkbox size="small" disableRipple ref={resolvedRef} {...rest} /> */}
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

interface Filter {
  column: {
    filterValue: any;
    setFilter: any;
    preFilteredRows: any;
    id: any;
  };
}

const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: Filter) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <FormControl
      style={{
        margin: 0,
        width: "100%",
        maxWidth: 400,
      }}
    >
      <Select
        id="demo-mutiple-checkbox"
        multiple
        value={filterValue ? filterValue : []}
        placeholder={"Filter"}
        onChange={(e) => {
          setFilter((e.target.value as string[]) || undefined);
        }}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(", ")}
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
              width: 250,
            },
          },
        }}
      >
        {options.map((option: any, i) => (
          <MenuItem id="menuItem" key={i} value={option}>
            {/* <input type="checkbox" checked={options.indexOf(option) > -1} /> */}
            {/* <Checkbox checked={options.indexOf(option) > -1} /> */}
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  return matchSorter(rows, filterValue, {
    keys: [(row: any) => row.values[id]],
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export const DataTable = ({
  columns,
  data,
  setSelectedRows,
  addActive,
  addAction,
  deleteActive,
  deleteAction,
}: any) => {
  const [skipPageReset, setSkipPageReset] = React.useState(true);

  useEffect(() => {
    // After the table has updated, always remove the flag
    setSkipPageReset(true);
  }, [setSkipPageReset]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: SelectColumnFilter,
      filter: "includesSome",
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      autoResetPage: !skipPageReset,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      filterTypes,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    const selected = selectedFlatRows.map((row: any) => row.original);
    setSelectedRows(selected);
  }, [selectedFlatRows, setSelectedRows]);

  // Render the UI for your table
  return (
    <>
      <IonHeader>
        {Object.keys(selectedRowIds).length ? (
          <IonToolbar color="secondary">
            <IonButtons slot="start">
              <IonButton disabled={!addActive} onClick={addAction}>
                <IonIcon slot="icon-only" icon={add} />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {"Selected : " + Object.keys(selectedRowIds).length}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton disabled={!deleteActive} onClick={deleteAction}>
                <IonIcon slot="icon-only" icon={trash} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton disabled={!addActive} onClick={addAction}>
                <IonIcon slot="icon-only" icon={add} />
              </IonButton>
            </IonButtons>
            <IonSearchbar
              type="text"
              value={globalFilter || ""}
              onIonChange={(e) => setGlobalFilter(e.detail.value)}
            />
          </IonToolbar>
        )}
      </IonHeader>
      <TableContainer>
        <MaUTable
          className="ion-no-margin"
          size="small"
          aria-label="a dense table"
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={i}>
                <TableRow {...headerGroup.getHeaderGroupProps()} key="header">
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...(column.id === "selection"
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                      style={{ borderBottom: "0px" }}
                    >
                      {column.render("Header")}
                      {column.id !== "selection" ? (
                        <TableSortLabel
                          style={{ borderBottom: "0px" }}
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow {...headerGroup.getHeaderGroupProps()} key="filter">
                  {headerGroup.headers.map((column, i) => (
                    <TableCell key={i}>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, i: any) => {
              prepareRow(row);
              return (
                <TableRow hover {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell defaultValue="" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </TableContainer>
      <IonFooter>
        <IonToolbar>
          <TablePagination
            component="div"
            rowsPerPageOptions={[
              10,
              20,
              30,
              40,
              50,
              { label: "All", value: data.length },
            ]}
            count={data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
            }}
            onChangePage={(_e, newPage) => {
              gotoPage(newPage);
            }}
            onChangeRowsPerPage={(e) => {
              setPageSize(Number(e.target.value));
            }}
            ActionsComponent={TablePaginationActions}
          />
        </IonToolbar>
      </IonFooter>
    </>
  );
};
