import { IonPage } from "@ionic/react";
import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { KPIProvider } from "store/KPIProvider";
import Dashboard from "./dashboard";

const KPIDashboard: React.FC = () => {
  const { url } = useRouteMatch();
  return (
    <KPIProvider>
      <IonPage>
        <Route component={Dashboard} path={`${url}`} exact={true} />
      </IonPage>
    </KPIProvider>
  );
};

export default KPIDashboard;
