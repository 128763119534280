import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { refreshCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import { Footer } from "../../../components/Item/ItemKPI";
import EmployeeSelect from "../../../components/Select/EmployeeSelect";
import Spinning from "../../../components/Spinning";
import { IPeriod } from "../../../models/kpi.model";
import useKPI from "../../../store/KPIProvider";
import { ReportKPIGroupAPI } from "api/KPIAPI";
import ExportModal from "./export_modal";

interface KPIGroupData {
  emp_id: string;
  comment: string;
  depa_indv_group: string;
  period: string;
  topic_name: string;
  description: string;
  weight: number;
  selected: boolean;
  final_acm: number;
  type_acm: string;
  total_storypoint: number;
  prj_list: Array<any>;
  self_acm: number;
  pl_acm: boolean;
  spm_acm: number;
}

const Page: React.FC<{ title: string; path: string }> = ({ title, path }) => {
  const { user } = useAppSelector(selectAuthen);
  const {
    state: { period, emp_id },
    dispatch,
  } = useKPI();

  const [Items, setItems] = useState<Array<any>>([]);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [Textloading, setTextLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState(0);

  const [periodDetail, setPeriodDetail] = useState<IPeriod>();
  const [totalStorypoint, setTotalStorypoint] = useState<number>(0);
  const [updateDate, setUpdateDate] = useState<Date | null>(null);
  const history = useHistory();

  useEffect(() => {
    setTextLoading(true);
    if (emp_id && period) {
      ReportKPIGroupAPI.read({ emp_id: emp_id, period: period.name })
        .then((res) => {
          const { data, period, updateDate } = res.data;
          const Cal = calWeight([...data]);
          setItems([...Cal]);
          setPeriodDetail(period);
          setUpdateDate(new Date(updateDate));
        })
        .then(() => setTextLoading(false));
    } else history.replace(path);
  }, [emp_id, period, update, history, path]);

  const calWeight = (Data: Array<KPIGroupData>) => {
    const KPIGroupDataList = Data;
    const KPIGroupChecked = KPIGroupDataList.filter(
      (data: KPIGroupData) => data.selected
    );

    let TotalStorypoint = 0;

    TotalStorypoint = KPIGroupChecked.reduce(
      (a, b) => a + b.total_storypoint,
      0
    );

    setTotalStorypoint(TotalStorypoint);

    KPIGroupChecked.map(
      (data) => (data.weight = (data.total_storypoint * 100) / TotalStorypoint)
    );

    return Data;
  };

  const setCheck = (e: any, detail: any) => {
    let item = Items.find(
      (score) => score.depa_indv_group === detail.depa_indv_group
    );

    if (item) {
      item.selected = e.detail.checked;
      item.weight = 0;
    }

    //calWeight(Items);
    setItems([...calWeight(Items)]);
  };

  const EditComment = (e: any, detail: any) => {
    const { name, value } = e.target;
    let item = Items.find(
      (score) => score.depa_indv_group === detail.depa_indv_group
    );

    if (item) {
      item[name] = value;
    }
    setItems([...Items]);
  };

  const EditSelectScore = (type: any, detail: any) => {
    let item = Items.find(
      (score) => score.depa_indv_group === detail.depa_indv_group
    );

    if (item) {
      item["type_acm"] = type;
    }
    setItems([...Items]);
  };

  const updateScore = (event: any) => {
    event.preventDefault();
    setShowLoading(true);
    const result = Items.filter((item) => item.depa_indv_group !== "None");
    ReportKPIGroupAPI.update(result, { emp_id: emp_id, period: period }).then(
      (item) => {
        setItems([]);
        setShowLoading(false);
        setUpdate(update + 1);
        setSaveSuccess(true);
      }
    );
  };

  const renderKPIList = Items.map((p, i) => (
    <KPIGroupCard
      key={i}
      p={p}
      setCheck={setCheck}
      EditComment={EditComment}
      EditSelectScore={EditSelectScore}
      editable={user && emp_id === user.username && periodDetail?.edit_summary}
    ></KPIGroupCard>
  ));

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/report-kpi-group" />
          </IonButtons>
          <IonTitle>{`${title} (${period?.name})`}</IonTitle>
        </IonToolbar>
        {user &&
          Boolean(user.role.includes("admin") || user.role.includes("spm")) && (
            <IonToolbar>
              <EmployeeSelect
                label="Employee"
                value={emp_id}
                name="Employee"
                onChange={(e: any) => {
                  dispatch({ type: "SET_EMP", emp_id: e.target.value });
                }}
              />
            </IonToolbar>
          )}
      </IonHeader>
      <IonContent>
        {Textloading ? (
          <Spinning />
        ) : Boolean(Items.length) ? (
          renderKPIList
        ) : (
          <h1 className="ion-text-center">No Record</h1>
        )}
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Saving..."}
        />
        <IonToast
          isOpen={saveSuccess}
          onDidDismiss={() => setSaveSuccess(false)}
          message="Your data was saved."
          duration={2000}
        />
      </IonContent>
      {Boolean(Items.length) && (
        <Footer
          totalStorypoint={totalStorypoint}
          updateScore={updateScore}
          updateDate={updateDate}
          editable={
            periodDetail?.edit_summary && user && emp_id === user.username
          }
          buttonList={[<ExportModal emp_id={emp_id ? emp_id : ""} />]}
        />
      )}
    </IonPage>
  );
};

const KPIGroupCard: React.FC<{
  p: KPIGroupData;
  setCheck: Function;
  EditComment: Function;
  EditSelectScore: Function;
  editable: any;
}> = ({ p, setCheck, EditComment, EditSelectScore, editable }) => {
  const [Topic, setTopic] = useState(
    p.topic_name ? p.topic_name : p.depa_indv_group
  );
  const [isTopicEdit, setIsTopicEdit] = useState(false);
  const [isDescEdit, setIsDescEdit] = useState(false);
  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [isRestore, setIsRestore] = useState(false);
  const [selectScore, setSelectScore] = useState<string>(p.type_acm);

  const checkEditable = (p: any) => {
    if (p.depa_indv_group === "None") return true;
    else return false;
  };

  const checkReadOnly = (p: any) => {
    if (!editable) return true;
    else return false;
  };

  return (
    <IonCard disabled={checkEditable(p)}>
      <IonGrid className="ion-no-margin ion-no-padding">
        <IonRow className="ion-no-margin ion-no-padding">
          <IonCol
            className="ion-no-margin ion-no-padding"
            size="12"
            size-sm="10"
          >
            <IonItem lines="inset" className="ion-align-items-center">
              <IonCheckbox
                slot="start"
                color="secondary"
                checked={p.selected}
                onIonChange={(e) => setCheck(e, p)}
                disabled={checkEditable(p) || checkReadOnly(p)}
                style={{ margin: "0px 20px 0px 0px" }}
              />
              <IonLabel
                className="ion-no-margin"
                color={isTopicEdit ? "success" : ""}
                position="stacked"
              >
                {p.topic_name ? "(" + p.depa_indv_group + ")" : ""}
              </IonLabel>
              <IonInput
                style={{ fontWeight: "bold" }}
                name="topic_name"
                color={isTopicEdit ? "success" : ""}
                value={Topic}
                type={"text"}
                maxlength={100}
                disabled={checkEditable(p)}
                readonly={checkReadOnly(p)}
                onIonChange={(e: any) => {
                  if (isRestore) setIsRestore(false);
                  else {
                    setTopic(e.detail.value);
                    EditComment(e, p);
                  }

                  setIsTopicEdit(true);
                }}
              />{" "}
              <IonButtons slot="end">
                <IonButton
                  shape="round"
                  disabled={checkEditable(p) || checkReadOnly(p)}
                  onClick={(e) => {
                    e.preventDefault();
                    const editTopicData = {
                      target: { name: "topic_name", value: "" },
                    };
                    setTopic(p.depa_indv_group);
                    EditComment(editTopicData, p);
                    setIsRestore(true);
                  }}
                >
                  <IonIcon icon={refreshCircle} />
                </IonButton>
              </IonButtons>
            </IonItem>
            <IonChip color="success">
              {p.total_storypoint + " storypoint"}
            </IonChip>
            <IonChip color="quaternary">
              {p.weight.toFixed(2)}
              {" %"}
            </IonChip>
            <IonItem lines="inset">
              <IonLabel color={isDescEdit ? "success" : ""} position="stacked">
                Description
              </IonLabel>
              <IonTextarea
                name="description"
                autoGrow
                color={isDescEdit ? "success" : ""}
                value={p.description}
                placeholder="Edit Description"
                disabled={checkEditable(p)}
                readonly={checkReadOnly(p)}
                onIonChange={(e: any) => {
                  EditComment(e, p);
                  setIsDescEdit(true);
                }}
              ></IonTextarea>
            </IonItem>
            <IonItem lines="inset">
              <IonLabel
                color={isCommentEdit ? "success" : ""}
                position="stacked"
              >
                Comment
              </IonLabel>
              <IonTextarea
                name="comment"
                autoGrow
                color={isCommentEdit ? "success" : ""}
                value={p.comment}
                placeholder="Edit Comment"
                disabled={checkEditable(p)}
                readonly={checkReadOnly(p)}
                onIonChange={(e: any) => {
                  EditComment(e, p);
                  setIsCommentEdit(true);
                }}
              ></IonTextarea>
            </IonItem>
            <IonItem className="text-wrap" lines="none">
              <IonList>
                <IonLabel position="fixed" style={{ fontWeight: "bold" }}>
                  Project
                </IonLabel>
                {p.prj_list.map((prj, i) => (
                  <IonChip key={i} color="secondary">
                    {prj.prj_id}
                  </IonChip>
                ))}
              </IonList>
            </IonItem>
            <IonItem>
              <IonLabel color="dark" slot="start" position="fixed">
                <IonText style={{ fontWeight: "bold" }}>Save score</IonText>
              </IonLabel>
              <IonSelect
                value={selectScore}
                okText="Okay"
                cancelText="Dismiss"
                interface="popover"
                disabled={checkEditable(p) || checkReadOnly(p)}
                onIonChange={(e) => {
                  setSelectScore(e.detail.value);
                  EditSelectScore(e.detail.value, p);
                }}
              >
                <IonSelectOption value="self_acm">Self Score</IonSelectOption>
                <IonSelectOption value="director_acm">
                  Director Score
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol
            className="ion-no-margin ion-no-padding"
            size="12"
            size-sm="2"
          >
            <ScoreForm p={p} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

const ScoreForm: React.FC<{ p: any }> = ({ p }) => {
  return (
    <IonGrid className="ion-margin-start">
      <IonRow className="ion-no-margin ion-no-padding">
        <IonCol className="ion-no-margin ion-no-padding" size-sm="12" size="3">
          <IonLabel color="dark" position="floating">
            <p>Self Score</p>
          </IonLabel>
          <IonInput
            type="number"
            color="dark"
            name="self_acm"
            id="self_acm"
            max="5"
            min="0"
            step="0.5"
            value={Math.round(p.self_acm * 100) / 100}
            readonly
          />
        </IonCol>
        <IonCol className="ion-no-margin ion-no-padding" size-sm="12" size="3">
          <IonLabel color="dark" position="floating">
            <p>PM Score</p>
          </IonLabel>
          <IonInput
            color="dark"
            name="pl_acm"
            id="pl_acm"
            max="5"
            min="0"
            step="0.5"
            readonly
            value={p.pl_acm ? "*" : ""}
          />
        </IonCol>
        <IonCol className="ion-no-margin ion-no-padding" size-sm="12" size="3">
          <IonLabel color="dark" position="floating">
            <p>Director Score</p>
          </IonLabel>
          <IonInput
            type="number"
            color="dark"
            name="spm_acm"
            id="spm_acm"
            max="5"
            min="0"
            step="0.5"
            readonly
            value={Math.round(p.director_acm * 100) / 100}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Page;
