import axios from "axios";

const API_URL = '/api/project';

export class dataAPI {
    Axios_API = axios.create({ baseURL: API_URL })

    public create(data: any) {
        return this.Axios_API.post('', data)
    }

    public read() {
        return this.Axios_API.get('')
    }

    public getDropdown() {
        return this.Axios_API.get('ddl')
    }

    public update(data: any) {
        return this.Axios_API.put('', data)
    }

    public delete(data: any) {
        return this.Axios_API.delete('', { data: data })
    }
}