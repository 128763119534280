import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonText,
  IonChip,
  IonAlert,
} from "@ionic/react";
import React, { useEffect, useState, useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { selectAuthen } from "redux/authen/selector";
import { selectEmpList } from "redux/employee/selector";

import { add, trash } from "ionicons/icons";
import Modal from "./modal";
import { dataAPI } from "./api";
import ConfirmModal from "./confirm-modal";
import { InitModelData, modelData } from "./model";
import { EmployeeAPI } from "api/KPIAPI";
import { StorypointChip, EmpChip } from "components/Chip";
import { IScoreFinalData } from "models/kpi.model";
import { IUserGroup } from "models/employee.model";
import dayjs from "dayjs";
import { formatSprintName, roundTwoDigit } from "utils";

const Page: React.FC = () => {
  const [buttonLabel, setButtonLabel] = useState<string>("");
  const [searchEmp, setSearchEmp] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<null | {
    isEdit: boolean;
    defaultValue?: modelData;
    editId?: modelData["id"];
  }>(null);
  const [refetch, setRefetch] = useState(0);
  const [Items, setItems] = React.useState<Array<modelData>>([]);
  const [DropDownList, setDropDownList] = React.useState<any>();
  const [typeItem, setTypeItem] = React.useState<"rq" | "ap" | null>(null);
  const [showConfirmModal, setShowConfirmModal] = React.useState<null | {
    status: "approve" | "reject" | "cancel";
    id: number;
  }>(null);
  const empList = useAppSelector(selectEmpList);
  const empListobj = useMemo(() => {
    return empList.reduce((acc, emp) => {
      acc[emp.emp_id] = emp;
      return acc;
    }, {} as any);
  }, [empList]);
  const processedItems = useMemo(() => {
    return Items.map((item) => ({
      ...item,
      firstname: empListobj[item.employee]?.firstname,
      lastname: empListobj[item.employee]?.lastname,
    }));
  }, [Items, empListobj]);

  const filteredItems = useMemo(() => {
    if (!searchEmp) return processedItems;
    return processedItems.filter((item) =>
      `${item.firstname} ${item.lastname}`
        .toLowerCase()
        .includes(searchEmp.toLowerCase())
    );
  }, [processedItems, searchEmp]);

  const { user } = useAppSelector(selectAuthen);
  console.log("🚀 ~ file: page.tsx:90 ~ user:", user);

  const handleSetModal = () => {
    setButtonLabel("add");
    setModal({ isEdit: false, defaultValue: InitModelData });
  };
  const handleClickEdit = (item: modelData, editId: number) => {
    setButtonLabel("edit");
    setModal({ isEdit: true, defaultValue: item, editId });
  };
  const handleApproveOrReject = (id, status) => {
    setShowConfirmModal({ status, id });
    console.debug("🚀 | Approve");
  };
  const statusName = [
    {
      type: "rq",
      label: "ขออนุมัติ",
    },
    {
      type: "ap",
      label: "ที่ต้องอนุมัติ",
    },
  ];

  const triggerRefetch = () => {
    setRefetch((prev) => prev + 1);
  };

  useEffect(() => {
    const DataService = new dataAPI();

    const getItems = () => {
      setShowLoading(true);
      DataService.read(typeItem).then((items) => {
        setItems(items.data);
        setShowLoading(false);
        // DataService.getDropdown().then((items) => {
        //   setDropDownList(items.data);
        //   setShowLoading(false);
        // });
      });
    };

    if (user) {
      const resource = user.resource.find(
        (element) => element.name === "project"
      );
      if (resource) {
        // setAction([...resource.action]);
      }
    }

    console.log("FETCH");
    getItems();
    console.log("PROJECT MOUNT");
    return () => {
      console.log("PROJECT UNMOUNT");
    };
  }, [user, typeItem, refetch]);

  const renderButton = (item: modelData, editId: number) => {
    if (item.status === "approved") {
      return null;
    }
    if (
      item.type === "rq" &&
      item.status !== "canceled" &&
      item.display_button
    ) {
      return (
        <IonButtons slot="end">
          <IonButton
            color="primary"
            fill="solid"
            slot="end"
            onClick={(e) => handleClickEdit(item, editId)}
          >
            แก้ไข
          </IonButton>
          <IonButton
            color="danger"
            fill="solid"
            onClick={(e) => handleApproveOrReject(editId, "cancel")}
          >
            ยกเลิก
          </IonButton>
        </IonButtons>
      );
    } else if (
      item.type === "ap" &&
      item.status === "waiting approve" &&
      item.display_button
    ) {
      return (
        <IonButtons slot="end">
          <IonButton
            color="success"
            fill="solid"
            onClick={(e) => handleApproveOrReject(editId, "approve")}
          >
            อนุมัติ
          </IonButton>
          <IonButton
            color="danger"
            fill="solid"
            onClick={(e) => handleApproveOrReject(editId, "reject")}
          >
            ไม่อนุมัติ
          </IonButton>
        </IonButtons>
      );
    }
  };
  const renderStatus = (status: modelData["status"]) => {
    const colors = {
      "waiting approve": "warning",
      approved: "success",
      rejected: "danger",
      canceled: "medium",
    };
    return (
      <IonChip color={colors[status]} className="ion-margin-end">
        {`${status}`}
      </IonChip>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Staff Borrow</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Loading ..."}
        duration={0}
      />
      <IonContent>
        {!showLoading && (
          <div>
            <IonToolbar>
              <IonGrid>
                <IonRow>
                  <IonCol size="auto">
                    <IonItem lines="none">
                      <IonButtons slot="start">
                        <IonButton onClick={handleSetModal}>
                          <IonIcon slot="icon-only" icon={add} />
                        </IonButton>
                      </IonButtons>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem lines="none">
                      <IonSearchbar
                        placeholder="Search Employee"
                        value={searchEmp}
                        onIonChange={(e) => {
                          setSearchEmp(e.detail.value!);
                        }}
                      ></IonSearchbar>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel>Type</IonLabel>
                      <IonSelect
                        value={typeItem}
                        onIonChange={(e) => setTypeItem(e.target.value)}
                        interface="popover"
                      >
                        {statusName.map((value) => (
                          <IonSelectOption value={value.type} key={value.type}>
                            {value.label}
                          </IonSelectOption>
                        ))}
                        <IonSelectOption value={null} key="All">
                          All
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonToolbar>
          </div>
        )}
        {/* show items */}
        {/* {processedItems.map(item => <div>{`${item.employee} (${item.firstname})`}</div>)} */}
        {filteredItems.map((item) => (
          <IonCard>
            <IonItem
              color="light"
              lines="full"
              className="ion-align-items-center"
            >
              <IonButtons slot="start">
                <EmpChip value={item.employee} />
              </IonButtons>
              <IonText style={{ fontWeight: "bold" }}>
                {`${item.firstname ?? ""} ${item.lastname ?? ""}`}
              </IonText>
              {renderStatus(item.status)}
              {renderButton(item, item.id)}
            </IonItem>
            <IonItem lines="inset">
              <IonGrid>
                <IonRow>
                  <IonCol size-sm="4" size="8">
                    <IonText>
                      {"Sprint Name : "}
                    </IonText>
                    <IonChip color="secondary">{item.sprint_name}</IonChip>
                  </IonCol>
                  <IonCol size-sm="2" size="8">
                    <IonText>{"Project : "}</IonText>
                    <IonChip color="secondary">{item.jira_project}</IonChip>
                  </IonCol>
                  <IonCol size-sm="2" size="8">
                    <IonChip color="success" className="ion-margin-end">
                      {`${roundTwoDigit(item.sp_amt)} storypoint`}
                    </IonChip>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
            {item.status == "rejected" &&(
            <IonItem lines="inset">
              <IonGrid>
                <IonRow>
                  <IonCol size-sm="6" size="6">
                    <IonText>
                      {"Reason : "}
                    </IonText>
                    <IonText>
                    {item.reason}
                  </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>)
            }
            {item.type == "ap" &&(
            <IonItem 
              lines="inset" 
              color="light"
              className="ion-align-items-center">
              <IonGrid>
                <IonRow>
                  <IonCol size-sm="6" size="6">
                    <IonText>
                      {"Requested by : "}
                    </IonText>
                      <EmpChip value={item.requesting_pm} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>)
            }
          </IonCard>
        ))}
        <Modal
          buttonLabel={buttonLabel}
          modal={Boolean(modal)} // Boolean(null) = false, Boolean(object) = true
          setModal={setModal}
          DropDownList={DropDownList}
          isEdit={modal?.isEdit}
          defaultValue={modal?.defaultValue}
          editId={modal?.editId}
          triggerRefetch={triggerRefetch}
        />
        <IonAlert
          isOpen={
            Boolean(showConfirmModal) && showConfirmModal?.status === "approve"
          }
          cssClass="my-custom-class"
          header={"Confirm"}
          message={"Are you sure?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                setShowConfirmModal(null);
              },
            },
            {
              text: "Okay",
              handler: async () => {
                try {
                  const DataService = new dataAPI();
                  await DataService.updateStatus(
                    showConfirmModal?.id,
                    showConfirmModal!.status,
                    { reason: "" }
                  );
                  setShowConfirmModal(null);
                  triggerRefetch();
                } catch (err) {
                  // TODO: show error dialog
                  console.error(err);
                  setShowConfirmModal(null);
                }
              },
            },
          ]}
        />
        <IonAlert
          isOpen={
            Boolean(showConfirmModal) && showConfirmModal?.status === "reject"
          }
          cssClass="my-custom-class"
          header={"Reject"}
          message={"Please Reason to Reject"}
          inputs={[
            {
              type: "textarea",
              placeholder: "Reason to Reject",
              name: "reason",
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                setShowConfirmModal(null);
              },
            },
            {
              text: "Okay",
              handler: async (data) => {
                try {
                  const DataService = new dataAPI();
                  await DataService.updateStatus(
                    showConfirmModal?.id,
                    showConfirmModal!.status,
                    { reason: data.reason }
                  );
                  setShowConfirmModal(null);
                  triggerRefetch();
                } catch (err) {
                  // TODO: show error dialog
                  console.error(err);
                  setShowConfirmModal(null);
                }
              },
            },
          ]}
        />
        <IonAlert
          isOpen={
            Boolean(showConfirmModal) && showConfirmModal?.status === "cancel"
          }
          cssClass="my-custom-class"
          header={"Confirm"}
          message={"Are you sure?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                setShowConfirmModal(null);
              },
            },
            {
              text: "Okay",
              handler: async () => {
                try {
                  const DataService = new dataAPI();
                  await DataService.updateStatus(
                    showConfirmModal?.id,
                    showConfirmModal!.status,
                    { reason: "" }
                  );
                  setShowConfirmModal(null);
                  triggerRefetch();
                } catch (err) {
                  // TODO: show error dialog
                  console.error(err);
                  setShowConfirmModal(null);
                }
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};
export default Page;
