import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "../../api/APIUtils";
import React, { useEffect, useState } from "react";

interface department {
  data: { name: string; value: string };
  checked: boolean;
}

const DepartmentSelect: React.FC<{
  label: string;
  value: any;
  name: string;
  color?: string;
  position?: string;
  multiple?: boolean;
  onChange: Function;
}> = ({ label, value, onChange, name, color, position, multiple }: any) => {
  const [DepartmentList, setDepartmentList] = useState<Array<department>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [TextLoading, setTextLoading] = useState<boolean>(false);
  //const [selectEmp, setSelectEmp] = useState<Array<dep_checkBox>>([]);
  const [modal, setModal] = useState<boolean>(false);

  useEffect(() => {
    const getDepartment = () =>
    axios({
      method: "get",
      url: "/api/department",
      params: { search: searchText },
    });
    
    if (searchText) {
      setTextLoading(true);
      getDepartment().then((res) => {
        setDepartmentList(() => {
          const list = [...res.data].map((element) => ({
            data: { name: element.name, value: element.name },
            checked: value && value.includes(element.name) ? true : false,
          }));

          return [
            ...list,
            {
              checked: true,
              data: { name: 'Add "' + searchText + '"', value: searchText },
            },
          ];
        });
        setTextLoading(false);
      });
    } else setDepartmentList([]);
  }, [value, searchText]);

  return (
    <>
      <IonItem
        button
        detail={false}
        lines="full"
        onClick={() => {
          setModal(true);
        }}
      >
        <IonLabel position={multiple ? "fixed" : position} color={color}>
          {label}
        </IonLabel>
        {Array.isArray(value) ? (value as string[]).join(", ") : value}
      </IonItem>
      {/* <IonInput readonly type="text" name={name} id={name} value={value} /> */}

      <IonModal isOpen={modal} >
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonTitle>Department List</IonTitle>
            <IonButtons slot="end">
              {multiple && (
                <IonButton
                  expand="block"
                  onClick={() => {
                    const selected = DepartmentList.filter(
                      (dep) => dep.checked
                    ).map((dep) => dep.data.name);
                    const event = { target: { name: name, value: selected } };
                    onChange(event);
                    setModal(false);
                  }}
                >
                  Submit
                </IonButton>
              )}
              <IonButton
                expand="block"
                onClick={() => {
                  setSearchText("");
                  if (multiple)
                    setDepartmentList((prevState) =>
                      prevState.map((element) => ({
                        ...element,
                        checked:
                          value && value.includes(element.data.name)
                            ? true
                            : false,
                      }))
                    );
                  else setDepartmentList([])
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar className="ion-align-items-center">
            <IonSearchbar
              onIonChange={(e) => setSearchText(e.detail.value!)}
            ></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {TextLoading ? (
            <IonRow className="ion-justify-content-center">
              <IonSpinner name="dots" />
            </IonRow>
          ) : (
            <>
              {searchText ? (
                <IonList style={{ background: "none" }}>
                  {DepartmentList.map((dep, i) => (
                    <DepartmentItem
                      key={i}
                      item={dep.data}
                      display={
                        dep.data.name
                          .toLowerCase()
                          .indexOf(searchText.toLowerCase()) > -1
                          ? true
                          : false
                      }
                      checked={dep.checked}
                      onClick={() => {
                        if (multiple) {
                          setDepartmentList((prevState) =>
                            prevState.map((element) =>
                              element.data.name === dep.data.name
                                ? {
                                    ...element,
                                    checked: !element.checked,
                                  }
                                : element
                            )
                          );
                        } else {
                          setSearchText("");
                          const event = {
                            target: { name: name, value: dep.data.value },
                          };
                          onChange(event);
                          setModal(false);
                        }
                      }}
                      multiple={multiple}
                    />
                  ))}
                </IonList>
              ) : (
                <p className="ion-text-center">Search Department</p>
              )}
            </>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

const DepartmentItem: React.FC<{
  item: any;
  display: boolean;
  onClick: Function;
  multiple: boolean;
  checked: boolean;
}> = ({ item, display, onClick, multiple, checked }) => {
  const [check, setCheck] = useState<boolean | undefined>(checked);
  return (
    <IonItem
      button
      onClick={() => {
        setCheck(!check);
        onClick();
      }}
      style={{ display: display ? "block" : "none" }}
    >
      {multiple && <IonCheckbox checked={check} slot="start" color="primary" />}
      {/* <IonAvatar slot="start">
          <img src={avatar} alt="avatar" width="250px" height="250px" />
        </IonAvatar> */}
      <IonLabel>{item.name}</IonLabel>
    </IonItem>
  );
};

export default DepartmentSelect;
