//import { create, trash, addCircleOutline } from 'ionicons/icons';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Footer } from "../../../components/Item/ItemKPI";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import { ProjectAPI } from "api/KPIAPI";
import List from "./list";
import { IScoreProjectData } from "models/kpi.model";
import KPICiteriaModal from "components/Modal/KPICiteriaModal";

const Page: React.FC<{ title: string; path: string }> = ({ title, path }) => {
  const {
    state: { period },
  } = useKPI();

  const [Items, setItems] = useState<Array<IScoreProjectData>>([]);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showSaving, setShowSaving] = useState<boolean>(false);
  const [ScoreList, setScoreList] = useState<Array<IScoreProjectData>>([]);
  const [totalStorypoint, setTotalStorypoint] = useState<number>(0);
  const [updateDate, setUpdateDate] = useState<Date | null>(null);
  const history = useHistory();

  useEffect(() => {
    setShowLoading(true);
    if (period) {
      ProjectAPI.read({ period: period.name })
        .then((res) => {
          const { data, total_storypoint, updateDate } = res.data;
          setItems(data);
          setScoreList(data);
          setTotalStorypoint(total_storypoint ? total_storypoint : 0);
          setUpdateDate(new Date(updateDate));
        })
        .then(() => setShowLoading(false));
    } else history.replace(path);
  }, [period, history, path]);

  const countFilled = () => {
    const allfilled = ScoreList.filter(
      (data) => data.spm_acm !== 0 && data.spm_acm !== null
    );
    return { filled: allfilled.length, all: ScoreList.length };
  };

  const updateScore = (event: any) => {
    event.preventDefault();
    setShowLoading(true);
    setShowSaving(true);

    ProjectAPI.update(ScoreList).then((item) => {
      if (Array.isArray(item.data)) {
      } else {
        console.log("failure");
      }
      setShowLoading(false);
      setShowSaving(false);
      setSaveSuccess(true);
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/kpi/prj" />
          </IonButtons>
          <IonTitle>{`${title} (${period?.name})`}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {showLoading ? (
          <Spinning />
        ) : (
          <List
            Items={Items}
            ScoreList={ScoreList}
            setScoreList={setScoreList}
            countFilled={countFilled}
            period={period}
          />
        )}
      </IonContent>
      {!showLoading && (
        <Footer
          totalStorypoint={totalStorypoint}
          filledCount={countFilled()}
          updateScore={updateScore}
          updateDate={updateDate}
          editable={period?.edit_spm}
          buttonList={[<KPICiteriaModal />]}
        />
      )}
      <IonToast
        isOpen={saveSuccess}
        onDidDismiss={() => setSaveSuccess(false)}
        message="Your data was saved."
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showSaving}
        message={"Saving..."}
        duration={0}
      />
    </IonPage>
  );
};

export default Page;
