import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "redux/store";
import {
  ISelectOption,
  IJiraProject,
  ISelectList,
  IDimensionList,
} from "models/dropdown.model";

interface DropdownState {
  userGroupOptions: Array<ISelectOption>;
  jiraProjects: Array<IJiraProject>;
  userGroupLists: Array<ISelectList>;
  dimensionsList: Array<IDimensionList>;
}

const initialState: DropdownState = {
  userGroupOptions: [],
  jiraProjects: [],
  userGroupLists: [],
  dimensionsList: [],
};

export const dropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserGroupOptions: (
      state: DropdownState,
      action: PayloadAction<ISelectOption[]>
    ) => {
      state.userGroupOptions = action.payload;
    },
    setJiraProjects: (
      state: DropdownState,
      action: PayloadAction<IJiraProject[]>
    ) => {
      state.jiraProjects = action.payload;
    },
    setUserGroupLists: (
      state: DropdownState,
      action: PayloadAction<ISelectList[]>
    ) => {
      state.userGroupLists = action.payload;
    },
    setDimensionsList: (
      state: DropdownState,
      action: PayloadAction<IDimensionList[]>
    ) => {
      state.dimensionsList = action.payload;
    },
  },
});

export const {
  setUserGroupOptions,
  setJiraProjects,
  setUserGroupLists,
  setDimensionsList,
} = dropdownSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectDDLUserGroup = (state: RootState) =>
  state.dropdown.userGroupOptions;
export const selectJiraProjects = (state: RootState) =>
  state.dropdown.jiraProjects;
export const selectUserGroupList = (state: RootState) =>
  state.dropdown.userGroupLists;
export const selectDimensionsList = (state: RootState) =>
  state.dropdown.dimensionsList;
export default dropdownSlice.reducer;
