import { IUser } from "../models/user.model";
import API, { setToken } from "./APIUtils";

function handleUserResponse({ token, ...user }: any) {
  localStorage.setItem("userToken", token);
  setToken(token);
  return user;
}

export function getCurrentUser() {
  return API.get("/auth/current");
}

export function getAvatar(emp_id: any) {
  return API.get("/auth/avatar/" + emp_id, { responseType: "blob" });
}

export function getAll() {
  return API.get("/auth/getAll");
}

export function getEmpList() {
  return API.get("/auth/emp_list");
}

export function updateAvatar(filename: string) {
  return API.put("/auth/avatar/" + filename);
}

export function uploadAvatar(file: any) {
  return API.post("/auth/avatar", file);
}

export function login(username: string, password: string) {
  return API.post("/auth/login", {
    username: username,
    password: password,
  }).then((user) => handleUserResponse(user.data));
}

export function updateUser(user: IUser & Partial<{ password: string }>) {
  return API.put("", { user });
}

export function updateProfile(user: any) {
  return API.put("/auth/current", user);
}

export function changePassword(old_pwd: string, new_pwd: string) {
  return API.put("/auth/changepwd", { old_pwd, new_pwd });
}

export function logout() {
  localStorage.removeItem("userToken");
  setToken(null);
}

export function getToken() {
  const token = localStorage.getItem("userToken");
  return token;
}

export function resetPasswordReq(username: string) {
  return API.post("/auth/reset-password", { username });
}

export function checkValidReset(token: string) {
  return API.get("/auth/check-valid-reset/" + token);
}

export function resetPassword(token: string, password: string) {
  return API.post("/auth/reset-password/" + token, { password });
}
