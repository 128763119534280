import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPermissions } from "models/permission.model";
import { IDashboardList, IUser } from "models/user.model";
import type { RootState } from "redux/store";

export interface AuthState {
  isAuthenticated: boolean;
  user: IUser | null;
  permission: Array<IPermissions>;
  dashboards: Array<IDashboardList>;
  avatar: any;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  permission: [],
  dashboards: [],
  avatar: null,
};

export const authenSlice = createSlice({
  name: "dropdown",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<IUser>) => {
      state.user = {
        ...action.payload,
        private_key: action.payload.private_key
          ? Buffer.from(action.payload.private_key, "base64").toString()
          : null,
      };
      state.permission = action.payload.resource;
      state.isAuthenticated = true;
      state.dashboards = action.payload.dashboards
    },
    setAvatar: (state: AuthState, action: PayloadAction<any>) => {
      state.avatar = action.payload;
    },
    removeUser: (state: AuthState) => {
      state.isAuthenticated = false;
      state.user = null;
      state.permission = [];
      state.avatar = null;
      state.dashboards = [];
    },
    setDashboardLists: (
      state: AuthState,
      action: PayloadAction<IDashboardList[]>
    ) => {
      state.dashboards = action.payload;
    },
  },
});

export const { setUser, setAvatar, removeUser, setDashboardLists } =
  authenSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectDDLUserGroup = (state: RootState) =>
  state.dropdown.userGroupOptions;

export default authenSlice.reducer;
