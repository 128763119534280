import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";

const Manual: React.FC = () => {
  useEffect(() => {
    console.log("MANUAL MOUNT");
    return () => {
      console.log("MANUAL UNMOUNT");
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Manual</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <object
          width="100%"
          height="100%"
          data="assets/pdf/VelaManual.pdf"
          type="application/pdf"
        >
          <iframe
            title="VelaManual"
            src="assets/pdf/VelaManual.pdf"
            width="100%"
            height="100%"
          >
            This browser does not support PDFs. Please download the PDF to view
            it:
            <a href="assets/pdf/VelaManual.pdf">Download PDF</a>
          </iframe>
        </object>
      </IonContent>
    </IonPage>
  );
};

export default Manual;
