import { person } from "ionicons/icons";
import { RootState } from "redux/store";
import { createSelector } from "reselect";
const empSelect = (state: RootState) => state.employee;

export const selectEmp = createSelector([empSelect], (emp) => emp);

export const selectEmpList = createSelector(
  [empSelect],
  (emp) => emp.employeeList
);

export const selectCurrentEmpList = createSelector([empSelect], (emp) =>
  emp.employeeList.filter((value) => value.is_employee)
);

export const selectEmpAvatar = (emp_id: string | null) =>
  createSelector([empSelect], ({ employeeList }) => {
    const emp = employeeList.find((emp) => emp.username === emp_id);
    if (emp) return "/avatar/" + emp.avatar;
    else return person;
  });

// export const getAvatarEmp = async (
//     emp_id: string,
//     emp_list: any,
//     dispatch: any
//   ) => {
//     if (!emp_list[emp_id]) return person;
//     if (emp_list[emp_id].avatar) {
//       return emp_list[emp_id].avatar;
//     } else {
//       const file = await getAvatar(emp_id);
//       const avatar = URL.createObjectURL(file.data);
//       const newEmpList = {
//         ...emp_list,
//         [emp_id]: { ...emp_list[emp_id], avatar: avatar },
//       };
//       console.log(emp_id, newEmpList);
//       dispatch({
//         type: "LOAD_EMP",
//         emp_list: newEmpList,
//       });
//       return avatar;
//     }
//   };
