import axios from "axios";
import { modelData } from "./model";
const API_URL = "/api/staff_borrow"; // TODO: change when backend is ready

export class dataAPI {
  Axios_API = axios.create({ baseURL: API_URL });

  public create(data: any) {
    return this.Axios_API.post("/request", data);
  }

  // get all submitted staff borrows
  public read(type: "rq" | "ap" | null) {
    return this.Axios_API.get("/get", { params: { type } });
  }

  public edit(id, data: any) {
    return this.Axios_API.put(`/edit/${id}`, data);
  }

  public updateStatus(
    id,
    status: "approve" | "reject" | "cancel",
    reason: any
  ) {
    return this.Axios_API.put(`/update_status/${id}`, reason, {
      params: { status },
    });
  }

  // get all submitted staff borrows
  public listds(type: "rq" | "ap" | null) {
    return this.Axios_API.get("/get", { params: { type } });
    // const mockRqData = [{
    //         requested_dept: "",
    //         requesting_pm: "",
    //         employee_group: "ds",
    //         employee: "ben.ru",
    //         sprint_name: "23-10B",
    //         sp_amt: 3,
    //         status: "waiting approve",
    //         type: 'rq',
    //         approved_by: ""},

    //     {
    //         requested_dept: "",
    //         requesting_pm: "",
    //         employee_group: "ds",
    //         employee: "amorn.ch",
    //         sprint_name: "23-10B",
    //         sp_amt: 3,
    //         status: "approved",
    //         type: 'rq',
    //         approved_by: ""},
    //     {
    //         requested_dept: "",
    //         requesting_pm: "",
    //         employee_group: "ds",
    //         employee: "amorn.ch",
    //         sprint_name: "23-11B",
    //         sp_amt: 3,
    //         status: "rejected",
    //         type: 'rq',
    //         approved_by: ""},
    //     ]
    //     const mockApData = [
    //     {
    //         requested_dept: "",
    //         requesting_pm: "",
    //         employee_group: "de",
    //         employee: "kusapon.ph",
    //         sprint_name: "23-10B",
    //         sp_amt: 3,
    //         status: "waiting approve",
    //         type: 'ap',
    //         approved_by: ""},
    //     {
    //         requested_dept: "",
    //         requesting_pm: "",
    //         employee_group: "ds",
    //         employee: "kittisak.sa",
    //         sprint_name: "23-10B",
    //         sp_amt: 3,
    //         status: "approved",
    //         type: 'ap',
    //         approved_by: ""},
    //     ]
    //     const mockAllData = [{
    //         requested_dept: "",
    //         requesting_pm: "",
    //         employee_group: "de",
    //         employee: "kusapon.ph",
    //         sprint_name: "23-10B",
    //         sp_amt: 3,
    //         status: "waiting approve",
    //         type: 'ap',
    //         approved_by: ""},
    //         {
    //         requested_dept: "",
    //         requesting_pm: "",
    //         employee_group: "ds",
    //         employee: "kittisak.sa",
    //         sprint_name: "23-10B",
    //         sp_amt: 3,
    //         status: "approved",
    //         type: 'ap',
    //         approved_by: ""},
    //         {
    //             requested_dept: "",
    //             requesting_pm: "",
    //             employee_group: "ds",
    //             employee: "ben.ru",
    //             sprint_name: "23-10B",
    //             sp_amt: 3,
    //             status: "waiting approve",
    //             type: 'rq',
    //             approved_by: ""},
    //         {
    //             requested_dept: "",
    //             requesting_pm: "",
    //             employee_group: "ds",
    //             employee: "amorn.ch",
    //             sprint_name: "23-10B",
    //             sp_amt: 3,
    //             status: "approved",
    //             type: 'rq',
    //             approved_by: ""},
    //         {
    //             requested_dept: "",
    //             requesting_pm: "",
    //             employee_group: "ds",
    //             employee: "amorn.ch",
    //             sprint_name: "23-11B",
    //             sp_amt: 3,
    //             status: "rejected",
    //             type: 'rq',
    //             approved_by: ""},
    //     ]
    // return new Promise<{data: modelData[]}>(resolve => setTimeout(()=> {
    //     let data
    //     switch (type) {
    //         case 'rq':
    //             data = mockRqData
    //             break
    //         case 'ap':
    //             data = mockApData
    //             break
    //         default:
    //             data = mockAllData
    //             break;
    //     }
    //     return resolve({data})
    // },2000))
  }
}
