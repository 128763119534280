import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";

const PowerBIDashboard: React.FC<{
  id: string;
  url: string;
  title: string;
}> = ({ id, url, title }) => {
  useEffect(() => {
    console.log("BI MOUNT: " + id);
    return () => {
      console.log("BI UNMOUNT: " + id);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <iframe
          title="BiDashboard"
          src={url}
          width="100%"
          height="100%"
          allowFullScreen={true}
        ></iframe>
      </IonContent>
    </IonPage>
  );
};

export default PowerBIDashboard;
