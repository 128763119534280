import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItemGroup,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import { changePassword } from "../api/AuthAPI";
import { InputText } from "../components/FormInputType";
import "./Profile.scss";
import ProfileModal from "./ProfileModal";

const Profile: React.FC = () => {
  const [showLoading] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const [Passwordmodal, setPasswordModal] = useState(false);
  const [iserror, setIserror] = useState<boolean>(false);
  const [passwordCurrent, setPasswordCurrent] = useState<string>("");
  const [passwordNew, setPasswordNew] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [notSame, setNotSame] = useState<boolean>(false);
  const [ProfileSuccess, setProfileSuccess] = useState<boolean>(false);
  const [PWSuccess, setPWSuccess] = useState<boolean>(false);
  const { user, avatar } = useAppSelector(selectAuthen);

  const toggle = () => {
    setModal(!modal);
  };

  const clearPasswordModal = () => {
    setPasswordCurrent("");
    setPasswordNew("");
    setPasswordConfirm("");
    setNotSame(false);
  };

  const submitFormEdit = (event: any) => {
    event.preventDefault();

    changePassword(passwordCurrent, passwordNew)
      .then(() => {
        clearPasswordModal();
        setPasswordModal(false);
        setPWSuccess(true);
      })
      .catch((error) => {
        if (error.status === 406) {
          setIserror(true);
        }
      });
  };

  const ChangePasswordModal = (
    <IonModal isOpen={Passwordmodal} >
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonTitle>Change Password</IonTitle>
          <IonButtons slot="end">
            <IonButton
              expand="block"
              onClick={(e) => {
                if (passwordNew === passwordConfirm) submitFormEdit(e);
                else setNotSame(true);
              }}
            >
              Save
            </IonButton>
            <IonButton
              expand="block"
              onClick={() => {
                setPasswordModal(false);
                clearPasswordModal();
              }}
            >
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItemGroup>
          <InputText
            label="Current Password"
            name="passwordCurrent"
            value={passwordCurrent}
            type="password"
            onChange={(e: any) => setPasswordCurrent(e.target.value!)}
          />
          <InputText
            label="New Password"
            name="passwordNew"
            value={passwordNew}
            type="password"
            onChange={(e: any) => setPasswordNew(e.target.value!)}
          />
          <InputText
            label="Confirm New Password"
            name="lastname"
            type="password"
            value={passwordConfirm}
            onChange={(e: any) => setPasswordConfirm(e.target.value!)}
          />
          {notSame && (
            <IonLabel color="danger">
              Your confirm password is not the same try again
            </IonLabel>
          )}
        </IonItemGroup>
      </IonContent>
    </IonModal>
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        {!showLoading && (
          <div>
            <IonGrid>
              <IonRow>
                <IonCol>
                  {" "}
                  <div className="ion-text-center">
                    <IonAvatar
                      style={{
                        margin: "auto",
                        width: "250px",
                        height: "250px",
                      }}
                    >
                      <img
                        src={avatar}
                        alt="avatar"
                        width="250px"
                        height="250px"
                      />
                    </IonAvatar>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div className="ion-text-center">
                    <IonCardTitle
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "30px",
                      }}
                    >
                      {user?.firstname + " " + user?.lastname}
                    </IonCardTitle>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonCard style={{ padding: "10px" }}>
              <IonCardContent>
                <IonList>
                  <IonGrid>
                    <IonRow>
                      <IonCol className="col-profile">
                        <IonCardSubtitle>Username</IonCardSubtitle>
                        <IonCardTitle style={{ color: "black" }}>
                          {user?.username}
                        </IonCardTitle>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="col-profile">
                        <IonCardSubtitle>Firstname</IonCardSubtitle>
                        <IonCardTitle style={{ color: "black" }}>
                          {user?.firstname}
                        </IonCardTitle>
                      </IonCol>
                      <IonCol className="col-profile">
                        <IonCardSubtitle>Lastname</IonCardSubtitle>
                        <IonCardTitle style={{ color: "black" }}>
                          {user?.lastname}
                        </IonCardTitle>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="col-profile">
                        <IonCardSubtitle>Email</IonCardSubtitle>
                        <IonCardTitle style={{ color: "black" }}>
                          {user?.email}
                        </IonCardTitle>
                      </IonCol>
                    </IonRow>
                    <IonRow id="button">
                      <IonCol className="col-profile">
                        <IonButton expand="block" onClick={toggle}>
                          Edit Account
                        </IonButton>
                      </IonCol>
                      <IonCol className="col-profile">
                        <IonButton
                          expand="block"
                          onClick={() => setPasswordModal(true)}
                        >
                          Change Password
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonList>
              </IonCardContent>
            </IonCard>
            {user && (
              <ProfileModal
                item={user}
                avatar={avatar}
                modal={modal}
                setModal={setModal}
                setProfileSuccess={setProfileSuccess}
              />
            )}

            {ChangePasswordModal}
          </div>
        )}
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          message={"Please wait..."}
          duration={5000}
        />
        <IonToast
          isOpen={PWSuccess}
          onDidDismiss={() => setPWSuccess(false)}
          message="Your password was changed."
          duration={2000}
        />
        <IonToast
          isOpen={ProfileSuccess}
          onDidDismiss={() => setProfileSuccess(false)}
          message="Your profile data was changed."
          duration={2000}
        />
      </IonContent>
      <IonAlert
        isOpen={iserror}
        onDidDismiss={() => setIserror(false)}
        cssClass="my-custom-class"
        header={"Password Wrong"}
        message={
          "Cannot change your password! Maybe your old password was incorrect. Please try again"
        }
        buttons={["OK"]}
      />
    </IonPage>
  );
};

export default Profile;
