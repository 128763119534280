import API from "./APIUtils";

const API_BASE = "/api/kpi";

export const IndividualAPI = {
  read: (data?: any) => {
    return API.get("/indv", { baseURL: API_BASE, params: data });
  },
  readPeriod: (data?: any) => {
    return API.get("/indv/period", { baseURL: API_BASE, params: data });
  },
  update: (data?: any) => {
    return API.put("/indv", data, { baseURL: API_BASE });
  },
};


export const ProjectMemberAPI = {
  read: (data?: any) => {
    return API.get("/pm", { baseURL: API_BASE, params: data });
  },
  readPeriod: (data?: any) => {
    return API.get("/pm/period", { baseURL: API_BASE, params: data });
  },
  update: (data?: any) => {
    return API.put("/pm", data, { baseURL: API_BASE });
  },
};

export const ProjectAPI = {
  read: (data?: any) => {
    return API.get("/spm", { baseURL: API_BASE, params: data });
  },
  readPeriod: (data?: any) => {
    return API.get("/period", { baseURL: API_BASE, params: data });
  },
  readDetail: (data?: any) => {
    return API.get("/spm/detail", { baseURL: API_BASE, params: data });
  },
  update: (data?: any) => {
    return API.put("/spm", data, { baseURL: API_BASE });
  },
};

export const EmployeeAPI = {
  read: (data?: any) => {
    return API.get("/director", { baseURL: API_BASE, params: data });
  },
  readPeriod: (data?: any) => {
    return API.get("/director/period", { baseURL: API_BASE, params: data });
  },
  readDetail: (data?: any) => {
    return API.get("/director/indv", { baseURL: API_BASE, params: data });
  },
  readReport: (data?: any) => {
    return API.get("/director/report", { baseURL: API_BASE, params: data });
  },
  update: (data?: any, params?: any) => {
    return API.put("/director", data, { baseURL: API_BASE, params: params });
  },
  updatePercent: (data?: any, params?: any) => {
    return API.put("/director/percent", data, { baseURL: API_BASE, params: params });
  },
  readRadar: (data?: any) => {
    return API.get("/director/radar_indv", { baseURL: API_BASE, params: data });
  },
};

export const DepartmentAPI = {
  read: (data?: any) => {
    return API.get("/department", { baseURL: API_BASE, params: data });
  },
  readPeriod: (data?: any) => {
    return API.get("/department/period", { baseURL: API_BASE, params: data });
  },
  readDetail: (data?: any) => {
    return API.get("/department/indv", { baseURL: API_BASE, params: data });
  },
  readReport: (data?: any) => {
    return API.get("/department/report", { baseURL: API_BASE, params: data });
  },
  update: (data?: any, params?: any) => {
    return API.put("/department", data, { baseURL: API_BASE, params: params });
  },
};

export const E360API = {
    read: (data?: any) => {
      return API.get("/e360", { baseURL: API_BASE, params: data });
    },
    readPeriod: (data?: any) => {
      return API.get("/e360/period", { baseURL: API_BASE, params: data });
    },
    update: (data?: any, params?: any) => {
      return API.put("/e360", data, { baseURL: API_BASE, params: params });
    },
  };


export const ReportKPIGroupAPI = {
  read: (data?: any) => {
    return API.get("/summary_kpi", { baseURL: API_BASE, params: data });
  },
  readPeriod: (data?: any) => {
    return API.get("/summary_kpi/period", { baseURL: API_BASE, params: data });
  },
  readReport: (data?: any) => {
    return API.get("/summary_kpi/report", { baseURL: API_BASE, params: data });
  },
  update: (data?: any, params?: any) => {
    return API.put("/summary_kpi", data, { baseURL: API_BASE, params: params });
  },
};

export const ReportProjectGroupAPI = {
  read: (data?: any) => {
    return API.get("/summary_prj", { baseURL: API_BASE, params: data });
  },
  readPeriod: (data?: any) => {
    return API.get("/summary_prj/period", { baseURL: API_BASE, params: data });
  },
  readReport: (data?: any) => {
    return API.get("/summary_prj/report", { baseURL: API_BASE, params: data });
  },
  update: (data?: any, params?: any) => {
    return API.put("/summary_prj", data, { baseURL: API_BASE, params: params });
  },
};

export const FinalScoreAPI = {
  read: (data?: any) => {
    return API.get("/final", { baseURL: API_BASE, params: data });
  },
  readPeriod: (data?: any) => {
    return API.get("/final/period", { baseURL: API_BASE, params: data });
  },
};

export const KPIDashboardAPI = {
    read: (data?: any) => {
      return API.get("/summary", { baseURL: API_BASE, params: data });
    },
    readPeriod: (data?: any) => {
      return API.get("/period", { baseURL: API_BASE, params: data });
    },
    readRadar: (data?: any) => {
      return API.get("/radar_indv", { baseURL: API_BASE, params: data });
    },
  };
  
