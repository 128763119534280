import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import NormalCurve from "components/NormalDistKPI";
import { EmpChip } from "components/Select/EmployeeSelect";
import { list } from "ionicons/icons";
import { IScoreFinalData } from "models/kpi.model";
import React from "react";
import { selectEmpAvatar } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { roundTwoDigit } from "../../../utils";

const ModalForm: React.FC<{
  data: IScoreFinalData;
  avg: number;
  stddev: number;
  dataList: Array<IScoreFinalData>;
}> = ({ data, stddev, avg, dataList }) => {
  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const avatar = useAppSelector(selectEmpAvatar(data.emp_id));
  const Loadingstyle = {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <IonButton color="primary" fill="solid" onClick={toggle}>
        <IonIcon icon={list} />
        {"Click for Detail"}
      </IonButton>
      <IonModal isOpen={modal}>
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonButtons slot="start"></IonButtons>
            <IonTitle>Detail ({`${data.emp_id}`})</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-justify-content-center">
          {data ? (
            <>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Final Score</IonCardTitle>
                </IonCardHeader>
                <IonRow>
                  <IonCol size-sm="6">
                    {data.emp_id && (
                      <IonAvatar
                        className="ion-padding"
                        style={{
                          margin: "auto",
                          height: "250px",
                          width: "250px",
                        }}
                      >
                        <img
                          src={avatar}
                          alt="avatar"
                        />
                      </IonAvatar>
                    )}
                  </IonCol>
                  <IonCol size-sm="6">
                    <IonCardContent>
                      <IonList>
                        <IonItemGroup>
                          <IonItem>
                            <IonLabel>PM Score</IonLabel>
                            {data.pm_acm ? roundTwoDigit(data.pm_acm) : "N/A"}
                          </IonItem>
                          <IonItem>
                            <IonLabel>Director Score</IonLabel>
                            {data.director_acm
                              ? roundTwoDigit(data.director_acm)
                              : "N/A"}
                          </IonItem>
                          <IonItem>
                            <IonLabel position="stacked">
                              Director's Adjustment Reason
                            </IonLabel>
                            <IonTextarea
                              readonly
                              value={data.comment ? data.comment : "N/A"}
                            ></IonTextarea>
                          </IonItem>
                          <IonItem>
                            <IonLabel>T Score</IonLabel>
                            {data.t_score ? roundTwoDigit(data.t_score) : "N/A"}
                          </IonItem>
                        </IonItemGroup>
                      </IonList>
                    </IonCardContent>
                  </IonCol>
                </IonRow>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <EmpChip value={data.emp_id}></EmpChip>
                    {`Score Range`}
                  </IonCardTitle>
                </IonCardHeader>
                <div
                  className="ion-padding ion-margin"
                  style={{ height: "300px" }}
                >
                  <NormalCurve
                    stddev={stddev}
                    avg={avg}
                    dataList={dataList}
                    data={data}
                    scoreKey="director_acm"
                  />
                </div>
              </IonCard>
            </>
          ) : (
            <div style={Loadingstyle}>No Data</div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ModalForm;
