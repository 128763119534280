import { IonSpinner } from "@ionic/react";
import React from "react";

const Spinning: React.FC = () => {
  const Loadingstyle = {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={Loadingstyle}>
      <IonSpinner color="primary" />
    </div>
  );
};

export default Spinning;
