/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosResponse } from "axios";
import API from "./APIUtils";
const API_BASE = "/api/staff-borrow"; // TODO: change when backend is ready

const api = axios.create({
  // baseURL: '', // Replace with your API's base URL
});

export const getDepartment = async () => {
  //   const response = await api.get('/api/organizations', getDefaultHeader(true));
  const response = { ds: "Data Science", de: "Data Engineer" };
  return response;
};

export const getAllJiraProjects = async () => {
  const response = await API.get("/api/staff_borrow/project");
  console.log("getAllJiraProjects response", response);
  return response;
};

export const getUserGroup = async () => {
  const response = await API.get("/api/user_group");
  console.log("getUserGroup response", response);
  return response;
};

export const getDimensions = async () => {
  const response = await API.get("/api/kpi/score_dimension");
  console.log("getDimension response", response);
  return response;
};
