import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { selectAuthen } from "redux/authen/selector";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { login } from "../api/AuthAPI";
import { useAddToHomescreenPrompt } from "../components/Hooks/useAddToHomescreenPrompt";
import "../theme/login.scss";
import { SetAuthData } from "../utils/auth.util";
import ResetPasswordModal from "components/Modal/ResetPasswordModal";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [iserror, setIserror] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = useState(false);

  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(selectAuthen);
  const history = useHistory();

  useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
    console.log("LOGIN MOUNT");
    return () => {
      console.log("LOGIN UNMOUNT");
    };
  }, [prompt]);

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setShowLoading(true);
    try {
      const user = await login(username, password);
      SetAuthData(dispatch, user);
      history.push("/home", { direction: "root" });
    } catch (error) {
      console.log(error);
      setShowLoading(false);
      if (error.status === 422) {
        setMessageError(
          "Your username or password is incorrect. Please try again"
        );
        setIserror(true);
        return false;
      } else {
        setMessageError("Process Fail. Please try again");
        setIserror(true);
        return false;
      }
    }
  };

  return (
    <IonPage>
      {/* <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Please wait..."}
      /> */}
      <IonContent fullscreen className="ion-no-padding ion-text-center">
        <div className="outer">
          <div className="inner">
            <form onSubmit={handleSubmit}>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <div className="login-logo">
                      <img
                        src="assets/img/vela_logo.png"
                        alt="Vera logo"
                        width="150px"
                        height="150px"
                      />
                      <IonText>Vela</IonText>
                    </div>
                  </IonCol>
                  {showLoading && <IonSpinner />}
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="fixed"> Username</IonLabel>
                      <IonInput
                        type="text"
                        name="username"
                        value={username}
                        autocomplete="username"
                        onIonChange={(e) => setUsername(e.detail.value!)}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="fixed"> Password</IonLabel>
                      <IonInput
                        type="password"
                        name="password"
                        value={password}
                        autocomplete="current-password"
                        clearOnEdit={false}
                        onIonChange={(e) => setPassword(e.detail.value!)}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <a
                  className="ion-padding-start"
                  style={{ cursor: "pointer" }}
                  target="_self"
                  onClick={() => {}}
                >
                  <ResetPasswordModal />
                </a>
                <input hidden type="submit" value="Submit" />
                <IonRow>
                  {iserror && !showLoading && (
                    <IonText color="danger">{messageError}</IonText>
                  )}
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton expand="block" onClick={handleSubmit}>
                      {"Login"}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
            {isVisible && (
              <div>
                {"Would you like to install Vela to your homescreen? "}
                <button
                  onClick={async () => {
                    if (prompt) {
                      setVisibleState(false);
                      promptToInstall();
                      const { outcome } = await prompt.userChoice;
                      console.log(
                        `User response to the install prompt: ${outcome}`
                      );
                      if (outcome === "accepted") window.location.reload();
                    }
                  }}
                >
                  Click here
                </button>
              </div>
            )}
          </div>
        </div>
      </IonContent>
      {/* <IonAlert
        isOpen={iserror}
        onDidDismiss={() => setIserror(false)}
        cssClass="my-custom-class"
        header={"Log in Fail"}
        message={messageError}
        buttons={["OK"]}
      /> */}
    </IonPage>
  );
};

export default Login;
