// export { default as AuthRouter } from "./AuthRouter";
// export { default as AppRouter } from "./AppRouter";
import { IonRouterOutlet } from "@ionic/react";
import { setToken } from "api/APIUtils";
import { getCurrentUser, getToken, logout } from "api/AuthAPI";
import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { selectAuthen } from "redux/authen/selector";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { SetAuthData } from "utils/auth.util";
import AuthRouter from "./AuthenRoute";
import MainRouter from "./MainRoute";

const AppRouter = () => {
  const { isAuthenticated } = useAppSelector(selectAuthen);
  //   const [token, setToken] = useState<string | null>();
  const [fetch, setFetch] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  //   const token = getToken();

  useEffect(() => {
    console.log("isLoggedIn : ", isAuthenticated);
    if (!fetch) {
      const token = getToken();
      if (token) {
        setToken(token);
        const currentUserPromise = getCurrentUser()
          .then((payload) => {
            const { token, ...user } = payload.data;
            SetAuthData(dispatch, user);
          })
          .catch((error) => {
            logout();
            console.log(error);
          });

        Promise.all([currentUserPromise]).then(() =>
          setFetch(true)
        );
      } else {
        setFetch(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  if (!fetch) return <></>;

  return (
    <IonRouterOutlet>
      <Route>{isAuthenticated ? <MainRouter /> : <AuthRouter />}</Route>
    </IonRouterOutlet>
  );
};

export default AppRouter;
