import { IonLoading, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { getDDL } from "api/DataAPI";
import { getAllJiraProjects, getDimensions, getUserGroup } from "api";
import PrivateRoute from "components/PrivateRoute";
import Manual from "pages/Manual";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  setUserGroupOptions,
  setJiraProjects,
  setUserGroupLists,
  setDimensionsList,
} from "redux/dropdown/reducer";
import { useAppDispatch, useAppSelector } from "redux/hooks";
//import Not_Found from "../pages/Not_Found";
import Not_Found from "routes/NotFoundLogin";
import { SetEmpListData } from "utils/auth.util";
import Menu from "../components/Menu";
import routes from "../pages/route";
import { renderRoutes } from "../utils/route.util";
import { selectAuthen } from "redux/authen/selector";
import PowerBIDashboard from "pages/PowerBIDashboard";

const MainRoute: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { dashboards } = useAppSelector(selectAuthen);
  useEffect(() => {
    console.log("MAIN ROUTE");
    setShowLoading(true);

    const empListPromise = SetEmpListData(dispatch);

    const ddlPromise = getDDL().then((payload) => {
      const { user_group } = payload.data;
      dispatch(setUserGroupOptions(user_group));
    });

    const userGroupListsPromise = getUserGroup().then((payload) => {
      const data = payload.data;
      dispatch(setUserGroupLists(data));
    });

    const jiraProjectsPromise = getAllJiraProjects().then((payload) => {
      const data = payload.data;
      dispatch(setJiraProjects(data));
    });

    const dimensionListPromise = getDimensions().then((payload) => {
      const data = payload.data;
      dispatch(setDimensionsList(data));
    });

    Promise.all([
      empListPromise,
      ddlPromise,
      jiraProjectsPromise,
      userGroupListsPromise,
      dimensionListPromise,
    ]).then(() => setShowLoading(false));

    const content = document.querySelector("ion-content");
    const styles = document.createElement("style");

    styles.textContent = `
    ::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
      border-radius: 10px;
    }
    ::-webkit-scrollbar
    {
      width: 10px;
      background-color: #F5F5F5;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      background-color: #1e1e1e;
    }
    `;
    if (content && content.shadowRoot) {
      content.shadowRoot.appendChild(styles);
    }
  }, [dispatch]);

  return (
    <IonSplitPane contentId="main" style={{ "--side-max-width": "300px" }}>
      <Menu />
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <IonRouterOutlet id="main">
        <Switch>
          {renderRoutes(routes, "main")}
          {renderRoutes(
            dashboards.map((value) => ({
              path: "/" + value.id,
              component: PowerBIDashboard,
              componentProps: {
                id: value.id,
                url: value.url,
                title: value.name,
              },
              page: value.id,
              requirePermission: false,
              layout: "main",
            })),
            "main"
          )}
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route exact path="/login" render={() => <Redirect to="/home" />} />
          <PrivateRoute path="/manual" component={Manual} page="manual" />
          <Route exact path="/notfound" component={Not_Found} />
          <Route render={() => <Redirect to="/notfound" />} />
        </Switch>
      </IonRouterOutlet>
      {/* </Suspense> */}
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Loading ..."}
        duration={0}
      />
    </IonSplitPane>
  );
};

export default MainRoute;
