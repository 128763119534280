import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { download } from "ionicons/icons";
import { IScoreFinalData } from "models/kpi.model";
import React, { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { getEmpDetail } from "utils";
import { EmpChip } from "../../../components/Select/EmployeeSelect";
import useKPI from "../../../store/KPIProvider";
import { roundTwoDigit } from "../../../utils";

const ExportModal: React.FC<{ data: Array<IScoreFinalData> }> = ({
  data,
}) => {
  const [modal, setModal] = React.useState(false);
  const [scoreList, setScoreList] = React.useState<Array<any>>([]);
  const [report, setReport] = React.useState<Array<any>>([]);
  const [isRound, setIsRound] = React.useState(false);
  const csvInstance = useRef<any>(null);
  const empList = useAppSelector(selectEmpList);

  const {
    state: { period },
  } = useKPI();

  useEffect(() => {
    if (modal) {
      setScoreList(data);
      setIsRound(false);
      setReport(
        data.map((emp: any) => ({
          ...emp,
          ...getEmpDetail(emp.emp_id, empList),
        }))
      );
    }
  }, [modal, data, empList]);

  const toggle = () => {
    setModal(!modal);
  };

  const Loadingstyle = {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  };

  const filename = "AllEmployeeKPI-" + period?.name + ".csv";

  const headers = [
    { label: "username", key: "emp_id" },
    { label: "emp_id", key: "depa_emp_id" },
    { label: "firstname", key: "firstname" },
    { label: "lastname", key: "lastname" },
    { label: "director_score", key: "director_acm" },
    { label: "z-score", key: "z_score" },
    { label: "t-score", key: "t_score" },
  ];

  const downloadCSV = () => {
    csvInstance.current.link.click();
    setModal(false);
  };

  const handleRounding = (e: any) => {
    const checked = e.detail.checked;
    setIsRound(checked);
    if (checked) {
      setReport(
        scoreList.map((emp: any) => ({
          ...emp,
          ...getEmpDetail(emp.emp_id, empList),
          director_acm: Math.round(emp.director_acm * 2) / 2,
        }))
      );
    } else {
      setReport(
        scoreList.map((emp: any) => ({
          ...emp,
          ...getEmpDetail(emp.emp_id, empList),
          director_acm: emp.director_acm,
        }))
      );
    }
  };

  return (
    <>
      <IonButton aria-label="Save" onClick={() => toggle()}>
        <IonIcon slot="icon-only" icon={download} />
      </IonButton>
      <IonModal isOpen={modal} >
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonButtons slot="start"></IonButtons>
            <IonTitle>Export</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonItem lines="none">
              <IonCheckbox
                slot="start"
                checked={isRound}
                onIonChange={handleRounding}
                disabled={report.length === 0}
              />
              <IonLabel>Mathematical Rounding</IonLabel>
              <IonButtons slot="end">
                <IonButton disabled={report.length === 0} onClick={downloadCSV}>
                  Download
                </IonButton>
              </IonButtons>
            </IonItem>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-justify-content-center">
          {scoreList.length !== 0 ? (
            <>
              <CSVLink
                ref={csvInstance}
                data={report}
                headers={headers}
                filename={filename}
                target="_blank"
              />
              <IonList>
                {report.map((value) => (
                  <IonItem key={value.emp_id}>
                    <EmpChip value={value.emp_id}></EmpChip>
                    <IonText slot="end">
                      <span
                        style={{ marginRight: "10px" }}
                      >{`Director Score : ${roundTwoDigit(
                        value.director_acm
                      )}`}</span>
                      <span>{`T Score : ${roundTwoDigit(value.t_score)}`}</span>
                    </IonText>
                  </IonItem>
                ))}
              </IonList>
            </>
          ) : (
            <div style={Loadingstyle}>No Data</div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ExportModal;
