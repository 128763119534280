import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";
import "../theme/login.scss";

const NotFound: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonTitle>Page not found</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding ion-text-center">
        <div className="outer">
          <div className="inner">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <div className="login-logo">
                    <IonText>Oops!!!</IonText>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonText slot="end">
                      <h1>404 Page not found</h1>
                    </IonText>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol></IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="block"
                    onClick={() => history.push("/home")}
                  >
                    Go to Home
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
