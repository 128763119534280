import { IonAvatar, IonChip, IonIcon, IonLabel } from "@ionic/react";
import { helpCircle } from "ionicons/icons";
import React from "react";
import { selectEmpAvatar } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { roundTwoDigit } from "utils";

export const StorypointChip: React.FC<{ value: number; slot?: string }> = ({
  value,
  slot = "end",
}) => {
  return value ? (
    <IonChip color="success" slot={slot}>
      {roundTwoDigit(value)} storypoint
    </IonChip>
  ) : (
    <></>
  );
};

export const EmpChip: React.FC<{
  value: any;
}> = ({ value }) => {
  const avatarEmp = useAppSelector(selectEmpAvatar(value));
  return value ? (
    <IonChip color="secondary">
      <IonAvatar>
        <img src={avatarEmp} alt="avatar" loading="lazy" />
      </IonAvatar>
      <IonLabel>{value}</IonLabel>
    </IonChip>
  ) : (
    <IonChip color="secondary">
      <IonIcon icon={helpCircle} />
      <IonLabel>{"N/A"}</IonLabel>
    </IonChip>
  );
};

export const LeaveStorypointChip: React.FC<{
  value: number;
  slot?: string;
}> = ({ value, slot = "end" }) => {
  return value ? (
    <IonChip color="orange" slot={slot}>
      LEAVE : {roundTwoDigit(value)}
    </IonChip>
  ) : (
    <></>
  );
};
