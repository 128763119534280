import { IPeriod } from "../models/kpi.model";

export type KPIAction =
  | {
      type: "SET_EMP";
      emp_id: string | null;
    }
  | {
      type: "SET_PERIOD";
      period: IPeriod | null;
    };

export interface KPIState {
  emp_id: string | null;
  period: IPeriod | null;
}

export const initialState: KPIState = {
  emp_id: null,
  period: null,
};

export const KPIReducer = (state: KPIState, action: KPIAction): KPIState => {
  switch (action.type) {
    case "SET_EMP": {
      return { ...state, emp_id: action.emp_id };
    }
    case "SET_PERIOD": {
      return {
        ...state,
        period: action.period,
      };
    }
    default:
      return state;
  }
};
