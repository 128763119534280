import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import { IRoute } from "../models/route.model";

export const renderRoutes: any | null | Route = (
  routes: IRoute[],
  layout: string
) => {
  let mappedRoutes: any[] = [];
  mappedRoutes = routes.map((prop, key) => {
    if (prop.layout === layout) {
      return (
        <PrivateRoute
          path={prop.path}
          component={prop.component}
          componentProps={prop.componentProps}
          key={key}
          page={prop.page}
          requirePermission={prop.requirePermission}
          // exact={!prop.subpage}
        />
      );
    } else return null;
  });

  return mappedRoutes.filter((route) => route);
};

export const getPageName = (routes: IRoute[]) => {
  for (let route of routes) {
    if (window.location.pathname.startsWith(route.layout + route.path)) {
      return route.page;
    }
  }
  return "Unknown";
};
