import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EmpChip } from "./Select/EmployeeSelect";

interface props {
  avg: number;
  stddev: number;
  dataList: Array<any>;
  data?: any;
  scoreKey: string;
}

const NormalDistKPI: React.FC<props> = ({
  avg,
  stddev,
  data,
  dataList,
  scoreKey,
}) => {
  const binsize = 0.25;

  const normDist = (avg: number, stddev: number, x: number) => {
    const ni1 = 1 / (stddev * Math.sqrt(2 * Math.PI));
    const ni2 = Math.exp(
      (-1 * ((x - avg) * (x - avg))) / (2 * (stddev * stddev))
    );
    return ni1 * ni2;
  };

  const [series, setSeries] = useState<Array<any>>([]);

  useEffect(() => {
    const frequencies = (values: Array<any>) => {
      var mapped = values.map((val) => {
        return {
          scoreRange: Math.floor(val[scoreKey] / binsize),
          emp_id: val.emp_id,
        };
      });

      const list = mapped.reduce((freqs: any, val) => {
        var bin = binsize * val.scoreRange;
        freqs[bin] ? freqs[bin].count++ : (freqs[bin] = { count: 1, list: [] });
        freqs[bin].list.push(val.emp_id);
        return freqs;
      }, {});
      return list;
    };
    const freq = frequencies(dataList);
    const x = _.range(2, 5.1, 0.25);
    const chart = x.map((x) => ({
      x,
      norm: normDist(avg, stddev, x),
      freq: freq[x] ? freq[x].count : 0,
      list: freq[x] ? freq[x].list : [],
    }));
    setSeries(chart);
  }, [avg, stddev, dataList, scoreKey]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: 10,
            backgroundColor: "white",
            border: "2px solid lightGrey",
          }}
        >
          <div>{`Score ${
            label >= 5 ? ` >5` : `${label}-${label + binsize}`
          } : ${payload[0].value}`}</div>
          {!data && (
            <div>
              {payload[0].payload.list.map((emp: string) => (
                <EmpChip value={emp} key={emp} />
              ))}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width={"99%"} height={300}>
      <ComposedChart
        data={series}
        margin={{
          top: 5,
          left: 0,
          right: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          type="number"
          domain={[2, "dataMax"]}
          ticks={_.range(2, 5.5, 0.5)}
        />
        <YAxis yAxisId="norm" hide />
        <YAxis yAxisId="freq" />
        <Bar yAxisId="freq" dataKey="freq" fill="#413ea0">
          {series.map((entry, index) => (
            <Cell
              cursor="pointer"
              fill={
                data &&
                Math.floor(data[scoreKey] / binsize) * binsize === entry.x
                  ? "#82ca9d"
                  : "#8884d8"
              }
              key={`cell-${index}`}
            />
          ))}
        </Bar>
        <Line
          yAxisId="norm"
          dataKey="norm"
          stroke="red"
          type="basis"
          dot={false}
          activeDot={false}
        />
        <Tooltip cursor={false} content={<CustomTooltip />} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default NormalDistKPI;
