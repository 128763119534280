import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonNote,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import React, { useState } from "react";

const KPICiteriaModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };

  const ScoreChip: React.FC<{ text: string }> = ({ text }) => (
    <IonChip color="secondary">{text}</IonChip>
  );

  return (
    <>
      <IonButton aria-label="Save" onClick={() => toggle()}>
        <IonIcon slot="icon-only" icon={informationCircle} />
      </IonButton>
      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        breakpoints={[0, 0.25, 0.5, 1]}
      >
        <IonToolbar color="gbdi">
          <IonButtons slot="start"></IonButtons>
          <IonTitle>Citeria</IonTitle>
          <IonButtons slot="end">
            <IonButton
              expand="block"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent className="ion-padding">
          <IonList>
            <IonItem>
              <p>
                <ScoreChip text="5 : Outstanding" />
                <br />
                <IonText>
                  Exceeds ALL or MOST performance targets significantly,
                  compared with performance of the same employee group
                </IonText>
                <br />
                <small>
                  ผลงานทั้งหมดหรือส่วนใหญ่ (เกิน 90% - ทั้งงานหลัก และงานรอง)
                  เกินกว่าเป้าหมายที่กำหนดไว้อย่างเด่นชัด
                  หรือเมื่อเปรียบเทียบกับผลการปฏิบัติงานของกลุ่มพนักงานเดียวกัน
                </small>
              </p>
            </IonItem>
            <IonItem>
              <p>
                <ScoreChip text="4 : Always Meet and Some Exceed Target" />
                <br />
                <IonText>
                  Meets ALL performance targets and SOME major goals are
                  exceeded with significant level, compared with performance of
                  the same employee group
                </IonText>
                <br />
                <small>
                  ผลงานทั้งหมดเป็นไปตามเป้าหมายที่กำหนดไว้
                  และมีผลงานหลักบางอย่างเกินกว่าเป้าหมายที่กำหนดไว้อย่างเด่นชัด
                  เมื่อเปรียบเทียบกับผลการปฏิบัติงานของกลุ่มพนักงานเดียวกัน
                  (จำนวนผลงานหลักที่ได้ผลเกินเป้าหมาย
                  ขึ้นกับงานที่ได้รับมอบหมายมา)
                </small>
              </p>
            </IonItem>
            <IonItem>
              <p>
                <ScoreChip text="3 : General Meet Target" />
                <br />
                <IonText>
                  Meets ALL or MAJORITY performance targets, compared with
                  performance of the same employee group
                </IonText>
                <br />
                <small>
                  ผลงานทั้งหมดหรือเกินกว่า 75% เป็นไปตามเป้าหมายที่กำหนดไว้
                  เมื่อเปรียบเทียบกับผลการปฏิบัติงานของกลุ่มพนักงานเดียวกัน
                  โดยมีเหตุผลที่ยอมรับได้กับ PM
                  อธิบายได้ว่าทำไมบางส่วนถึงไม่สามารถบรรลุเป้าหมายที่กำหนดได้
                </small>
              </p>
            </IonItem>
            <IonItem>
              <p>
                <ScoreChip text="2 : Partially Meet Target" />
                <br />
                <IonText>
                  Meets only SOME performance targets. Performance level needs
                  improvement, compared with performance of the same employee
                  group
                </IonText>
                <br />
                <small>
                  ผลงานบางส่วนเท่านั้น (ไม่ถึง 50%)
                  ที่เป็นไปตามเป้าหมายที่กำหนดไว้
                  และจำเป็นต้องปรับปรุงระดับผลการปฏิบัติงาน
                  เมื่อเปรียบเทียบกับผลการปฏิบัติงานของกลุ่มพนักงานเดียวกัน
                </small>
              </p>
            </IonItem>
            <IonItem>
              <p>
                <ScoreChip text="1 : Not Meet Target" />
                <br />
                <IonText>
                  Does not meet MOST of performance targets. Performance level
                  is unacceptable, compared with performance of the same
                  employee group
                </IonText>
                <br />
                <small>
                  ผลงานส่วนใหญ่ไม่เป็นไปตามเป้าหมายที่กำหนดไว้
                  ระดับผลการปฏิบัติงานต่ำกว่าเกณฑ์ที่หัวหน้ายอมรับได้
                  เมื่อเปรียบเทียบกับผลการปฏิบัติงานของกลุ่มพนักงานเดียวกัน
                </small>
              </p>
            </IonItem>
          </IonList>
          <IonNote>
            <IonText color="danger">หมายเหตุ </IonText>
            ในการทำงานเป้าหมายจะมีกำหนดไว้แล้วกับ PM หรือหัวหน้า
            ว่าต้องทำให้ได้เท่าไหร่ คุณภาพไหน
            ส่วนการจะดูว่าได้ผลงานเด่นชัดให้ทำการเทียบกับ Peer
            หรืออาจเป็นเป้าที่กำหนดไว้เดิม สมมติเช่น
            เดิมตั้งเป้าว่าจะสามารถทำได้ใน 10 วัน แต่สามารถคิดวิธีการใหม่
            breakthrough ทำเสร็จใน 6 วัน แทนเป็นต้น
            หรือสามารถทำได้ในเวลาที่กำหนด แต่คิดวิธีการเพื่อให้ผู้อื่นนำไป
            reapply ได้ ซึ่งอาจเป็นการลดเวลาการทำงานในอนาคตเป็นต้น
          </IonNote>
        </IonContent>
      </IonModal>
    </>
  );
};

export default KPICiteriaModal;
