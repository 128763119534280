import {
  IonButtons,
  IonCard,
  IonChip,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import { IScoreFinalData } from "models/kpi.model";
import React from "react";
import { selectEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { EmpChip } from "../../../components/Select/EmployeeSelect";
import { getEmpFullName, getWorkingTime, roundTwoDigit } from "../../../utils";
import ModalForm from "./modal";

const List: React.FC<{
  data: Array<IScoreFinalData>;
  avg: number;
  stddev: number;
  dataList: Array<IScoreFinalData>;
}> = ({ data, avg, stddev, dataList }) => {
  const empList = useAppSelector(selectEmpList);

  const renderKPIList = data.map((p, i) => (
    <IonCard key={i}>
      <IonItem color="light" lines="full" className="ion-align-items-center">
        <IonButtons slot="start">
          <EmpChip value={p.emp_id} />
        </IonButtons>
        <IonText style={{ fontWeight: "bold" }}>
          {`${getEmpFullName(p.emp_id, empList)}`}
        </IonText>
        <IonChip color="success" className="ion-margin-end">
          {`${roundTwoDigit(p.total_storypoint)} storypoint`}
        </IonChip>
        <IonChip>{`${getWorkingTime(p.emp_id, empList)}`}</IonChip>
        <IonButtons slot="end">
          <ModalForm data={p} stddev={stddev} avg={avg} dataList={dataList} />
        </IonButtons>
      </IonItem>
      <IonItem lines="inset">
        <IonGrid>
          <IonRow>
            <IonCol size-sm="6" size="12">
              <IonText>
                {"Director Score : "}
                {p.director_acm ? roundTwoDigit(p.director_acm) : "N/A"}
              </IonText>
            </IonCol>
            <IonCol size-sm="6" size="12">
              <IonText>
                {"T Score : "}
                {p.t_score ? roundTwoDigit(p.t_score) : "N/A"}
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonCard>
  ));

  return <>{renderKPIList}</>;
};

export default List;
