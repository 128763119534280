export const powerbiIcon =
  "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221em%22%20height%3D%221em%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M21%201v22a1%201%200%200%201-1%201h-3.5V7c0-.827-.673-1.5-1.5-1.5h-2V1a1%201%200%200%201%201-1h6a1%201%200%200%201%201%201zm-6%205H9a1%201%200%200%200-1%201v4.5h2c.827%200%201.5.673%201.5%201.5v11H16V7a1%201%200%200%200-1-1zm-5%206H4a1%201%200%200%200-1%201v10a1%201%200%200%200%201%201h7V13a1%201%200%200%200-1-1z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E";

export const chartBellCurve =
  "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221em%22%20height%3D%221em%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M9.96%2011.31C10.82%208.1%2011.5%206%2013%206s2.18%202.1%203.04%205.31C17%2014.92%2018.1%2019%2022%2019v-2c-2.2%200-3-2.46-4.03-6.2C17.08%207.46%2016.15%204%2013%204c-3.15%200-4.08%203.46-4.97%206.8C7.03%2014.54%206.2%2017%204%2017V2H2v20h20v-2H4v-1c3.9%200%205-4.08%205.96-7.69z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E";

export const e360Icon =
  'data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"%3E%3Cpath fill="currentColor" d="m4.5 5.5l.4.3a.5.5 0 0 0-.4-.8v.5ZM3 7.5l-.4-.3A.5.5 0 0 0 3 8v-.5Zm9.736-4.646l.382.323l-.382-.323ZM2 6h2.5V5H2v1Zm2.1-.8l-1.5 2l.8.6l1.5-2l-.8-.6ZM3 8h.5V7H3v1Zm.5 1H2v1h1.5V9Zm.5-.5a.5.5 0 0 1-.5.5v1A1.5 1.5 0 0 0 5 8.5H4ZM3.5 8a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 3.5 7v1ZM8 5h-.5v1H8V5ZM6 6.5v1h1v-1H6Zm0 1v1h1v-1H6ZM7.5 7h-1v1h1V7ZM9 8.5A1.5 1.5 0 0 0 7.5 7v1a.5.5 0 0 1 .5.5h1ZM7.5 10A1.5 1.5 0 0 0 9 8.5H8a.5.5 0 0 1-.5.5v1Zm0-1a.5.5 0 0 1-.5-.5H6A1.5 1.5 0 0 0 7.5 10V9Zm0-4A1.5 1.5 0 0 0 6 6.5h1a.5.5 0 0 1 .5-.5V5ZM12 6.5v2h1v-2h-1Zm-1 2v-2h-1v2h1Zm.5.5a.5.5 0 0 1-.5-.5h-1a1.5 1.5 0 0 0 1.5 1.5V9Zm.5-.5a.5.5 0 0 1-.5.5v1A1.5 1.5 0 0 0 13 8.5h-1ZM11.5 6a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 11.5 5v1Zm0-1A1.5 1.5 0 0 0 10 6.5h1a.5.5 0 0 1 .5-.5V5Zm-4 9A6.5 6.5 0 0 1 1 7.5H0A7.5 7.5 0 0 0 7.5 15v-1ZM14 7.5A6.5 6.5 0 0 1 7.5 14v1A7.5 7.5 0 0 0 15 7.5h-1ZM7.5 0A7.5 7.5 0 0 0 0 7.5h1A6.5 6.5 0 0 1 7.5 1V0Zm6 3a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 13.5 2v1Zm0 1a.5.5 0 0 1-.5-.5h-1A1.5 1.5 0 0 0 13.5 5V4Zm-.5-.5c0-.123.044-.235.118-.323l-.763-.646c-.221.261-.355.6-.355.969h1Zm.118-.323A.498.498 0 0 1 13.5 3V2c-.46 0-.871.207-1.145.531l.763.646ZM7.5 1c1.934 0 3.671.844 4.862 2.186l.748-.664A7.483 7.483 0 0 0 7.5 0v1Zm5.854 3.67c.414.856.646 1.815.646 2.83h1c0-1.17-.268-2.277-.746-3.265l-.9.436ZM14 3.5a.5.5 0 0 1-.348.477l.304.952A1.5 1.5 0 0 0 15 3.5h-1Zm-.348.477A.5.5 0 0 1 13.5 4v1a1.5 1.5 0 0 0 .456-.07l-.304-.953Z"%2F%3E%3C%2Fsvg%3E';

export const departmentIcon =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cg fill='none'%3E%3Cpath d='M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z'/%3E%3Cpath fill='currentColor' d='M12 3a3 3 0 0 0-1 5.83V11H8a3 3 0 0 0-3 3v1.17a3.001 3.001 0 1 0 2 0V14a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v1.17a3.001 3.001 0 1 0 2 0V14a3 3 0 0 0-3-3h-3V8.83A3.001 3.001 0 0 0 12 3'/%3E%3C/g%3E%3C/svg%3E";
