import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import { Footer } from "../../../components/Item/ItemKPI";
import EmployeeSelect from "../../../components/Select/EmployeeSelect";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import { IndividualAPI } from "api/KPIAPI";
import List from "./list";
import KPICiteriaModal from "components/Modal/KPICiteriaModal";

const Page: React.FC<{ title: string; path: string }> = ({ title, path }) => {
  const { user } = useAppSelector(selectAuthen);
  const {
    state: { period, emp_id },
    dispatch,
  } = useKPI();

  const [Items, setItems] = useState<Array<any>>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showSaving, setShowSaving] = useState<boolean>(false);
  const [ScoreList, setScoreList] = useState<Array<any>>([]);
  const [totalStorypoint, setTotalStorypoint] = useState<number>(0);
  const [updateDate, setUpdateDate] = useState<Date | null>(null);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setShowLoading(true);
    if (emp_id && period) {
      IndividualAPI.read({ emp_id: emp_id, period: period.name }).then(
        (res) => {
          const { data, form, total_storypoint, updateDate } = res.data;
          setItems(data);
          setScoreList(form);
          setTotalStorypoint(total_storypoint ? total_storypoint : 0);
          setUpdateDate(new Date(updateDate));
          setShowLoading(false);
        }
      );
    } else history.replace(path);
  }, [emp_id, period, history, path]);

  const updateScore = (event: any) => {
    event.preventDefault();
    setShowLoading(true);
    setShowSaving(true);
    IndividualAPI.update(ScoreList).then((item) => {
      if (Array.isArray(item.data)) {
      } else {
        setShowLoading(false);
        setShowSaving(false);
        setSaveSuccess(true);
      }
    });
  };

  const countFilled = (filter: {
    prj_id?: string;
    epic_id?: string;
    emp_id?: string;
  }) => {
    const { prj_id, epic_id, emp_id } = filter;
    const allInput = ScoreList.filter(
      (data) =>
        (prj_id ? data.prj_id === prj_id : true) &&
        (epic_id ? data.epic_id === epic_id : true) &&
        (emp_id ? data.emp_id === emp_id : true) &&
        data.depa_indv_group !== "None"
    );
    const allfilled = ScoreList.filter(
      (data) =>
        (prj_id ? data.prj_id === prj_id : true) &&
        (epic_id ? data.epic_id === epic_id : true) &&
        (emp_id ? data.emp_id === emp_id : true) &&
        data.self_acm !== 0 &&
        data.self_acm !== null &&
        data.depa_indv_group !== "None"
    );
    return { filled: allfilled.length, all: allInput.length };
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/kpi/indv" />
          </IonButtons>
          {period && <IonTitle>{`${title} (${period.name})`}</IonTitle>}
        </IonToolbar>
        {user &&
          Boolean(user.role.includes("admin") || user.role.includes("spm")) && (
            <IonToolbar>
              <EmployeeSelect
                label="Employee"
                value={emp_id}
                name="Employee"
                onChange={(e: any) => {
                  dispatch({ type: "SET_EMP", emp_id: e.target.value });
                }}
              />
            </IonToolbar>
          )}
      </IonHeader>
      <IonContent>
        {showLoading ? (
          <Spinning></Spinning>
        ) : (
          <List
            Items={Items}
            ScoreList={ScoreList}
            setScoreList={setScoreList}
            emp_id={emp_id}
            countFilled={countFilled}
            period={period}
          />
        )}
      </IonContent>
      {!showLoading && Boolean(Items.length) && (
        <Footer
          totalStorypoint={totalStorypoint}
          filledCount={countFilled({})}
          updateScore={updateScore}
          updateDate={updateDate}
          editable={period?.edit_self && user && emp_id === user.username}
          buttonList={[<KPICiteriaModal/>]}
        />
      )}
      <IonToast
        isOpen={saveSuccess}
        onDidDismiss={() => setSaveSuccess(false)}
        message="Your data was saved."
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showSaving}
        message={"Saving ..."}
        duration={0}
      />
    </IonPage>
  );
};

export default Page;
