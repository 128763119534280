import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonItemGroup,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { setAvatar, setUser } from "redux/authen/reducer";
import { useAppDispatch } from "redux/hooks";
import {
  getAvatar,
  getCurrentUser,
  updateProfile,
  uploadAvatar,
} from "../api/AuthAPI";
import { InputText } from "../components/FormInputType";

interface ProfileEdit {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
}

const ProfileModal: React.FC<{
  item: any;
  avatar: any;
  setModal: Function;
  modal: boolean;
  setProfileSuccess: Function;
}> = ({ item, avatar, setModal, modal, setProfileSuccess }) => {
  const dispatch = useAppDispatch();
  const [Item, setItem] = React.useState<any>(item);
  const [BeforeItem, setBeforeItem] = React.useState<any>({ ...item });

  const [file, setFile] = useState<any>({});
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>(null);

  const fileInput = useRef<any>();

  useEffect(() => {
    const { id, username, firstname, lastname, email, avatar }: any = item;
    setItem(item);
    setBeforeItem({ id, username, firstname, lastname, email, avatar });
  }, [item]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setItem((prevState: ProfileEdit) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const changeAvatar = () => {
    if (!imagePreviewUrl) return false;
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "PNG",
      20,
      0,
      (blob: any) => {
        let formData = new FormData();
        formData.append("photo", blob, Item.id);

        uploadAvatar(formData)
          .then((response: any) => {
            console.log(response);
          })
          .catch((err) => console.log(err));
      },
      "blob",
      200,
      200
    );
  };

  const onFileChange = (fileChangeEvent: any) => {
    let img = fileChangeEvent.target.files[0];
    //const reader = new FileReader();

    if (img) {
      setFile(img);
      Resizer.imageFileResizer(
        img,
        300,
        300,
        "PNG",
        20,
        0,
        (uri) => {
          setImagePreviewUrl(uri);
        },
        "base64",
        200,
        200
      );
    }
  };

  const submitFormEdit = (event: any) => {
    event.preventDefault();

    const fetchUser = () => {
      getCurrentUser()
        .then((payload) => {
          const { token, ...user } = payload.data;

          dispatch(setUser(user));
          const reader = new FileReader();
          // getAvatar(user.username).then((file: any) => {
          //   reader.readAsDataURL(file.data);
          //   reader.onloadend = () => dispatch(setAvatar(reader.result));
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    changeAvatar();

    const { id, username, firstname, lastname, email } = Item;

    updateProfile({ id, username, firstname, lastname, email })
      .then(() => {
        fetchUser();
        setBeforeItem({ ...Item });
        setModal(false);
        setProfileSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setTimeout(() => window.location.reload(), 1000));
  };

  const form = (
    <IonItemGroup>
      <IonItem>
        <IonAvatar
          slot="start"
          style={{
            //margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          <img
            src={imagePreviewUrl ? imagePreviewUrl : avatar}
            alt="avatar"
            width="250px"
            height="250px"
            onClick={() => {
              fileInput?.current?.click();
            }}
          />
        </IonAvatar>
        <input
          ref={fileInput}
          hidden
          type="file"
          accept="image/*"
          onChange={(e) => onFileChange(e)}
          onClick={() => {
            console.log("onClick");
          }}
        />
        <IonButton
          color="primary"
          onClick={() => {
            fileInput?.current?.click();
          }}
        >
          Change profile picture
        </IonButton>
        {/* <IonButton color="primary" onClick={uploadProfile}>
          Upload
        </IonButton> */}
      </IonItem>
      <InputText
        label="Firstname"
        name="firstname"
        value={Item.firstname}
        onChange={onChange}
      />
      <InputText
        label="Lastname"
        name="lastname"
        value={Item.lastname}
        onChange={onChange}
      />
      <InputText
        label="Email"
        name="email"
        value={Item.email}
        onChange={onChange}
      />
    </IonItemGroup>
  );

  return (
    <IonModal isOpen={modal} >
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonTitle>Edit Profile Data</IonTitle>
          <IonButtons slot="end">
            <IonButton expand="block" onClick={submitFormEdit}>
              Save
            </IonButton>
            <IonButton
              expand="block"
              onClick={() => {
                setItem({ ...BeforeItem });
                setImagePreviewUrl(null);
                //setFile(null);
                setModal(false);
              }}
            >
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">{form}</IonContent>
    </IonModal>
  );
};

export default ProfileModal;
