import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { selectAuthen } from "redux/authen/selector";
import { useAppSelector } from "redux/hooks";
import NotFound from "routes/NotFoundLogin";
import { HasResource } from "../utils/auth.util";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  componentProps?: any;
  page: string | Array<string>;
  requirePermission?: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, componentProps, page, requirePermission, ...rest } = props;
  const { user, isAuthenticated, permission } = useAppSelector(selectAuthen);

  const checkResource = () => {
    return HasResource(permission, page) || !requirePermission ? true : false;
  };

  return (
    user && (
      <Route
        {...rest}
        render={(routeProps) => {
          return !isAuthenticated ? (
            <Redirect to="/login" />
          ) : checkResource() ? (
            <Component {...routeProps} {...componentProps} />
          ) : (
            <NotFound />
          );
        }}
      />
    )
  );
};

export default PrivateRoute;
