import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { E360API } from "api/KPIAPI";
import { IScoreE360Data } from "models/kpi.model";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
// import { selectDDLUserGroup } from "redux/dropdown/reducer";
// import { useAppSelector } from "redux/hooks";
import { Footer } from "../../../components/Item/ItemKPI";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import GetTemplate from "./getTemplate";
import List from "./list";
import UploadData from "./uploadData";

const Page: React.FC<{ title: string; path: string }> = ({ title, path }) => {
  const [Items, setItems] = useState<Array<IScoreE360Data>>([]);
  const [searchEmp, setSearchEmp] = useState<string>("");
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [Textloading, setTextLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  //const userGroupOption = useAppSelector(selectDDLUserGroup);
  const [update, setUpdate] = useState(0);
  const [userGroup, setUserGroup] = useState<string | null>(null);
  const history = useHistory();

  const {
    state: { period },
  } = useKPI();

  useEffect(() => {
    setTextLoading(true);
    if (period) {
      E360API.read({ period: period.name, group: userGroup })
        .then((res) => {
          setItems(res.data);
        })
        .then(() => setTextLoading(false));
    } else history.push(path);
  }, [period, userGroup, history, path, update]);

  const convertData = (scoreList: Array<any>) => {
    return scoreList.map((value) => ({
      emp_id: value.username,
      kpi_acm: parseFloat(value["KPI_score"]),
      e360_acm: parseFloat(value["360_score"]),
      pip_acm: parseFloat(value["PIP_score"]),
      pms_acm: parseFloat(value["PMS_score"]),
    }));
  };

  const updateScore = (scoreList: Array<IScoreE360Data>, event: any) => {
    event.preventDefault();
    setShowLoading(true);
    setTextLoading(true);
    E360API.update(convertData(scoreList), { period: period!.name })
      .then((item) => {
        if (Array.isArray(item.data)) {
        } else {
          console.log("failure");
        }
        setShowLoading(false);
        setTextLoading(false);
        setUpdate(update + 1);
        setSaveSuccess(true);
      })
      .catch((err) => {
        console.log("failure");
        setShowLoading(false);
        setTextLoading(false);
      });
  };

  const filterSearch = (list: Array<IScoreE360Data>, searchEmp: string) =>
    list.filter((emp) => emp.emp_id.indexOf(searchEmp.toLowerCase()) > -1);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="gbdi">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/kpi/final_kpi" />
          </IonButtons>
          <IonTitle>{`${title} (${period?.name})`}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeSm="12">
                <IonItem lines="none">
                  <IonSearchbar
                    placeholder="Search Employee"
                    value={searchEmp}
                    onIonChange={(e) => {
                      setSearchEmp(e.detail.value!);
                    }}
                  ></IonSearchbar>
                </IonItem>
              </IonCol>
              {/* <IonCol size="12" sizeSm="6">
                  <IonItem>
                    <IonLabel>Employee Group</IonLabel>
                    <IonSelect
                      value={userGroup}
                      onIonChange={(e) => setUserGroup(e.detail.value)}
                      interface="popover"
                    >
                      {userGroupOption.map(({ label, value }) => (
                        <IonSelectOption value={value} key={value}>
                          {label}
                        </IonSelectOption>
                      ))}
                      <IonSelectOption value={null} key="all">
                        All
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol> */}
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {Textloading ? (
          <Spinning />
        ) : (
          <div>
            {Boolean(Items.length) ? (
              <>
                <List Items={filterSearch(Items, searchEmp)} />
              </>
            ) : (
              <h1 className="ion-text-center">No Record</h1>
            )}
          </div>
        )}
      </IonContent>

      <IonToast
        isOpen={saveSuccess}
        onDidDismiss={() => setSaveSuccess(false)}
        message="Your data was saved."
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Saving..."}
      />

      <Footer
        editable={period?.edit_empKPI}
        buttonList={[
          <GetTemplate />,
          <UploadData updateScore={updateScore} editable={period?.edit_360} />,
        ]}
      />
    </IonPage>
  );
};

export default Page;
