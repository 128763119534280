import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { list } from "ionicons/icons";
import React, { useEffect } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { EmpChip } from "../../../components/Select/EmployeeSelect";
import Spinning from "../../../components/Spinning";
import useKPI from "../../../store/KPIProvider";
import { roundTwoDigit } from "../../../utils";
import { colorScheme } from "../../../variable/colorScheme";
import { DepartmentAPI } from "api/KPIAPI";

interface IempDetail {
  project: Array<any>;
  kpi: Array<any>;
}

const ModalForm: React.FC<{ emp_id: string }> = ({ emp_id }) => {
  const [modal, setModal] = React.useState(false);
  const [empDetail, setempDetail] = React.useState<IempDetail>();
  const [textLoading, setTextLoading] = React.useState<boolean>(false);

  const {
    state: { period },
  } = useKPI();

  useEffect(() => {
    if (modal) {
      setTextLoading(true);
      period &&
        DepartmentAPI.readDetail!({ period: period.name, emp_id: emp_id }).then(
          (items) => {
            setTextLoading(false);
            setempDetail(items.data);
          }
        );
    }
  }, [modal, emp_id, period]);

  const toggle = () => {
    setModal(!modal);
  };

  const Loadingstyle = {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <IonButton color="primary" fill="solid" onClick={toggle}>
        <IonIcon icon={list} />
        {"Click for Detail"}
      </IonButton>
      <IonModal isOpen={modal}>
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonButtons slot="start"></IonButtons>
            <IonTitle>Detail ({`${emp_id}`})</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-justify-content-center">
          {textLoading ? (
            <Spinning />
          ) : empDetail &&
            (empDetail.project.length !== 0 || empDetail.kpi.length !== 0) ? (
            <IonGrid style={{ height: "100%" }}>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Project Score (Average)</IonCardTitle>
                </IonCardHeader>
                <IonRow style={{ minHeight: "400px" }}>
                  <IonCol size-sm="6">
                    <ResponsiveContainer width="99%" height={400}>
                      <PieChart>
                        <Pie
                          data={empDetail.project}
                          nameKey="pname"
                          dataKey="total_storypoint"
                        >
                          {empDetail.project.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colorScheme[index % colorScheme.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip
                          formatter={(value: any) =>
                            `${roundTwoDigit(value)} storypoint`
                          }
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </IonCol>
                  <IonCol size-sm="6">
                    <IonList>
                      {empDetail.project.map((data, i) => (
                        <IonItemGroup key={i}>
                          <IonItem lines="none">
                            <IonLabel slot="start">{data.pname}</IonLabel>
                            <EmpChip value={data.lead} />
                          </IonItem>
                          <IonItem lines="none">
                            <IonChip color="success">{`${roundTwoDigit(
                              data.total_storypoint
                            )} storypoint`}</IonChip>
                          </IonItem>
                          <IonItem lines="full">
                            <IonLabel slot="start">PM Score</IonLabel>
                            <IonText slot="end">
                              {roundTwoDigit(data.pm_acm)}
                            </IonText>
                          </IonItem>
                        </IonItemGroup>
                      ))}
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>KPI Score (Average)</IonCardTitle>
                </IonCardHeader>
                <IonRow style={{ minHeight: "400px" }}>
                  <IonCol size-sm="6">
                    <ResponsiveContainer width="99%" height={400}>
                      <PieChart>
                        <Pie
                          data={empDetail.kpi}
                          nameKey="depa_indv_group"
                          dataKey="total_storypoint"
                        >
                          {empDetail.kpi.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colorScheme[index % colorScheme.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip
                          formatter={(value: any) =>
                            `${roundTwoDigit(value)} storypoint`
                          }
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </IonCol>
                  <IonCol size-sm="6">
                    <IonList>
                      {empDetail.kpi.map((data, i) => (
                        <IonItemGroup key={i}>
                          <IonItem lines="none">
                            <IonText slot="start">
                              {data.depa_indv_group}
                            </IonText>
                          </IonItem>
                          <IonItem lines="none">
                            <IonChip color="success">{`${roundTwoDigit(
                              data.total_storypoint
                            )} storypoint`}</IonChip>
                          </IonItem>
                          <IonItem lines="full">
                            <IonLabel slot="start">PM Score</IonLabel>
                            <IonText slot="end">
                              {roundTwoDigit(data.pm_acm)}
                            </IonText>
                          </IonItem>
                        </IonItemGroup>
                      ))}
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonGrid>
          ) : (
            <div style={Loadingstyle}>No Data</div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ModalForm;
