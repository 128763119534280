import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { EmpChip } from "components/Select/EmployeeSelect";
import Spinning from "components/Spinning";
import { cloudUpload } from "ionicons/icons";
import React from "react";
import { useCSVReader } from "react-papaparse";
import { selectCurrentEmpList } from "redux/employee/selector";
import { useAppSelector } from "redux/hooks";
import { getEmpFullName, roundTwoDigit } from "utils";
import useKPI from "../../../store/KPIProvider";

const UploadData: React.FC<{
  updateScore: Function;
  editable: any;
}> = ({ updateScore, editable }) => {
  const [modal, setModal] = React.useState(false);
  const [scoreList, setScoreList] = React.useState<Array<any>>([]);
  const [textLoading, setTextLoading] = React.useState<boolean>(false);
  const empList = useAppSelector(selectCurrentEmpList);

  const {
    state: { period },
  } = useKPI();

  const { CSVReader } = useCSVReader();

  const Loadingstyle = {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <CSVReader
        config={{ header: true }}
        onUploadAccepted={(results: any) => {
          setScoreList(results.data);
          setModal(true);
        }}
        validator={(file: any) => {
          console.log(file);
          return false;
        }}
      >
        {({ getRootProps, acceptedFile, getRemoveFileProps }: any) => (
          <IonButton type="button" {...getRootProps()} disabled={!editable}>
            <IonIcon slot="start" icon={cloudUpload} />
            Upload Score
          </IonButton>
        )}
      </CSVReader>
      <IonModal isOpen={modal} class="fullscreen">
        <IonHeader>
          <IonToolbar color="gbdi">
            <IonButtons slot="start"></IonButtons>
            <IonTitle>Upload</IonTitle>
            <IonButtons slot="end">
              <IonButton
                expand="block"
                onClick={(e) => {
                  updateScore(scoreList, e);
                  setModal(false);
                  setScoreList([]);
                }}
              >
                Confirm
              </IonButton>
              <IonButton
                expand="block"
                onClick={() => {
                  setModal(false);
                  setScoreList([]);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-justify-content-center">
          {textLoading ? (
            <Spinning />
          ) : scoreList.length !== 0 ? (
            <>
              <IonList>
                {scoreList.map((value) => (
                  <IonItem key={value.username}>
                    <EmpChip value={value.username}></EmpChip>
                    {/* <IonText style={{ fontWeight: "bold" }}>
                      {`${getEmpFullName(value.username, empList)}`}
                    </IonText> */}
                    <IonText slot="end">
                      <span
                        style={{ marginRight: "10px" }}
                      >{`KPI Score : ${roundTwoDigit(
                        value["KPI_score"]
                      )}`}</span>
                    </IonText>
                    <IonText slot="end">
                      <span
                        style={{ marginRight: "10px" }}
                      >{`360 Score : ${roundTwoDigit(
                        value["360_score"]
                      )}`}</span>
                    </IonText>
                    <IonText slot="end">
                      <span
                        style={{ marginRight: "10px" }}
                      >{`PIP Score : ${roundTwoDigit(
                        value["PIP_score"]
                      )}`}</span>
                    </IonText>
                    <IonText slot="end">
                      <span
                        style={{ marginRight: "10px" }}
                      >{`PMS Score : ${roundTwoDigit(
                        value["PMS_score"]
                      )}`}</span>
                    </IonText>
                  </IonItem>
                ))}
              </IonList>
            </>
          ) : (
            <div style={Loadingstyle}>No Data</div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default UploadData;
