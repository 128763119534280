// import { IJiraProject } from "models/dropdown.model";
import { IUserGroup } from "models/employee.model";

export interface modelData {
  requested_dept: string;
  requesting_pm: string;
  employee_group: IUserGroup | "all";
  jira_project: string;
  employee: string;
  sprint_name: string;
  sp_amt: number;
  status: string;
  type: "rq" | "ap";
  resource_owner: string;
  resource_owner_1: string;
  resource_owner_2: string;
  approved_by: string;
  id: number;
  display_button: boolean;
}

export const InitModelData: modelData = {
  requested_dept: "",
  requesting_pm: "",
  employee_group: "all",
  jira_project: "",
  employee: "",
  sprint_name: "",
  sp_amt: 0,
  status: "",
  type: "rq",
  resource_owner: "",
  resource_owner_1: "",
  resource_owner_2: "",
  approved_by: "",
  id: 1,
  display_button: false,
};
